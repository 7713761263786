import { Message } from '../message';

export const MESSAGES_EN_LANG = 'en-EN';
export const MESSAGES_EN_US_LANG = 'en-US';
export const MESSAGES_EN_NAV = 'en';

export const MESSAGES_EN = [
  new Message('user', 'User'),
  new Message('password', 'Password'),
  new Message('welcome', 'Your place to manage your'),
  new Message('welcome2', 'attendance, resources and services'),
  new Message('menu_init', 'Home'),
  new Message('menu_room', 'Room Reservation'),
  new Message('menu_profile', 'Profile'),
  new Message('menu_descargas', 'Download'),
  new Message('menu_incidence', 'Incidence'),
  new Message('menu_security', 'Security'),
  new Message('menu_job_position', 'Job position'),
  new Message('menu_resource', 'Resources'),
  new Message('menu_carWash', 'Car wash'),
  new Message('menu_gym_resources', 'Gym'),
  new Message('menu_dorlet', 'Dorlet'),
  new Message('menu_steward', 'Steward'),
  new Message('menu_dinning_position', 'Restaurant booking'),
  new Message('menu_assistance', 'Office Presence'),
  new Message('menu_assistance_user', 'Team Office Presence'),
  new Message('menu_cantine', 'Restaurant'),
  new Message('menu_parking', 'Parking'),
  new Message('menu_shuttle', 'Shuttle'),
  new Message('menu_question_and_answers', 'Support'),
  new Message('menu_logout', 'Logout'),
  new Message('error_getEquipement', 'Error getting room equipment'),
  new Message('error_getAllEquipement', 'Error getting equiment'),
  new Message('error_getRoomDetail', 'Error getting room detail'),
  new Message('rooms_reserve', 'Rooms reserved'),
  new Message('rooms_disable_reservation_title', 'Released rooms'),
  new Message(
    'rooms_disable_reservation_description',
    'We have freed the meeting rooms during the COVID-19 crisis'
  ),
  new Message('rooms_reserve_empty', 'There is no room reserved'),
  new Message(
    'rooms_waiting_get_error',
    'Error at getting reservations and waiting list from service'
  ),
  new Message('waiting_list', 'Waiting list'),
  new Message('express_reservation_btn', 'Express reservation {0}'),
  new Message('room_reserve_btn', 'Reserve room'),
  new Message('room_available', 'AVAILABLE'),
  new Message('room_available_btn', 'Reserve'),
  new Message('room_busy', 'BUSY'),
  new Message('room_busy_user', 'BUSY FOR {0}'),
  new Message(
    'express_resevation_success',
    'You have been assigned the room {0} for schedule {1} ({2} - {3})'
  ),
  new Message('incorrect_login', 'Bad login'),
  new Message(
    'azure_token_renewal_error',
    'Failed to log into SSO, please close the Pop-up and try again'
  ),
  new Message('error_forbidden', 'Session expired'),
  new Message('error_required', '* Field Required'),
  new Message('error_numeric_required', '* Numeric field required'),
  new Message('error_license_required', '* License plate field required'),
  new Message('error_ceco_required', '* CECO required'),
  new Message('error_description_required', '* Description field required'),
  new Message('btn_view_rooms', 'View available rooms'),
  new Message('error_nextBooking', 'Error getting the last reservation'),
  new Message('home_events_title', 'NEXT EVENTS'),
  new Message('cancel_reservation_btn', 'Cancel'),
  new Message('reservation_cancelled', 'Reservation cancelled seccessfully'),
  new Message('reservation_cancelled_error', 'Error at cancel reservation'),
  new Message('confirm_dialog_err', 'Error at showing cancel confirmation'),
  new Message(
    'reservation_delete_msg',
    'Are you sure you want to cancel the reseravation?'
  ),
  new Message('reservation_delete_msg_title', 'Reservation cancel'),
  new Message(
    'cancel_waiting_msg',
    'Are you sure you want to cancel the waiting?'
  ),
  new Message('cancel_waiting_msg_title', 'Waiting cancel'),
  new Message('cancel_waiting_successfully', 'Waiting cancelled seccessfully'),
  new Message('cancel_waiting_error', 'Error at cancel waiting'),
  new Message(
    'cancel_waiting_dialog_error',
    'Error at showing cancel confirmation'
  ),
  new Message('express_create_error', 'Error at creating a express reserve'),
  new Message('error_dalkonParking', 'Error at obtain parking'),
  new Message('home_parking_title', 'PARKING'),
  new Message('parking_ceded', 'Ceded'),
  new Message('parking_resident', 'fixed square'),
  new Message('parking_lot', 'Parking space booked for today'),
  new Message('no_parking', 'Without parking space'),
  new Message('no_vehicle', 'Without vehicle'),
  new Message('session_expired', 'Session Expired'),
  new Message('schedule', 'Schedule'),
  new Message('issue', 'Issue'),
  new Message('equipment', 'Equipment'),
  new Message('time_str', 'From {0} to {1}'),
  new Message('date', 'Date'),
  new Message('assistants', 'Assistants'),
  new Message('from', 'From'),
  new Message('since', 'Since'),
  new Message('meeting_reason', 'Reason for meeting'),
  new Message(
    'date_from_greater',
    'Date From must be greater than actual date'
  ),
  new Message('date_to_greater', 'Date To must be greater than Date From'),
  new Message('no_available_rooms_msg', 'There is no available rooms'),
  new Message(
    'no_available_rooms_equipment_msg',
    'With the equipment you need'
  ),
  new Message('rooms_without_equip', 'Rooms without equipment'),
  new Message('available_rooms_list_error', 'Error at getting available rooms'),
  new Message(
    'room_reserved_msg',
    'Room {0} reserved: Add at date {1} since {2} to {3}'
  ),
  new Message('reserve_creation_error', 'Error at create reserve'),
  new Message(
    'room_to_waiting',
    'The reservation has been added to waiting list'
  ),
  new Message(
    'room_to_waiting_error',
    'Error at adding reserve to waiting list'
  ),
  new Message('waiting_msg', '{0} from {1} to {2}'),
  new Message('reservation_reason_placeholder', 'Write a reason'),
  new Message('edit_reservation_msg', 'Reservation updated successfully'),
  new Message('edit_reservation_error', 'Error at reservation edit'),
  new Message('reservation_in_progress', 'In Progress'),
  new Message('name', 'Name'),
  new Message('building', 'Building'),
  new Message('select_building', 'Select building'),
  new Message('no_building', 'Without building'),
  new Message('select', 'Select..'),
  new Message('none', 'None'),
  new Message('not_available', 'Not available'),
  new Message('all_dept', 'All the departments'),
  new Message('all_user_dept', 'All users in the department: '),
  new Message('save_profile_btn', 'Update profile'),
  new Message('save_building_btn', 'Save building'),
  new Message('buildings', 'Building'),
  new Message('save_building_successfully', 'Building save succesfully'),
  new Message('save_building_error', 'Error at save building'),
  new Message('error_getBuildings', 'Error at obtain building list'),
  //------------------

  new Message(
    'periodicity_types_load_error',
    'Error loading frequency types'
  ),
  new Message(
    'periodicity_empty_rooms',
    'There are rooms reserved in the selected period'
  ),
  new Message(
    'building_list_load_errror',
    'Error loading building types'
  ),
  new Message('show_all', 'Show all'),

  new Message('Sun', 'Sun'),
  new Message('Mon', 'Mon'),
  new Message('Tue', 'Tue'),
  new Message('Wed', 'Wed'),
  new Message('Thu', 'Thu'),
  new Message('Fri', 'Fri'),
  new Message('Sat', 'Sat'),

  new Message('modal_edit_reservation', 'Edit reservation'),
  new Message('modal_save_btn', 'Save'),
  new Message('building_select_lbl', 'Buildings'),
  new Message('room_select_lbl', 'Rooms'),
  new Message('select_building', 'Select building'),
  new Message('select_room', 'Select room'),
  new Message('reserve_room', 'Reserve room'),
  new Message('room_detail_error', 'Error getting room detail'),
  new Message(
    'avaiableRooms_empty',
    'There are no rooms available for the selected date'
  ),
  new Message('room_not_available', 'The room is not available'),
  new Message('load_rooms_error', 'Error loading rooms'),

  new Message('duration', 'Duration'),

  new Message(
    'user_emailOrName_empty',
    'Any of the following fields is empty: email, name. If the user does not have an email, no email will be sent when making a reservation'
  ),

  new Message('reservation_list_filter_msg', 'Filtered by {0}'),

  new Message('login_username', 'User'),
  new Message('login_password', 'Password'),
  new Message('reset_password', 'Password'),
  new Message('reset_newpassword', 'New Password'),
  new Message('login_username_err', 'Field user is mandatory'),
  new Message('login_password_err', 'Field password is mandatory'),
  new Message(
    'reset_password_msj',
    'To request a new password enter'
  ),
  new Message('reset_password_err', 'You must enter a valid email'),
  new Message('error_login', 'An error occurred while logging in'),
  new Message(
    'error_reset',
    'There was an error sending the email. Contact the administrator.'
  ),

  new Message('menu_available_rooms', 'Available rooms'),
  new Message('menu_available_rooms_speed', 'Available Speed rooms'),

  new Message('room_speed_reserve', 'Reserve Speed room'),
  new Message('room_reserve_byRoom_btn', 'Find room'),
  new Message('room_reserve_byHQ', 'Reserve room in other headquarter'),

  new Message('room_edit', 'Your Reservation'),

  new Message('room_edit_btn', 'Edit'),

  new Message('error_password_required', 'Password is mandatory'),
  new Message('error_password_different', 'Given value do not match with previous'),

  new Message('periodicity', 'Frequency'),
  new Message('periodicity_none', 'None'),
  new Message('periodicity_daily', 'Daily'),
  new Message('periodicity_weekly', 'Weekly'),
  new Message('periodicity_max_date', 'Max date'),

  new Message(
    'date_from_greater_actual',
    'Date From cannot be lower than actual date'
  ),
  new Message(
    'date_from_in_day',
    'The duration of the reservation must be within the same day'
  ),
  new Message('duraton_greater', 'Duration cannot exceed the current day'),
  new Message(
    'date_max_periodicity_greater',
    'The maximum date of periodicity must be greater than the date of the reservation'
  ),

  new Message(
    'room_speed_reserved_msg',
    'Room {0} reserved: Add at date {1} since {2} with duration {3} min.'
  ),
  new Message(
    'room_reserved_multiple_msg',
    'Reservations made correctly'
  ),

  new Message('send_reservation_mail_btn', 'Alarm'),
  new Message('send_reservation_mail_success', 'Reservation sent successfully'),
  new Message('send_reservation_mail_error', 'Error sending the reservation'),
  new Message('reservation_listView_date', '{1} {0}, from {2} to {3}'),

  //------------------
  new Message('room_detail_name_title', 'Room'),
  new Message('headquarters_list', 'Headquarters'),
  new Message('country_list', 'Countries'),
  new Message('provinces_list', 'Provinces'),
  new Message('headquarters', 'Headquarters'),
  new Message('getHeadquarters_error', 'Error getting headquarters'),
  new Message('getCountry_error', 'Error getting countries'),
  new Message('getProvince_error', 'Error getting provinces'),
  new Message(
    'userWithoutHeadquartersMustSelectOne',
    'Please, select a headquarters to start using the app'
  ),
  new Message('login_access_btn', 'Login'),
  new Message('login_forgot_password', 'I forgot my password'),
  new Message(
    'login_problem_mail',
    'If you have problems accessing the service contact us from '
  ),
  new Message('login_problem_here', 'here'),
  new Message(
    'resetPassword_message_ok',
    'An email with a new password has been sent to you.'
  ),
  new Message('resetPassword_message_error', 'Error updating password'),
  new Message('resetPassword_request_btn', 'Request'),
  new Message('resetPassword_return_btn', 'Return'),
  new Message('parking_nextDraw_message', 'Next assignment will be '),
  new Message('parking_yieldToday_message', 'Yield today'),
  new Message('parking_yield_message', 'Give place'),
  new Message('parking_next_raffle', 'The next draw will be on'),
  new Message(
    'parking_notVehicle',
    'To register your vehicle, you can do it in Your vehicles within Your profile.'
  ),
  new Message('parking_raffle_info', "Today you don't have a parking space"),
  new Message('parking_raffle_info_not_available', 'Draw not available'),
  new Message('parking_enter_raffle', 'Enter the draw'),
  new Message(
    'parking_yieldTemporary_message',
    'Use the option "Temporary yield" for vacation periods or absences. You can determine up to 3 periods in which you want to yield your parking spot.'
  ),
  new Message(
    'parkingOne_yieldTemporary_message',
    'Give in now to leave your place free until the next draw. Use the option "Temporary transfer" for vacation periods or absences. '
  ),
  new Message(
    'parkingOne_checkedRaffle_title',
    'You are participating in the draw'
  ),
  new Message(
    'parkingOne_checkedRaffle_description',
    'As long as you keep this option active, every day you will enter the lottery for parking spaces. Remember to deactivate it during vacation or absences. '
  ),
  new Message(
    'parkingOne_no_checkedRaffle_title',
    'You are not participating in the draw'
  ),
  new Message(
    'parkingOne_checkedRaffle_description_second',
    'If you want to know how the lottery works, press'
  ),
  new Message(
    'parkingOne_raffle_end',
    'Error, the giveaway is deleted or disabled'
  ),
  new Message(
    'parking_without_assigned_parkingSpot',
    "You don't have an assigned parking spot today"
  ),
  new Message(
    'parking_with_assigned_parkingSpot_today',
    'Your parking spot for today is '
  ),
  new Message(
    'parking_yielded_parkingSpot',
    'Your parking spot is yielded until '
  ),
  new Message(
    'parking_assigned_parkingSpot',
    'You have a parking spot assigned'
  ),
  new Message(
    'parking_assigned_fixed_parkingSpot',
    'You have a fixed place assigned '
  ),
  new Message(
    'parking_assigned_temporary_parkingSpot',
    'You have temporary place assigned until '
  ),
  new Message('parking_request_seeDetails_btn', 'See details'),
  new Message('parking_request_btn', 'Request parking spot'),
  new Message(
    'parking_parkingSpot_absence',
    'Your can yield your parking spot when on absence or vacation'
  ),
  new Message('parking_user_message', 'Place assigned until next raffle'),
  new Message('parking_temporal_message', 'Place temporarily ceded'),
  new Message(
    'parking_yielded_parkingRecover',
    'You will recover your place on '
  ),
  new Message(
    'parking_yielded_parkingConfirm',
    'You have canceled the transfer of your place'
  ),
  new Message(
    'parking_request_periods',
    'To request parking spot, select up to 3 periods'
  ),
  new Message('parking_yield_temporary', 'Temporary yield'),
  new Message('parking_from_date', 'From'),
  new Message('parking_to_date', 'Until'),
  new Message('parking_disabled_reservation_title', 'Parking released'),
  new Message(
    'parking_disabled_reservation_description',
    'We have released the car park during the COVID-19 crisis'
  ),

  new Message('send_invite_btn', 'Invite'),
  new Message('send_invitation', 'Send invitation'),
  new Message('user_to_send_invite', 'Search users…'),
  new Message('search_user', 'Search person'),
  new Message(
    'search_users_to_send_invitation',
    'Choose the users you want to send the invitation to:'
  ),
  new Message('invitation_user_not_found', 'No users found'),
  new Message('invitation_sent', 'The invitation has been sent successfully'),
  new Message(
    'invitations_users_search_error',
    'A problem has occurred while searching for users, please try again.'
  ),
  new Message(
    'invitations_users_department_search_error',
    'There was a problem searching by user or department, please try again'
  ),
  new Message(
    'invitations_users_send_error',
    'There was a problem sending the invitation, please try again.'
  ),
  new Message('invitations_user_not_registered', 'User not registered'),
  new Message('reservation_pending_confirmation', 'Confirmation pending'),
  new Message('room_reserve_byRoomAndPeriod_btn', 'Search by room and period'),
  new Message(
    'search_reservations_periods_by_room',
    'Finding times for a room'
  ),
  new Message('hours', 'Hours'),
  new Message('select_hours', 'Select an hour'),
  new Message('time_str_from', 'From'),
  new Message('time_str_to', 'To'),
  new Message('floor', 'Plant'),
  new Message('select_floor', 'Select plant'),
  new Message('area', 'Space'),
  new Message('select_area', 'Select space'),
  new Message('resource', 'Resource'),
  new Message('select_resource', 'Select resource'),
  new Message('categorie', 'Category'),
  new Message('select_categorie', 'Select category'),
  new Message('observation', 'Observations'),
  new Message('send_report', 'Send report'),
  new Message('title_incidence', 'Report incident'),
  new Message('box_title', 'Select a position to reserve it'),
  new Message('box_title_legend', 'Legend'),
  new Message('box_free', 'Free'),
  new Message('box_partially_occupied', 'Partially busy'),
  new Message('box_occupied', 'Occupied'),
  new Message('box_disabled', 'Disabled'),
  new Message('box_reserved', 'Reserved'),
  new Message('box_yourDesk', 'Your Desk'),
  new Message('box_userSelectDesk', 'Selected user position'),
  new Message('box_position_available', 'Selected positions'),
  new Message('reserveDesk_title', 'Reserve job'),
  new Message('reserveDeskDinning_title', 'Reserve dinning'),
  new Message('reserveDesk_busy', 'Busy'),
  new Message('reserveDesk_toSelect', 'TO SELECT'),
  new Message('reserveDesk_selected', 'SELECTED'),
  new Message('reserveDesk_cancel', 'CANCEL'),
  new Message('reserveDesk_reserve', 'RESERVE'),
  new Message('reserveDesk_reserve_all', 'FULL WORKING DAY'),
  new Message('reserveDesk_edit', 'EDIT'),

  new Message('message_DeskNotSelected_Title', 'Cannot be selected'),
  new Message(
    'message_DeskConsecutive_Description',
    'Only consecutive time slots can be booked.'
  ),
  new Message(
    'message_DeskNot_Description',
    'No more time slots can be selected.'
  ),

  new Message('message_DeskNotSelected_Title--NotSelect', 'Cannot be reserved'),
  new Message(
    'message_DeskConsecutive_Description--NotSelect',
    'To make the reservation you must select at least one time slot.'
  ),

  // Security
  new Message('security_search_title', 'Search by'),
  new Message('security_search_select', 'Select an option'),
  new Message('security_search_name', 'Name'),
  new Message('security_search_email', 'Email'),
  new Message('security_search_code', 'Seat code'),
  new Message('security_search_model', 'Model'),
  new Message('security_search_plate', 'Plate'),
  new Message('security_search_place', 'Place number'),
  new Message('security_search_building', 'Building'),
  new Message('security_search_floor', 'Floor'),
  new Message('security_search_btn', 'Search'),
  new Message('security_search_value', 'Text'),
  new Message(
    'security_search_value_error',
    'Please, enter at least 3 characters'
  ),
  new Message('security_search_by_name', 'There are no results for that name'),
  new Message(
    'security_search_by_plate',
    'There are no results for that plate'
  ),
  new Message(
    'security_search_by_place',
    'There are no results for that place'
  ),
  new Message(
    'security_search_service_error',
    'Oops, an unexpected error occurred'
  ),
  new Message('security_search_results', 'Results'),
  new Message(
    'security_search_places_available_error',
    'Error when obtaining available places'
  ),
  new Message('security_assign_place_btn', 'Assign place'),
  new Message('security_enrollment_value', 'License plate'),
  new Message('security_search_assign_place_error', 'Place assignment error'),
  new Message('security_search_found_vehicle_error', 'Vehicle not found'),
  new Message(
    'security_search_headquarters_vehicle_error',
    'The vehicle does not belong to the headquarters'
  ),
  new Message('security_search_assign_place_ok', 'Correctly assigned place'),
  new Message('security_search_available_places', 'Places available'),
  new Message('security_search_result_list', 'Search for places'),

  // CheckIn with code
  new Message('check_in_with_code_title', 'Reservation verification by code'),
  new Message('check_in_with_code_nfc', 'Position code'),
  new Message('check_in_with_code_nfc_placeholder', 'Insert alphanumeric code'),

  // Reservation by position
  new Message('reservation_by_position_title', 'Reserve a position'),
  new Message('reservation_by_position_btn', 'Reservation'),
  new Message('reserved_seats', 'Reserved seats'),
  new Message('no_reserved_seats', 'You have no reserved positions'),
  new Message(
    'reservation_recover_desk_error',
    'Error retrieving reserved seats'
  ),
  new Message(
    'reservation_desk_success',
    'Your seat has been successfully reserved.'
  ),
  new Message(
    'reservation_desk_error',
    'An error occurred during the reservation.'
  ),
  new Message('reservation_checkin_success', 'Check-in was successful.'),
  new Message('reservation_checkin_error', 'Check-in could not be done.'),
  new Message('reservation_checkout_success', 'Check-out was successful.'),
  new Message('reservation_checkout_error', 'Check-out could not be done.'),
  new Message(
    'reservation_not_available',
    'Could not book. You already have a seat reservation on the same date.'
  ),
  new Message('reservation_give', 'Cancel'),
  new Message('reservation_edit', 'Edit'),
  new Message('reservation_checkin', 'Check-In'),
  new Message('reservation_checkout', 'Check-Out'),
  new Message(
    'reservation_edit_success',
    'Your reservation has been modified successfully.'
  ),
  new Message(
    'reservation_edit_error',
    'An error occurred during modification.'
  ),
  new Message(
    'reservation_cancel_success',
    'Your reservation has been cancelled successfully.'
  ),
  new Message(
    'reservation_cancel_error',
    'An error occurred during the process.'
  ),
  new Message('reservation_historical_title', 'History'),
  new Message(
    'reservation_historical_description',
    'You dont have finalized reservations yet'
  ),
  new Message(
    'reservation_permissions',
    'You cannot make any more reservations in this period.'
  ),
  new Message('reservation_load_more', 'Load more'),
  new Message('reservation_share', 'Share'),
  new Message('reservation_add_people', 'Add people'),
  new Message('reservation_by', 'by '),
  new Message('reservation_busy_by', 'OCCUPIED BY'),
  new Message('reservation_by_admin', 'Reserved by '),
  new Message('reservation_menu_user', 'Search user position'),
  new Message('reservation_menu_multiple', 'Multiple booking'),
  new Message('reservation_menu_position', ' Reserve position'),
  new Message('reservation_user_position_date', 'Date'),
  new Message('reservation_user_position_see_reservations', 'See reservation'),
  new Message('reservation_multiple_tab1', 'Data'),
  new Message('reservation_multiple_tab2', 'People'),
  new Message('reservation_multiple_number_of_positions', 'position no'),
  new Message('reservation_multiple_number_of_hours', 'Hours'),
  new Message('reservation_multiple_number_of_minutes', 'Minutes'),
  new Message('reservation_multiple_start_date', 'Start date'),
  new Message('reservation_multiple_end_date', 'End date'),
  new Message('reservation_multiple_search_positions', 'Search available positions'),
  new Message('reservation_multiple_see_positions', 'See available positions'), 
  new Message(
    'reservation_share_success',
    'Your position has been shared successfully'
  ),
  new Message(
    'reservation_share_error',
    'Error, your position could not be shared'
  ),
  new Message(
    'reservation_no_available_positions',
    'There are no positions available for that selection'
  ),
  new Message(
    'reservation_available_positions_error',
    'Error getting available seats'
  ),
  new Message(
    'reservation_multiple_desk_success',
    'Your multiple reservation has been successful'
  ),
  new Message('reservation_search_user', 'Person'),

  new Message('profile_email_required', 'The email must be valid'),
  new Message('profile_building_selected', 'The building is mandatory'),
  new Message('profile_password_required', 'The password is mandatory'),
  new Message('profile_newpassword_required', 'The new password is mandatory'),
  new Message(
    'profile_newpasswordrepeat_required',
    'You must repeat the new password'
  ),
  new Message('profile_newpasswordrepeat_notSame', 'Passwords do not match'),
  new Message('getBuildings_error', 'Failed to get the buildings'),
  new Message('updateProfile_error', 'Profile update failed'),
  new Message('updateProfile_success', 'Profile successfully updated'),
  new Message('updatePassword_success', 'Password updated successfully'),
  new Message(
    'updatePassword_error',
    'An error occurred while changing the password.'
  ),

  // Incidences
  new Message(
    'incidence_file_type',
    'Error. The selected file is not an image'
  ),
  new Message('user_image', 'Picture'),
  new Message('user_image_optional', '(Optional)'),
  new Message('no_incidences', 'No incidents have been created'),
  new Message('user_image_select_file', 'Select file'),
  new Message('user_image_no_file', 'No chosen file'),
  new Message('user_image_file_charge', 'Click to upload file'),

  // Download
  new Message('dowloads_android_title', 'SmartBuilding App for Android'),
  new Message(
    'dowloads_android_desc',
    'You must authorize the installation in "Settings", "Advanced settings", "Security" and activate "Apps of unknown origin"'
  ),
  new Message('dowloads_btn', 'Download'),
  new Message('dowloads_ios_title', 'SmartBuilding App for iOS'),
  new Message(
    'dowloads_ios_desc',
    'You will receive a notice that the application comes from an unidentified developer, you must accept the installation anyway (Trust the service provider)'
  ),
  new Message('dowloads_android_tower_title', 'Tower App for Android'),
  new Message('dowloads_ios_tower_title', 'Tower App for iOS'),

  // Azure
  new Message('login_with_azure', 'Login with SSO'),
  new Message(
    'login_with_azure_loading',
    'Performing authentication through SSO. Please wait.'
  ),
  new Message('login_with_azure_loading_error', 'Error. It is not authorized'),

  // Profile
  new Message('profile_currentPassword_title', 'CURRENT PASSWORD'),
  new Message(
    'profile_currentPassword_placeholder',
    'Enter your current password'
  ),
  new Message('profile_newPassword_title', 'NEW PASSWORD'),
  new Message('profile_newPassword_placeholder', 'Enter your new password'),
  new Message('profile_confirmNewPassword_title', 'CONFIRM NEW PASSWORD'),
  new Message(
    'profile_confirmNewPassword_placeholder',
    'Repeat your new password'
  ),
  new Message('profile_updatePassword', 'Password update'),

  new Message('change_password_btn', 'Change password'),
  new Message('my_vehicles_btn', 'My vehicles'),
  new Message('update_password_ok', 'Password updated correctly'),
  new Message(
    'update_password_fail',
    'Error, the password introduced does not match your password'
  ),
  new Message(
    'update_password_error',
    'Error, it was not possible to update the password'
  ),
  new Message('profile_vehicle_main', 'Main'),
  new Message('profile_vehicle_edit', 'Edit'),
  new Message('profile_vehicle_remove', 'Remove'),
  new Message(
    'profile_principal_vehicle',
    'Correctly assigned primary vehicle'
  ),
  new Message('profile_edit_vehicle', 'Vehicle edited correctly'),
  new Message(
    'profile_edit_vehicle_error',
    'You must select other vehicle as main previously'
  ),
  new Message('profile_vehicleEdit_type_title', 'VEHICLE TYPE'),
  new Message('profile_vehicleEdit_type_select', 'Select type'),
  new Message('profile_vehicleEdit_license_title', 'LICENSE PLATE'),
  new Message(
    'profile_vehicleEdit_license_placeholder',
    'Enter the license plate'
  ),
  new Message('profile_vehicleEdit_model_title', 'MODEL'),
  new Message('profile_vehicleEdit_model_placeholder', 'Enter the model'),
  new Message('profile_vehicleEdit_color_title', 'COLOR'),
  new Message('profile_vehicleEdit_color_placeholder', 'Enter the color'),
  new Message('profile_vehicleEdit_main_title', 'MAIN VEHICLE'),
  new Message('profile_vehicleEdit_add', 'Add vehicle'),
  new Message('profile_vehicleEdit_edit', 'Edit vehicle'),
  new Message('profile_vehicleEdit_cancel', 'Cancel'),
  new Message('profile_department', 'Department'),
  new Message('profile_my_reservations', 'Make my reservations public'),
  new Message(
    'profile_departments_error',
    'Failed to get departments from your headquarters'
  ),
  new Message('profile_assistance_parking', 'Booking parking space'),
  new Message(
    'profile_assistance_parking_description',
    'Book automatically a parking space when informing the office presence'
  ),
  new Message(
    'profile_assistance_no_vehicle_for_parking',
    'You have to register a vehicle in order to request a parking space'
  ),
  new Message('profile_assistance_dinner', 'Booking restaurant'),
  new Message(
    'profile_assistance_dinner_description',
    'Book automatically a time slot in the restaurant when informing the office presence. If there are no available time slots, please select a new one'
  ),
  new Message('support_preferences_btn', 'Office Presence Preferences'),
  new Message('support_language_btn', 'Language'),
  new Message('profile_form_update_error', 'Support update failed'),
  new Message('profile_form_assistance_config', 'Office presence settings'),
  new Message('profile_form_vehicle', 'Your vehicles'),
  new Message('profile_form_pass_mod', 'Modify password'),
  new Message('profile_form_vehicle_add', 'Vehicle successfully added'),
  new Message('profile_form_vehicle_delete', 'Vehicle removed successfully'),
  new Message('profile_form_vehicle_delete_error', 'Failed to remove vehicle'),
  new Message('profile_error_image', 'Error. Size is greater than 5MB'),
  new Message('profile_preferred_time_slot', 'PREFERRED TIME SLOT'),

  // MODAL INDICATE YOUR DEPT
  new Message('indicate_your_dept_title', 'Indicate your department'),
  new Message(
    'indicate_your_dept_desc',
    'In order to use the application, the user must select the department to which it belongs.'
  ),
  new Message('indicate_your_dept_bt_cancel', 'CANCEL'),
  new Message('indicate_your_dept_bt', 'ACCEPT'),
  new Message('indicate_your_dept_error', 'You must select a department'),

  // ASSISTANCE
  new Message('assistance_bottom_previous', 'Previous'),
  new Message('assistance_bottom_next', 'Next'),
  new Message('assistance_bottom_save', 'SAVE'),
  new Message('assistance_bottom_solict', 'REQUEST'),
  new Message('assistance_bottom_cancel', 'CANCEL'),
  new Message('assistance_bottom_cancel_assistance', 'Cancel office presence'),
  new Message('assistance_text_waiting', '(Waiting List)'),
  new Message('assistance_text_confirm', 'Confirmed office presence'),
  new Message('assistance_text_confirmed', 'Confirmed office presence'),
  new Message('assistance_text_not_confirm', 'Confirm office presence'),
  new Message(
    'assistance_text_cancel_ok',
    'Office Presence successfully canceled'
  ),
  new Message('assistance_text_cancel_ko', 'Failed to cancel office presence'),
  new Message(
    'assistance_text_confirm_ok',
    'Office presence successfully confirmed'
  ),
  new Message('assistance_update_error', 'Failed to confirm office presence'),
  new Message(
    'assistance_text_liberate_parking_ok',
    ' Parking space successfully freed'
  ),
  new Message(
    'assistance_text_liberate_parking_ko',
    ' Error when freeing the parking space'
  ),
  new Message(
    'assistance_text_liberate_waiting_list_ok',
    'Waiting list successfully canceled'
  ),
  new Message(
    'assistance_text_liberate_waiting_list_ko',
    'Waiting list cancellation error'
  ),
  new Message(
    'assistance_text_solict_parking_ok',
    'Parking space successfully booked'
  ),
  new Message(
    'assistance_text_solict_parking_ko',
    'Error when booking the parking space'
  ),
  new Message(
    'assistance_text_solict_dinner_ok',
    'Time slot in the restaurant booked successfully'
  ),
  new Message(
    'assistance_text_solict_dinner_ko',
    'Error in the assignment of the restaurant position'
  ),
  new Message(
    'assistance_text_cancel_dinner_ok',
    'Time slot in the restaurant canceled successfully'
  ),
  new Message(
    'assistance_text_cancel_dinner_ko',
    ' Error when cancelling the time slot in the restaurant'
  ),
  new Message('assistance_text_check_daily_menu', 'CHECK DAILY MENU'),
  new Message('assistance_text_available_shifts', 'AVAILABLE TIME SLOTS'),
  new Message('assistance_text_available_vehicles', 'VEHICLE TYPE'),
  new Message('assistance_text_legend', 'Legend'),
  new Message(
    'assistance_text_reserve_error',
    ' You have exceeded the maximum number of bookings'
  ),
  new Message(
    'assistance_text_turn_selection_error',
    'You have to select a time slot'
  ),
  new Message(
    'assistance_text_legend_alert',
    'Capacity exceeded. Please review the office presence planning'
  ),
  new Message(
    'assistance_configuration_days_search_error',
    'An error occurred while searching for the days setting'
  ),
  new Message(
    'assistance_search_error',
    'An error occurred while searching for assists'
  ),
  new Message(
    'assistance_users_for_day_search_error',
    'An error has occurred in the user search'
  ),
  new Message(
    'assistance_info_search_error',
    'An error occurred while searching for support information'
  ),
  new Message(
    'assistance_candidates_search_error',
    'An error occurred while searching for candidate assists'
  ),
  new Message(
    'assistance_user_manager_search_error',
    'An error occurred while searching for users'
  ),
  new Message('assistance_select_user', 'Select user: '),
  new Message('assistance_button_confirm', 'Confirm'),
  new Message('assistance_button_close', 'Close'),
  new Message('assistance_dialog_confirm_title', 'Cancel office presence'),
  new Message(
    'assistance_dialog_confirm_text',
    'Do you want to cancel office presence? The following items will be removed: '
  ),
  new Message('assistance_dialog_confirm_text_assistance', 'Office presence'),
  new Message('assistance_dialog_confirm_text_assign_place', 'Assigned place'),
  new Message(
    'assistance_dialog_confirm_text_restaurant_shift',
    'Restaurant shift'
  ),

  // CALENDAR NOT CONFIRM
  //PARKING
  new Message(
    'assistance_text_not_space_available',
    'No parking space available'
  ),
  new Message(
    'assistance_text_not_vehicle_assign',
    'You do not have a vehicle registered in your profile'
  ),
  new Message(
    'assistance_text_parking_not_space_available_waiting',
    'There is no parking space available. You can get on the waiting list.'
  ),
  new Message(
    'assistance_text_parking_complete_space',
    'You can complete the parking space reservation for the selected vehicle'
  ),
  new Message(
    'assistance_text_parking_not_complete_space_selected',
    'The parking reservation for the selected vehicle cannot be completed. Please select another type of vehicle'
  ),
  new Message(
    'assistance_text_parking_available',
    'There are parking spaces available'
  ),
  new Message(
    'assistance_text_not_request_parking',
    ' You have not booked a parking space'
  ),
  new Message(
    'assistance_text_not_space_available_contactSystem',
    'The reservation cannot be completed, contact system administrator'
  ),
  //DINNER
  new Message(
    'assistance_text_not_dining_room_service',
    `There's no restaurant service for this day`
  ),
  new Message(
    'assistance_text_not_assign_dinner_space',
    ' There is no free time slot in the restaurant at the selected time'
  ),
  new Message(
    'assistance_text_not_assign_dinner_space_hour_select',
    'There is no free time slot in the restaurant at the selected time'
  ),
  new Message(
    'assistance_text_assign_dinner_space',
    ' Restaurant time slot booked at '
  ),
  new Message('assistance_text_dinner_select', 'Choose a restaurant time slot'),
  new Message(
    'assistance_text_not_request_dinner_space',
    ' You have not booked a restaurant time slot'
  ),
  new Message(
    'assistance_text_not_dining_room_service_workingHome_partTime',
    `There's no restaurant service for this day`
  ),

  // CALENDAR CONFIRM
  //PARKING
  new Message(
    'assistance_text_assign_parking',
    'You have booked a {0} parking space'
  ),
  new Message(
    'assistance_text_assign_parking_space',
    'You have assigned parking space'
  ),
  new Message('assistance_text_waiting_list', 'You are on the waiting list'),
  new Message('assistance_text_not_assign_main_active', ''),
  new Message('assistance_text_not_assign_main_not_active', ''),
  new Message('assistance_text_not_assign_parking', ''),
  new Message(
    'assistance_text_not_assign_parking_text_alert',
    'You have to register a vehicle in your profile'
  ),
  new Message(
    'assistance_text_not_parking_space_available',
    'There are no parking space available'
  ),
  //DINNER
  new Message(
    'assistance_text_assign_dinner',
    'You have booked time slot in the restaurant at '
  ),
  new Message(
    'assistance_text_available_dinner_space',
    'You can book a time slot in the restaurant'
  ),
  new Message(
    'assistance_text_not_assign_dinner',
    `You haven't booked time slot in the restaurant`
  ),

  // DINNING OCCUPATION
  new Message('occupation_text_free', 'Free'),
  new Message('occupation_text_occupied', 'Occupied'),
  new Message('occupation_text_occupancy_level', 'Real-time occupancy ratio'),
  new Message('occupation_text_last_update', 'Last updated at '),

  // PARKING OCCUPATION
  new Message('parking_occupation_title', 'Parking'),
  new Message('parking_occupation_free_places', 'Free places'),
  new Message('parking_occupation_building_a', 'BUILDING A'),
  new Message('parking_occupation_building_b', 'BUILDING B'),
  new Message('parking_occupation_schedule', 'Occupancy schedule profile'),
  new Message('parking_occupation_normal_parking', 'Normal parking'),
  new Message('parking_occupation_electric_parking', 'Electric parking'),
  new Message(
    'parking_occupation_external_error',
    'Obix server crash when obtaining occupancy data'
  ),

  // CANTINE
  new Message('cantine_text_error_data', 'Failed to get data'),

  // MODAL LANGUAGE
  new Message('language_assign', 'Assign language'),
  new Message('language_text_available', 'Available languages'),
  new Message('language_es', 'Spanish'),
  new Message('language_en', 'English'),
  new Message('language_pt', 'Portuguese'),
  new Message('language_pt-br', 'Brazilian Portuguese'),
  new Message('language_update_ok', 'Language has been updated successfully'),
  // LOPD PUIG
  new Message('lopd_text_title', 'Privacy Policy'),
  new Message(
    'lopd_text_subtitle',
    'Welcome to our website SmartBuilding (the <b>“Website”</b> or the <b>App</b>).' +
    'Please read our Privacy Policy carefully.'
  ),
  new Message('lopd_text_our_commitment_title', 'Our commitments'),
  new Message(
    'lopd_text_our_commitment_parraf_1',
    'Everyone has the right to protect their personal data. We respect the right of users to be ' +
    'informed about the collection of their personal data and other operations related to the said ' +
    'data. In using data that can identify you directly or indirectly, we will apply a principle of strict ' +
    'necessity. For this reason, we have designed the Website and/or the App in such a way that ' +
    'your personal data will be used in a minimal way and their use will not exceed the purposes ' +
    'for which your personal data were collected or processed: we shall not process them when ' +
    'we can provide you with services through the use of anonymous or traffic data and by other ' +
    'means that would allow us to identify you, except when strictly necessary or as a result of a ' +
    'prior request from competent public authorities or the police (for example, in case of traffic ' +
    'data or your MAC/IP address of your electronic device).'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_2',
    'For the purposes of data protection legislation (as defined below), ANTONIO PUIG S.A. ' +
    'Hereinafter referred to as <b>“APSA”</b> or the <b>“Company”</b>), with registered office at Plaça Europa, ' +
    '46-48, 08902 Hospitalet de Llobregat (Barcelona), holder of Tax Reference Number B-' +
    '64.797.129, entered in the Barcelona Companies’ Register, book 40,696, page 197, sheet ' +
    'number B-371.911, shall be considered to be your personal data processing manager. The ' +
    'Company undertakes to process all the information provided online by a user as strictly ' +
    'confidential.'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_3',
    'This Privacy Policy refers to the personal information that identifies any user when accessing ' +
    'our Website and/or App, using its services or completing forms on our Website and/or App, ' +
    'with or without the need to purchase any product (hereinafter referred to as <b>“personal ' +
    'data”</b>). This Privacy Policy helps you understand how the Company gathers and uses your ' +
    'personal data and for what purposes, and lists your rights in relation to your personal data.'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_4',
    'By using this Website and/or App, you accept the practices described in this Privacy Policy. If ' +
    'you do not wish to accept the practices described in this Privacy Policy, do not use the ' +
    'Website and/or the App.'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_5',
    'You must inform our Human Resources department of any change in the personal data in ' +
    'order to guarantee that the information contained in our records is at all times updated and ' +
    'accurate. In all cases, you are responsible for the accuracy of the personal data provided, ' +
    'and we reserve the right to suspend or stop the services requested if you provide inaccurate ' +
    'personal data, without prejudice to any action permitted by law.'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_6',
    'If you have any problem or query related to this Privacy Policy, please contact our Human ' +
    'Resources department: <b>hrpersonaldata@puig.com</b>'
  ),
  new Message(
    'lopd_text_applicable_legislation_title',
    'Applicable legislation'
  ),
  new Message(
    'lopd_text_applicable_legislation_parraf1',
    'All personal data sent to the Company via the Website and/or the App shall be collected or ' +
    'processed by the Company in accordance with applicable data protection legislation, ' +
    'including EU Regulation 2016/679 relating to the protection of individuals with regard to ' +
    'personal data processing and the free circulation of these data (General Data Protection ' +
    'Regulation or GDPR), without prejudice to any other national regulations in force or that in ' +
    'future may be passed on the matter, as well as applicable local laws such as the Spanish ' +
    'data protection law “Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos ' +
    'Personales y garantía de los derechos digitales” (hereinafter referred to jointly as <b>“Data ' +
    'protection legislation”</b>).'
  ),
  new Message(
    'lopd_text_personal_data_title',
    'Who gathers and processes your personal data, how and for what purpose? '
  ),
  new Message(
    'lopd_text_personal_data_parraf1',
    '<b>Personal data that you give us</b>:  via the related Website and/or App forms, these personal ' +
    'data (first name, last name, professional-mail, vehicle license number, other professional ' +
    'data, etc.) may be processed for purposes related to the control of capacity and access to ' +
    'common areas, aimed to the prevention of risks affecting the protection of people, premises ' +
    'and assets managed, by APSA or any company in the PUIG Group, particularly, to control ' +
    'the access to the building, parking and restaurant (hereinafter, <b>“Security purposes”</b>).'
  ),
  new Message(
    'lopd_text_personal_data_parraf2',
    'The legal basis for processing your personal data according to the above-mentioned purpose ' +
    'is founded on our legitimate interest in ensuring the health and safety of our employees, in ' +
    'accordance with the mandate established in the Spanish risk prevention law “Ley 31/1995, ' +
    'de 8 de noviembre, de Prevención de Riesgos Laborales”.'
  ),
  new Message(
    'lopd_text_personal_data_parraf3',
    'The reasons for gathering personal data are expressly listed in the information on the privacy ' +
    'declaration that we shall put out every so often on the section of the Website and/ or the App ' +
    'requesting the User to provide their personal data, in relation to the Security purposes.'
  ),
  new Message(
    'lopd_text_personal_data_parraf4',
    'Finally, we would remind you that, if you provide us with data relating to another person, ' +
    'before including them you should inform them of the points contained in this Privacy Policy.'
  ),
  new Message(
    'lopd_text_no_personal_data_title',
    'What happens if you do not provide us with your personal data?'
  ),
  new Message(
    'lopd_text_no_personal_data_parraf1',
    'Refusing to provide us with some of your personal data necessary to achieve the above-' +
    'mentioned purposes shall, as a result, lead to the impossibility of being able to deal with the ' +
    'reservation for access to the facilities of APSA or any company of the Puig Group.'
  ),
  new Message(
    'lopd_text_no_personal_data_parraf2',
    'Disclosing to us additional personal data that are not necessary to comply with legal or ' +
    'contractual obligations is, on the other hand, optional and has no effect whatsoever on the ' +
    'use of the Website and/or the App.'
  ),
  new Message(
    'lopd_text_disclosure_personal_data_title',
    'To whom will your personal data be disclosed?'
  ),
  new Message(
    'lopd_text_disclosure_personal_data_parraf1',
    'APSA has hired the provision of certain services to providers, who could have access and/or ' +
    'process personal data in their capacity as data processors (hereinafter referred to as <b>“Data processors”</b>), in particular:'
  ),
  new Message(
    'lopd_text_disclosure_personal_data_parraf2',
    'Website hosting and maintenance providers and corporate software solution ' +
    'providers who so require it;'
  ),
  new Message(
    'lopd_text_disclosure_personal_data_parraf3',
    'Data subjects’ rights service provider.'
  ),
  new Message(
    'lopd_text_disclosure_personal_data_parraf4',
    'In any case, your personal data shall not be transferred to countries located outside the ' +
    'European Economic Area that do not offer a suitable level or do not provide suitable ' +
    'guarantees of protection in accordance with the provisions set out in data protection ' +
    'legislation.'
  ),
  new Message(
    'lopd_text_disclosure_personal_data_parraf5',
    'In addition, we may give your data to the competent authorities when this is provided for by ' +
    'law. Your personal data may also be disclosed to public authorities (e.g., police or judicial ' +
    'authorities) in accordance with applicable laws and at the official request of these bodies. '
  ),
  new Message(
    'lopd_text_disclosure_personal_data_parraf6',
    'Your personal data could be sent to the companies belonging to the PUIG Group, with ' +
    'registered office in EU countries or in countries outside the EU, for their own Security ' +
    'purposes.'
  ),
  new Message(
    'lopd_text_security_measures_title',
    'Security measures and storage period'
  ),
  new Message(
    'lopd_text_security_measures_parraf1',
    'We have taken security measures to protect personal data from accidental or illegal ' +
    'destruction, accidental loss, unauthorised alteration, disclosure or access, and against any ' +
    'other reason for data processing that does not comply with our Privacy Policy.'
  ),
  new Message(
    'lopd_text_security_measures_parraf2',
    'In order to provide the best possible protection for your personal data beyond the limits of our ' +
    'control and management thereof, it is advisable to have on your electronic device, software ' +
    'solutions that will protect the transmission/reception of online data (such as updated antivirus ' +
    'systems) and for your Internet service provider to take suitable measures for the security of ' +
    'online data transmission (such as, for example, firewall and antispam filter).'
  ),
  new Message(
    'lopd_text_security_measures_parraf3',
    'We shall only store your personal data for the period necessary to complete the purposes set ' +
    'out in this Privacy Policy. When we no longer need to process your personal data for the ' +
    'purposes set out in this Privacy Policy or you have exercised your right of suppression, we ' +
    'shall delete your personal data from our system.'
  ),
  new Message(
    'lopd_text_data_transfer_title',
    'Transfer of your personal data to other countries'
  ),
  new Message(
    'lopd_text_data_transfer_parraf1',
    'The personal data we collect from you are currently within the European Union (“EU”).'
  ),
  new Message(
    'lopd_text_data_transfer_parraf2',
    'However, it is possible, in the future, that these personal data will be transferred, stored or ' +
    'processed outside the EU or the European Economic Area (“EEA”). In this eventuality, your ' +
    'data will only be transferred to countries located outside the European Union where: (i) there ' +
    'is an adequacy finding of the European Commission; or (ii) the appropriate guarantees of ' +
    'protection are provided (namely, Data protection-type clauses adopted by the European ' +
    'Commission or a control authority, Binding corporate Regulations, Codes of conduct, ' +
    'Certification mechanisms or ad hoc contract clauses).'
  ),
  new Message(
    'lopd_text_right_personal_data_title',
    'Your right in relation to your personal data'
  ),
  new Message(
    'lopd_text_right_personal_data_parraf1',
    'A summary of the rights available in relation to your personal data is given below.'
  ),
  new Message(
    'lopd_text_right_personal_data_parraf2',
    'For your convenience, and without prejudice to certain official requirements set out in data ' +
    'protection legislation, you may exercise any of these rights by contacting us at ' +
    '<b>hrpersonaldata@puig.com.</b>'
  ),
  new Message(
    'lopd_text_withdraw_consent_title',
    'Right to withdraw your consent:'
  ),
  new Message(
    'lopd_text_withdraw_consent_parraf1',
    'You may withdraw the consent you give the Company to process your personal data at any ' +
    'time. You must remember, however, that, if you withdraw your consent or otherwise object to ' +
    'our processing your personal data, this may affect the operating capacity of our Website and/ ' +
    'or App.'
  ),
  new Message(
    'lopd_access_personal_data_title',
    'Right to access your personal data in our possession:'
  ),
  new Message(
    'lopd_access_personal_data_parraf1',
    'You have the right to obtain, at any time, confirmation of whether or not we are processing ' +
    'your personal data and, where appropriate, access these personal data.'
  ),
  new Message(
    'lopd_access_personal_data_parraf2',
    'You also have the right to receive information on the source of your personal data; the ' +
    'purposes and form of processing your personal data; the logic involved in any online data ' +
    'processing, the details of the data processing manager and the data processors; the names ' +
    'of the bodies and categories of bodies to which your personal data can be disclosed or which ' +
    'may access your personal data, for example, as data processing manager or a party so ' +
    'appointed.'
  ),
  new Message(
    'lopd_text_right_rectify_data_title',
    'Right to correct inaccurate personal data:'
  ),
  new Message(
    'lopd_text_right_rectify_data_parraf1',
    'You have the right to get us to correct, without undue delay, inaccurate personal data that we ' +
    'store and that affect you. This includes the right to request completion of incomplete personal ' +
    'data.'
  ),
  new Message('lopd_text_right_to_erasure_title', 'Right of suppression:'),
  new Message(
    'lopd_text_right_to_erasure_parraf1',
    'You have the right to get us to suppress, without undue delay, the personal data we store ' +
    'and that concern you, in the circumstances stipulated by data protection legislation.'
  ),
  new Message(
    'lopd_text_right_restriction_title',
    'Right to processing restriction:'
  ),
  new Message(
    'lopd_text_right_restriction_parraf1',
    'You have the right to restrict the way in which we process your personal data in the ' +
    'circumstances stipulated by data protection legislation.'
  ),
  new Message(
    'lopd_text_data_portability_right_title',
    'Right to data portability:'
  ),
  new Message(
    'lopd_text_data_portability_right_parraf1',
    'You have the right to receive from us the personal data relating to you that you have given ' +
    'us, in a structured, commonly used and machine legible format, and the right to transmit ' +
    'these data to another processing manager without any impediment from us. This right shall ' +
    'include the right to require us to transmit the relevant personal data to another processing ' +
    'manager in your name, when this is technically feasible. This right only applies to the ' +
    'personal data for which: (i) we obtain your consent to process, or that (ii) we obtain to fulfil ' +
    'our contractual obligations with you, and in each case insofar as we process your personal ' +
    'data by automated means.'
  ),
  new Message('lopd_text_right_of_opposition_title', 'Right to object:'),
  new Message(
    'lopd_text_right_of_opposition_parraf1',
    'In certain circumstances and for reasons related to your particular situation, you may object ' +
    'to the processing of your data. APSA will stop processing the data, except for compelling ' +
    'legitimate reasons, or the exercise or defence of possible claims.'
  ),
  new Message(
    'lopd_text_right_of_complaint_title',
    'Right to lodge a complaint against us:'
  ),
  new Message(
    'lopd_text_right_of_complaint_parraf1',
    'You have the right to exercise your right to lodge a complaint with a competent control ' +
    'authority, in particular in the member state where you usually reside or work, or in the place ' +
    'where the presumed infringement occurred, if you consider that the processing of your ' +
    'personal data infringes data protection legislation.'
  ),
  new Message('lopd_text_opt-out_title', 'Voluntary subscription/exclusion'),
  new Message(
    'lopd_text_opt-out_parraf1',
    'Whenever your consent is required, the Company will inform you in advance and will give ' +
    'you the option of providing or refusing your consent to use your personal data, including your ' +
    'e-mail address by putting a cross in the appropriate boxes.'
  ),
  new Message(
    'lopd_text_opt-out_parraf2',
    'We would like to inform you that we can process your personal data without your consent in ' +
    'certain circumstances, such as when this processing is necessary to carry out a legal ' +
    'obligation that we are bound to fulfil or when this processing is necessary to carry out ' +
    'obligations based on our legitimate interest.'
  ),
  new Message('lopd_text_contacts_title', 'Contacts'),
  new Message(
    'lopd_text_contacts_parraf1',
    'You can contact the Company at any time by writing to the following e-mail address ' +
    '<b>hrpersonaldata@puig.com</b> if you have any doubt or question relating to this Privacy Policy ' +
    'and the personal data that you have given to the Company, or if you wish to receive further ' +
    'information on how we process your personal data.'
  ),
  new Message(
    'lopd_text_privacy_policy_title',
    'Amendments and update to this Privacy Policy'
  ),
  new Message(
    'lopd_text_privacy_policy_parraf1',
    'We may change or simply update all or part of this Privacy Policy, including when changes ' +
    'are made to the legal or regulatory provisions governing data protection and those protecting ' +
    'your rights. The amendments and update to the Privacy Policy shall be binding as soon as ' +
    'they are published on the Website and/ or the App in this section. You will therefore be asked ' +
    'to access this section regularly to check publication of the most recent and updated Privacy ' +
    'Policy.'
  ),
  new Message(
    'lopd_text_privacy_policy_parraf2',
    'This Privacy Policy was updated in May, 2021.'
  ),
  new Message(
    'lopd_text_foot',
    '<b>© ANTONIO PUIG S.A., 2021. All rights reserved.</b>'
  ),

  // ASSISTANCE TO THIRD PARTIES
  new Message('assistance_third_users_error', 'Failed to get users'),

  // MODAL RESERVATION
  new Message('reserve_title', 'Reserve your resource'),
  new Message('reserve_carwash_title', 'Reserve your laundry'),
  new Message('reserve_edit_title', 'Edit your Resource'),
  new Message('reserve_carwash_edit_title', 'Edit your laundry'),
  new Message('reserve_toSelect', 'SELECT'),
  new Message('reserve_selected', 'SELECTED'),
  new Message('reserve_toOccupation', 'OCCUPIED'),
  new Message('reserve_cancel', 'CANCEL'),
  new Message('reserve_reserve_all', 'FULL TIME'),
  new Message('reserve_reserve', 'RESERVE'),
  new Message('reserve_edit', 'EDIT'),

  //MULTI RESERVATION
  new Message('reserve_limit_today_lower', 'Select a time later than the current time if you are booking from today'),
  new Message('reserve_limit_opening_headquarters', 'Check that the reservation is limited to opening hours'),
  new Message('reserve_not_available', 'There are insufficient resources for the selected date and time.'),

  // CONFIRM RESERVATION
  new Message('reserve_confirm_title', 'Confirm Booking'),
  new Message('reserve_confirm_text', 'Do you want to confirm your reservation for the seats you just selected?'),

  // RESOURCE
  //LIST
  new Message('resource_list_title', 'Reserved resources'),
  new Message('resource_list_not_reserve', 'It has no reserved resources'),
  new Message('resource_list_button', 'Reserve resources'),
  new Message(
    'resource_list_cancel_reservation_ok',
    'Resource reservation deleted successfully'
  ),
  new Message(
    'resource_list_cancel_reservation_error',
    'The resource reservation has not been deleted correctly'
  ),
  new Message(
    'resource_list_add_reservation_ok',
    'The resource has been reserved successfully'
  ),
  new Message(
    'resource_list_add_reservation_error',
    'The reservation could not be made'
  ),
  new Message(
    'resource_list_edit_reservation_ok',
    'Resource reservation edited successfully'
  ),
  new Message(
    'resource_list_edit_reservation_error',
    'The resource reservation has not been edited correctly'
  ),
  //RESERVE
  new Message('resource_reserve_title', 'Book a resource'),
  new Message('resource_edit_title', 'Edit Reservation'),
  new Message('resource_reserve_type', 'Resource type'),
  new Message('resource_reserve_type_select', 'Select type'),
  new Message('resource_reserve_license', 'License plate'),
  new Message('resource_reserve_date', 'Date'),
  new Message('resource_reserve_submit', 'Reserve'),
  new Message('resource_reserve_edit_submit', 'Edit Reserve'),
  new Message(
    'resource_reserve_license_placeholder',
    'Enter the license plate'
  ),

  // CAR WASH
  //LIST
  new Message('carWash_list_title', 'Reserved car wash'),
  new Message('carWash_list_card_title', 'Parking area'),
  new Message('carWash_list_not_reserve', 'You dont have a reserved car wash'),
  new Message('carWash_list_button', 'Book car wash'),
  new Message(
    'carWash_list_cancel_reservation_ok',
    'The laundry reservation has been deleted successfully'
  ),
  new Message(
    'carWash_list_cancel_reservation_error',
    'The laundry reservation has not been deleted correctly'
  ),
  new Message(
    'carWash_list_add_reservation_ok',
    'The car wash has been reserved successfully'
  ),
  new Message(
    'carWash_list_add_reservation_error',
    'The reservation could not be made'
  ),
  new Message(
    'carWash_list_edit_reservation_ok',
    'Car wash reservation edited successfully'
  ),
  new Message(
    'carWash_list_edit_reservation_error',
    'The car wash reservation has not been edited correctly'
  ),

  //RESERVE
  new Message('carWash_reserve_title', 'Book a car wash'),
  new Message('gym_resources_reserve_title', 'Reserve Gym'),
  new Message('carWash_edit_title', 'Edit Reservation'),
  new Message('carWash_reserve_type', 'Type of car wash'),
  new Message('carWash_reserve_type_select', 'Select the type'),
  new Message('carWash_reserve_license', 'License plate'),
  new Message('carWash_reserve_ceco', 'CECO'),
  new Message('carWash_reserve_ceco_introduction', 'Insert ceco'),
  new Message('carWash_reserve_flota', 'IS FLEET'),
  new Message('carWash_reserve_date', 'Date'),
  new Message('carWash_reserve_submit', 'Reserve'),
  new Message('carWash_reserve_edit_submit', 'Edit Reserve'),
  new Message('carWash_reserve_license_placeholder', 'Enter the license plate'),

  //QUESTION AND ANSWERS
  //LIST
  new Message('question-and-answers_list_title', 'Questionnaires in progress'),
  new Message('question-and-answers_list_card_title', 'Questionnaire'),
  new Message('question-and-answers_list_not_progress', 'No quiz in progress '),
  new Message('question-and-answers_list_button', 'New quiz'),
  new Message('question-and-answers_list_next_title', 'Continue'),
  new Message('question-and-answers_list_delete_title', 'Cancel'),
  new Message(
    'question-and-answers_list_delete_ok',
    'Has been successfully canceled'
  ),
  new Message(
    'question-and-answers_list_delete_error',
    'It has not been canceled correctly'
  ),

  //NEW

  new Message(
    'question-and-answers_new_add_error',
    'The answer has not been added correctly'
  ),
  new Message(
    'question-and-answers_new_update_error',
    'The answer has not been edited correctly'
  ),
  new Message('question-and-answers_new_text_placeholder', 'Write your answer'),
  new Message('question-and-answers_new_file_placeholder', 'Select a file'),
  new Message('question-and-answers_new_next', 'Next'),

  //RESULT
  new Message('question-and-answers_result_init', 'Started'),
  new Message('question-and-answers_result_finish', 'Finished'),
  new Message(
    'question-and-answers_result_finish_admin',
    'Administration completed'
  ),
  new Message('question-and-answers_result_button', 'To finalize'),
  new Message('question-and-answers_result_title', 'Response:'),
  new Message('question-and-answers_result_text', 'Text:'),
  new Message('question-and-answers_result_file', 'File:'),

  //TYPE
  new Message('question-and-answers_type_title', 'Select a question type'),
  new Message('question-and-answers_type_next', 'Next'),
  new Message('question-and-answers_type_next_error', 'Not created correctly'),

  // CUSTOM EXTERNAL MODULE
  new Message('external_module_files', 'Archivos'),
  new Message('external_module_apps', 'Aplicaciones'),
  new Message('external_module_report_incidence', 'Reportar incidencia'),
  new Message('external_module_not_found', 'Módulo no encontrado'),
  new Message('external_module_error', 'Error al obtener el módulo'),

  new Message('alertCarWash_title', 'Car wash prices'),
  new Message('alertCarWash_title_1', 'Schedule'),
  new Message('alertCarWash_title_2', 'Instructions'),

  new Message('alertCarWash_text_1', 'Small: €12,50'),
  new Message('alertCarWash_text_2', 'Standard: €14,00'),
  new Message('alertCarWash_text_3', 'Big: €15,50'),
  new Message('alertCarWash_text_4', 'Mon - Thu: 08:00 - 17:00'),
  new Message('alertCarWash_text_5', 'Sat: 08:00 - 13:00'),
  new Message('alertCarWash_text_6', 'Choose the day you want on the calendar, and if there is space, you will be assigned a wash bay. Park your car in that spot, take the keys to the car wash and pay the cost of the wash. If you want a special wash, ask at the car wash for the cost. Eulen is not responsible for any valuable items found in the vehicle.'),

  new Message('external_module_files', 'Files'),
  new Message('external_module_apps', 'Applications'),
  new Message('external_module_report_incidence', 'Report incidence'),
  new Message('external_module_not_found', 'Module not found'),
  new Message('external_module_error', 'Error getting module'),

  new Message('cookies_title', 'Cookies Policy'),
  new Message(
    'cookies_text_1',
    'Access to this website implies the use of technical cookies.'
  ),
  new Message(
    'cookies_text_2',
    'Cookies are small amounts of information that are stored in the browser used by each User -in the different devices that may be used to navigate- so that the server remembers certain information that later and only the server that implemented it will read.'
  ),
  new Message(
    'cookies_text_3',
    'Cookies make browsing easier, more user-friendly, and do not damage the browsing device. Cookies are automatic procedures for collecting information relating to the preferences determined by the User during his visit to the Website in order to recognize him as a User, and to personalize his experience and use of the Website, and can also, for example, help to identify and resolve errors.'
  ),



];
