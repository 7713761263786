<div class="multiple-reservation">
  <!-- Tabs -->
  <div class="tab">
    <button #defaultOpen class="tablinks" (click)="manageTabs($event, 'tab1')">
      {{ 'reservation_multiple_tab1' | messagesPipe }}
    </button>
    <button #secondTab class="tablinks" (click)="manageTabs($event, 'tab2')">
      {{ 'reservation_multiple_tab2' | messagesPipe }}
    </button>
  </div>

  <!-- Content 1 -->
  <div id="tab1" class="tabcontent">
    <form
      class="row mui-form"
      (ngSubmit)="searchByPosition()"
      [formGroup]="multipleReservationForm"
      novalidate
    >

      <!-- Start hour -->
      <div class="form-group select-group col-sm-6">
        <div class="mui-select">
          <label for="hourFrom">{{'from' | messagesPipe}}</label>
          <select
            #selectHourFrom
            class="form-control"
            id="hourFrom"
            name="hourFrom"
          >
            <option *ngFor="let hourFrom of possibleHoursFrom" [value]="hourFrom">
              {{hourFrom}}
            </option>
          </select>
        </div>
        <span>:</span>
        <div class="mui-select">
          <select
            #selectMinuteFrom
            class="form-control"
            id="minuteFrom"
            name="minuteFrom"
          >
            <option *ngFor="let minuteFrom of minutesFrom" [value]="minuteFrom">
              {{minuteFrom}}
            </option>
          </select>
        </div>
      </div>

       <!-- Hours -->
       <div class="select-by form-group select-group col-sm-6">
        <div class="mui-select">
          <label for="selectNumberOfHours">{{
            !isDinner
              ? ('reservation_multiple_number_of_hours'
                | messagesPipe
                | uppercase)
              : ('reservation_multiple_number_of_minutes'
                | messagesPipe
                | uppercase)
          }}</label>
          <select
            id="numberOfHours"
            name="numberOfHours"
            class="form-control"
            formControlName="numberOfHours"
          >
            <option [value]="-1" selected disabled>
              {{ 'select' | messagesPipe }}
            </option>
            <option *ngFor="let hour of availableHours" [value]="hour">
              {{ hour }}
            </option>
          </select>
          <span
            class="required"
            [hidden]="multipleReservationForm.controls.numberOfHours.valid"
            >{{ 'error_numeric_required' | messagesPipe }}</span
          >
        </div>
      </div>


      <!-- Start Date -->
      <div class="form-group select-group col-sm-6 mui-textfield">
        <label for="startDate">{{
          'reservation_multiple_start_date' | messagesPipe
        }}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerFromElement
          id="startDate"
          [options]="datepickerFromOptions"
          [locale]="locale"
          (dateChanged)="onStartDateChanged($event)"
        ></datepicker>
        <span class="required" [hidden]="startDate">{{
          'error_required' | messagesPipe
        }}</span>
      </div>

      <!-- End Date -->
      <div class="form-group select-group col-sm-6 mui-textfield">
        <label for="endDate">{{
          'reservation_multiple_end_date' | messagesPipe
        }}</label>
        <datepicker
          class="custom-datepicker"
          #datePickerToElement
          id="endDate"
          [options]="datepickerToOptions"
          [locale]="locale"
          (dateChanged)="onEndDateChanged($event)"
        ></datepicker>
        <span class="required" [hidden]="endDate">{{
          'error_required' | messagesPipe
        }}</span>
      </div>

      <div class="form-group col-sm-12 pt-3">
        <div class="form-check">
          <input name="add-myself" class="form-check-input"
            type="checkbox" [checked]="addLoggedUser" id="checkMyself"
            (change)="checkMyself()">
          <label class="form-check-label mt-1" for="add-myself" for="license">
            Añadirme a mi mismo
          </label>
        </div>
      </div>

      <app-search-people
      [serviceType]="searchType"
      (onSelectedUsersChange)="getSelectedPeople($event)"
      [departmentId]="null"
      [departmentDesc]="null"
      class="col-sm-12 multi-search"
      ></app-search-people>

      <!-- Button -->
      <div class="placed-button">
        <button
          type="submit"
          class="check-availability button-by"
          [disabled]="!isFormValid()"
        >
          <i class="vdp-vista-imagen"></i
          >{{ 'reservation_multiple_search_positions' | messagesPipe }}
        </button>
      </div>
    </form>
  </div>

  <!-- Select building and area -->
  <div id="tab2" class="tabcontent">
    <form
      class="row mui-form"
      [formGroup]="multipleBuildingSearchForm"
      novalidate
      (ngSubmit)="seeAvailablePositions2()">

      <!-- Building -->
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectBuildings">{{
            'building' | messagesPipe | uppercase
          }}</label>
          <select
            #selectBuildings
            id="selectBuildings"
            name="selectBuildings"
            class="form-control"
            formControlName="idBuilding"
            (change)="changeBuilding()"
          >
            <option [value]="-1" selected>
              {{ 'select_building' | messagesPipe }}
            </option>
            <option *ngFor="let building of buildings" [value]="building.id">
              {{ building.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Floor -->
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectFloor">{{
            'floor' | messagesPipe | uppercase
          }}</label>
          <select
            #selectFloor
            id="selectFloor"
            name="selectFloor"
            class="form-control"
            formControlName="idFloor"
            (change)="changeFloor()"
          >
            <option [value]="-1">{{ 'select_floor' | messagesPipe }}</option>
            <option *ngFor="let floor of floors" [value]="floor.id">
              {{ floor.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Area -->
      <div class="select-by form-group select-group col-sm-12">
        <div class="mui-select">
          <label for="selectArea">{{ 'area' | messagesPipe | uppercase }}</label>
          <select
            #selectArea
            id="selectArea"
            name="selectArea"
            class="form-control"
            formControlName="idArea"
          >
            <option [value]="-1">{{ 'select_area' | messagesPipe }}</option>
            <option *ngFor="let area of areas" [value]="area.id">
              {{ area.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Button -->
      <!-- Button -->
      <div class="bottom-buttons">
        <div>
          <button
            type="button"
            class="check-availability button-by"
            (click)="defaultTab.nativeElement.click()"
          >
            <i class="vdp-go-back"></i
            >{{ 'resetPassword_return_btn' | messagesPipe }}
          </button>
        </div>
        <div>
          <button
            type="submit"
            class="check-availability button-by"
            [disabled]="isSearchBuildingValid()"
          >
            <i class="vdp-map"></i
            >{{ 'reservation_multiple_see_positions' | messagesPipe }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
