import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { gymConfiguaration } from 'src/app/core/models/gym.class';
import { GymService } from 'src/app/shared/services/gym.service';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from '../../../core/constants/consts';
import { ReservationCarwashWs } from '../../../core/models/reservation.carwash.ws';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { CarWashService } from '../../../shared/services/car-wash.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { slideButtons } from '../../../shared/utils/reservation.list.animations';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-gym-resoruces-list',
  templateUrl: './gym-resoruces-list.component.html',
  styleUrls: ['./gym-resoruces-list.component.scss'],
  animations: [slideButtons()],
})
export class GymResorucesListComponent implements OnInit {

  gymList: ReservationCarwashWs[] = [];
  gymHistoryList: ReservationCarwashWs[] = [];
  showOptionsButtons: boolean = false;
  gymConfiguaration: gymConfiguaration;
  showReserveForm = false;
  dataEdit = null;
  ie: boolean;

  messagesPipe = new MessagesPipe();
  constructor(
    private gymService: GymService,
    private carWashService: CarWashService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private sideModalService: SideModalService,
    private route: ActivatedRoute
  ) {
    this.showOptionsButtons = false;
    this.ie = false;
  }

  ngOnInit(): void {
    this.getGymConfiguaration();
    this.closeReserve();
    let userAgent: string, ieReg: RegExp;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.ie = ieReg.test(userAgent);
  }

  showButtons(): void {
    this.showOptionsButtons = !this.showOptionsButtons;
  }

  getGymConfiguaration() {
    this.gymService.gymConfiguaration().
      subscribe(
        {
          next: res => {
            this.gymConfiguaration = res;
            this.getGymMe();
            this.getHistoryGym();

          },
          error: err => console.log(err)
        }
      )
  }

  getHistoryGym() {
    this.gymService.historic(this.gymConfiguaration.resourceTypeForHourCode).subscribe(
      (res) => {
        if (res) {
          this.gymHistoryList = res.content;
        } else {
          this.gymHistoryList = [];
        }
      },
      () => {
        this.gymHistoryList = [];
      }
    );
  }

  getGymMe() {
    this.gymService.ReservationsMe(this.gymConfiguaration.resourceTypeForHourCode).subscribe(
      (res) => {
        if (res) {
          this.gymList = res.content;
        } else {
          this.gymList = [];
        }
      },
      () => {
        this.gymList = [];
      }
    );
  }

  cancelCarWashReservation(carwashReservationId: number) {
    this.spinnerService.show();
    this.gymService.CancelReservation
      (carwashReservationId)
      .subscribe(
        (res) => {
          this.getGymMe();
          this.flashMessagesService.show(
            this.messagesPipe.transform('gym_resources_cancel_reservation_ok'),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
          this.spinnerService.hide();
        },
        () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform(
              'gym_resources_cancel_reservation_error'
            ),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
          this.spinnerService.hide();
        }
      );
  }

  openReserve() {
    this.sideModalService.openSideModal(
      'reserve',
      {},
      this.messagesPipe.transform('gym_resources_reserve_title'),
      this.route,
      false
    );
  }

  closeReserve() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res.close && res.nameComponent) {
        this.showOptionsButtons = false;
        this.getGymMe();
      }
    });
  }

  getSlideButtonsClass(): string {
    if (this.ie) {
      const el = document.getElementById('carWash-list');
      if (this.showOptionsButtons) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    } else {
      if (this.showOptionsButtons) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }
}

