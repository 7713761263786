<div class="modal" id="modalConfig" #modalConfig>
    <div class="modal__content" [ngClass]="{'modal-web': isWebVersion}">
        <div *ngIf="!dontShowReminder" class="modal__content--reminder" id="modal__reminder">
            <div class="notification">
                <span class="message">Si vas a realizar jornada completa, recuerda marcar la hora de inicio</span>
                <span class="check">
                    <input name="checkReminder" (change)="checkDontShowAgainReminder($event)" type="checkbox">
                    <label for="checkReminder">No volver a mostrar</label>
                </span>
                <button (click)="closeReminderMessage()" class="btn waves-effect">Aceptar</button>
            </div>
        </div>
        <div class="modal__content--title" *ngIf="!fullPage">
            <span>{{reserveDesk_title}}</span>
            <span class="code">{{reservation?.code}}</span>
        </div>
        <div class="modal__content--timeLine" id="calendar">
            <div class="calendar" *ngIf="reservation">
                <ul [ngClass]="getClassInterval(xMin)">
                    <li *ngFor="let hour of hours">{{hour}}</li>
                </ul>
                <div class="disabled" [ngClass]="getClassScheduleDivToday(xMin)"></div>
                <div *ngFor="let reservation of reservation.reservations">
                    <div *ngIf="reservation.id != idReservation" class="occupied" [ngClass]="[getClassScheduleDiv(reservation.dateFrom, reservation.dateTo, xMin, reservation.minuteFrom, reservation.minuteTo)]">
                        <div class="message" >
                            <span *ngIf="!reservation.userTo">{{reserveDesk_busy}}</span>
                            <span class="username" *ngIf="reservation.userTo && reservation.userTo.showHotdeskReservation">
                                {{reservation_busy_by}} {{reservation.userTo.userName}}
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let reservation of reservationFree; let i = index" >
                    <div id="desk-{{i}}" class="free" [ngClass]="[getClassScheduleDivHour(reservation.dateFrom,reservation.minFrom, xMin), reservation?.active ? 'active' : '']"
                        (click)="includeReserver(reservation)">
                        <div class="message">
                            <span *ngIf="!reservation?.active">{{reserveDesk_toSelect}}</span>
                            <span *ngIf="reservation?.active">{{reserveDesk_selected}}</span>
                            <span class="vdp-icon-mas"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal__content--bottom">
            <a (click)="closeModal()" *ngIf="!fullPage">{{reserveDesk_cancel}}</a>
            <a class="reserve" *ngIf="showAllReservation && !edit && !isDinner" (click)="addReservationDeskAll()">{{reserveDesk_reserve_all}}</a>
            <a class="reserve" *ngIf="!edit && showReservation" (click)="addReservationDesk()">{{reserveDesk_reserve}}</a>
            <a class="reserve" *ngIf="edit" (click)="editReservationDesk()">{{reserveDesk_edit}}</a>
        </div>
    </div>
</div>