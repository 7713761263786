import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BoxOfficeService } from 'src/app/shared/services/box-office.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { SearchPeopleComponent } from '../search-people/search-people.component';
import { Department as Department } from 'src/app/core/models/department';
import { DepartmentService } from 'src/app/shared/services/department.service';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-share-my-position',
  templateUrl: './share-my-position.component.html',
  styleUrls: ['./share-my-position.component.scss'],
})
export class ShareMyPositionComponent implements OnInit {
  messagesPipe = new MessagesPipe();
  @Input() visible: boolean;
  @Input() myCurrentPosition: any;
  @Output() closeModal = new EventEmitter();
  searchType = 'byDepartment';

  @ViewChild(SearchPeopleComponent) searchPeople: SearchPeopleComponent;
  selectedPeople: any[] = [];
  lstUsers = [];

  departments: Department[];
  departmentSelected = null;
  descDepartmentSelected = '';

  user: UserLdapWs;

  url: String;
  selectOptionMenu: string;

  constructor(
    private authenticationService: AuthenticationService,
    private boxOfficeService: BoxOfficeService,
    private flashMessagesService: FlashMessagesService,
    private departmentService: DepartmentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position/') ? 'dinner' : 'desks';
    if (this.user.headquarters) {
      this.getDepartmentListByHeadquarter(
        this.authenticationService.getToken(),
        this.user.headquarters.id
      );
    }
  }

  closeComponent(): void {
    this.searchPeople.reset();
    this.closeModal.emit();
  }

  getSelectedPeople($event): void {
    this.selectedPeople = $event;
  }

  share(): void {
    const peopleSelectedIds = [];
    this.selectedPeople.forEach(element => {
      peopleSelectedIds.push(element.id);
    });
    this.boxOfficeService
      .shareUsers(
        this.myCurrentPosition.id,
        peopleSelectedIds,
        this.authenticationService.getToken(),
        this.selectOptionMenu
      )
      .subscribe(
        (response: any) => {
          this.closeComponent();
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_share_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_share_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        );
    }

  getDepartmentListByHeadquarter(token: string, headquartersId: number) {
    this.departmentService
      .getDepartmentListByHeadquarterId(token, headquartersId)
      .subscribe(
        (data) => {
          this.departments = data;
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_departments_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  onChangeDept(value: any, event: any) {
    this.departmentSelected = value != 0 ? value : null;
    this.descDepartmentSelected =
      event.target.options[event.target.options.selectedIndex].text;
    this.searchPeople.clearAndChangeAutoComplete(this.departmentSelected, this.descDepartmentSelected);
  }
}
