import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import {
  AREA_DATA_API_PATH,
  AREA_DINNER_DATA_API_PATH,
  AREA_HOTDESK_DATA_API_PATH,
  SERVICE_BASE_URL,
} from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getAreaListAll(id: String): Observable<any> {
    const url = `${SERVICE_BASE_URL}${AREA_DATA_API_PATH}${id}?access_token=${this.authenticationService.getToken()}`;
    return this.http
      .get(url, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getAreaListAllOption(
    id: String,
    option: string,
    listAttributes?: DeskAttribute[]
  ): Observable<any> {
    const selectedOption =
      option == 'dinner'
        ? AREA_DINNER_DATA_API_PATH
        : AREA_HOTDESK_DATA_API_PATH;
    let url = `${SERVICE_BASE_URL}${selectedOption}${id}?access_token=${this.authenticationService.getToken()}`;

    if (
      selectedOption == AREA_HOTDESK_DATA_API_PATH &&
      listAttributes?.length > 0
    ) {
      listAttributes.map((attribute) => {
        url = url + `&attributesId=${attribute}`;
      });
    }

    return this.http
      .get(url, OPTIONS)
      .map((res: any) => {
        return res.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getIntervalByAreaId(areaId: any, token: string): Observable<any[]> {
    const url = `${SERVICE_BASE_URL}/api/areas/interval/${areaId}?access_token=${token}`;

    return this.http
      .get(url, OPTIONS)
      .map((response) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getAreasForMultiReservation(
    floorId: number,
    dateFrom: number,
    dateTo: number,
    hotdeskNumber: number,
    hourFrom: number,
    hourNumber: number,
    usersList: any[],
    token: string,
    option: string,
  ){

    const url = `${SERVICE_BASE_URL}/api/${option}/multiReservationsArea?access_token=${token}`;

    const body = {
      floorId,
      dateFrom,
      dateTo,
      hotdeskNumber,
      hourFrom,
      hourNumber,
      usersList,
    };

    return this.http
      .post(url, body, OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
