<app-spinner></app-spinner>

<div class="row gym-list">
  <!-- ### Col 1: List ###  -->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'gym_resources_list_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-carWash" *ngIf="gymList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'gym_resources_list_not_reserve' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card" *ngFor="let gymContent of gymList">
      <div class="detail">
        <span class="detail--title">
          {{ 'gym_resources_list_title' | messagesPipe }}:
          {{ gymContent?.codeResourceForHour }}</span>
        <span class="detail--license" *ngIf="gymContent?.license">
          {{ 'carWash_reserve_license' | messagesPipe }}:
          {{ gymContent?.license }}
        </span>
        <span class="detail--headquarters">
          {{ 'headquarters' | messagesPipe }}
          {{ gymContent?.nameHeadquarter }}
        </span>
        <span class="detail--date">
          {{ gymContent?.dateFrom | date: 'mediumDate' }}
          {{ 'time_str_from' | messagesPipe }}
          {{ gymContent?.dateFrom | date: 'shortTime' }}
          {{ 'time_str_to' | messagesPipe }}
          {{ gymContent?.dateTo | date: 'shortTime' }}
        </span>
      </div>
      <span class="state">{{ gymContent?.codeResourceForHour }}</span>

      <!-- Card Options -->
      <div class="card-options">
        <!-- Available Options -->
        <div class="available-options">
          <!-- Cancel -->
          <span class="available-options__cancel" (click)="cancelCarWashReservation(gymContent?.id)">
            {{ 'reservation_give' | messagesPipe }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- ### Col 2: History ###-->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'reservation_historical_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-carWash" *ngIf="gymHistoryList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'reservation_historical_description' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card history" *ngFor="let gymHistory of gymHistoryList">
      <div class="detail">
        <span class="detail--title">
          {{ 'gym_resources_list_title' | messagesPipe }}:
          {{ gymHistory?.codeResourceForHour }}</span>
        <span class="detail--license" *ngIf="gymHistory?.license">
          {{ 'carWash_reserve_license' | messagesPipe }}:
          {{ gymHistory?.license }}
        </span>
        <span class="detail--headquarters">
          {{ 'headquarters' | messagesPipe }}
          {{ gymHistory?.nameHeadquarter }}
        </span>
        <span class="detail--date">
          {{ gymHistory?.dateFrom | date: 'mediumDate' }}
          {{ 'time_str_from' | messagesPipe }}
          {{ gymHistory?.dateFrom | date: 'shortTime' }}
          {{ 'time_str_to' | messagesPipe }}
          {{ gymHistory?.dateTo | date: 'shortTime' }}
        </span>
      </div>
      <span class="state">{{ gymHistory?.codeResourceForHour }}</span>
    </div>
  </div>
</div>

<!--  ### Buttons ### -->
<div class="add-carWash">
  <button type="button" class="vdp-icon-mas add" (click)="showButtons()"
    [ngClass]="{ closed: showOptionsButtons }"></button>
  <div class="gym-list" id="gym-list" [@slideButtons]="getSlideButtonsClass()">
    <div class="gym-list-buttons">
      <!-- Option 1 -->
      <button type="button" (click)="openReserve()">
        {{ 'gym_resources_list_button' | messagesPipe }}
        <i class="vdp-reserve-gym-resources"></i>
      </button>
    </div>
  </div>
</div>

<side-modal></side-modal>