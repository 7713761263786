import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map } from 'rxjs/operators';
import { MyDatePicker } from 'src/app/shared/components/date_picker/datepicker.component';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { BoxOfficeService } from 'src/app/shared/services/box-office.service';
import { Utils } from 'src/app/shared/utils/utils';
import { AreaWs } from '../../../core/models/area.ws';
import { BuildingWs } from '../../../core/models/building.ws';
import { DeskAttribute } from '../../../core/models/deskAttribute';
import { Floor } from '../../../core/models/floor';
import { FloorWs } from '../../../core/models/floor.ws';
import { Headquarters } from '../../../core/models/headquarters';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { AreaService } from '../../../shared/services/area.service';
import { AttributeService } from '../../../shared/services/attribute.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BuildingService } from '../../../shared/services/building.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { FloorService } from '../../../shared/services/floor.service';

@Component({
  selector: 'app-reservation-by-position',
  templateUrl: './reservation-by-position.component.html',
  styleUrls: ['./reservation-by-position.component.scss'],
})
export class ReservationByPositionComponent implements OnInit, AfterViewInit {
  @ViewChild('datePickerElement') datePickerElement: MyDatePicker;
  loading: boolean;
  user: UserLdapWs;
  userHeadquarter: Headquarters;
  reservationPositionForm: FormGroup;
  buildings: BuildingWs[];
  floors: Floor[];
  attributes: DeskAttribute[];
  oldAttributesCall: number[] = [0];
  areas: any;
  datepickerOptions: any = {};
  reservationDate: Date;
  messagesPipe = new MessagesPipe();
  reservedPositionsByPeriod: number;
  canBook = true;
  locale: string;
  url: String;
  selectOptionMenu: string;

  constructor(
    private authenticationService: AuthenticationService,
    private buildingService: BuildingService,
    private floorService: FloorService,
    private areaService: AreaService,
    private attributeService: AttributeService,
    private flashMessagesService: FlashMessagesService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private utils: Utils,
    private router: Router,
    private boxOffice: BoxOfficeService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.userHeadquarter = this.user.headquarters;
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position')
      ? 'dinner'
      : 'desks';
    this.createForm();
    this.getBuilding();
    this.getReservationAttributesList();
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays()
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.utils.setDatePickerDate(new Date(), this.datePickerElement);
    }, 0);
  }

  createForm() {
    this.reservationPositionForm = new FormGroup({
      idAttribute: new FormControl(),
      idBuilding: new FormControl(-1, Validators.required),
      idFloor: new FormControl(-1, Validators.required),
      idZona: new FormControl(-1, Validators.required),
    });

    this.reservationPositionForm.get('idFloor').disable();
    this.reservationPositionForm.get('idZona').disable();
  }

  validForm() {
    if (
      this.reservationPositionForm.valid &&
      this.reservationPositionForm.get('idBuilding').value > 0 &&
      this.reservationPositionForm.get('idFloor').value > 0 &&
      this.reservationPositionForm.get('idZona').value > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  changeBuilding() {
    if (this.reservationPositionForm.get('idBuilding').value > 0) {
      this.reservationPositionForm.patchValue({
        idFloor: -1,
        idZona: -1,
      });
      this.reservationPositionForm.get('idZona').disable();
      this.getFloor(this.reservationPositionForm.get('idBuilding').value);
    } else {
      this.floors = [];
      this.areas = [];
      this.reservationPositionForm.get('idFloor').disable();
      this.reservationPositionForm.get('idZona').disable();
    }
  }

  changeFloor() {
    if (this.reservationPositionForm.get('idFloor').value > 0) {
      this.reservationPositionForm.patchValue({
        idZona: -1,
      });
      this.getArea(this.reservationPositionForm.get('idFloor').value);
    } else {
      this.areas = [];
      this.reservationPositionForm.get('idZona').disable();
    }
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
      this.checkBookingPermissions();
    } else {
      this.reservationDate = null;
    }
  }

  checkPosition() {
    const area = this.reservationPositionForm.get('idZona').value;
    const date = this.reservationDate;
    const token = this.user.accessToken;
    const attributes = this.reservationPositionForm.get('idAttribute')?.value;
    this.router.navigate(
      [
        'menu',
        this.url.includes('/dinning-position')
          ? 'dinning-position'
          : 'job-position',
        'select-position',
        area,
        date.getTime(),
        token,
      ],
      { queryParams: { atr: attributes } }
    );
  }
  changeAttribute() {
    if (this.attributes?.length > 0) {
      this.getBuilding(this.reservationPositionForm.get('idAttribute').value);
    }
  }

  getReservationAttributesList() {
    this.attributeService.reservationAttributeList().subscribe(
      (res) => {
        if (res && res.length > 0) {
          this.attributes = res;
        } else {
          this.attributes = [];
        }
      },
      () => {
        this.attributes = [];
      }
    );
  }

  getBuilding(listAttributes?) {
    if (listAttributes != this.oldAttributesCall) {
      this.buildings = [];
      this.buildingService
        .getBuildingListByHeadquartersAndAttribute(
          this.user.accessToken,
          listAttributes
        )
        .pipe(
          map((response) =>
            response.filter((building: BuildingWs) => building.enabled)
          )
        )
        .subscribe((res) => {
          if (res.length > 0) {
            this.reservationPositionForm.get('idBuilding').enable();
            this.buildings = res;
            if (this.buildings.length > 1) {
              this.reservationPositionForm.get('idBuilding').patchValue(-1);
            } else if (this.buildings.length === 1) {
              this.reservationPositionForm
                .get('idBuilding')
                .patchValue(res[0].id);
              this.changeBuilding();
            }
          } else {
            if (listAttributes) {
              this.flashMessagesService.show(
                this.messagesPipe.transform('message_attribute_notBuild'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );
            }
            this.buildings = [];
            this.reservationPositionForm.get('idBuilding').disable();
            this.floors = [];
            this.reservationPositionForm.get('idFloor').disable();
            this.areas = [];
            this.reservationPositionForm.get('idZona').disable();
          }
        });
    }

    this.oldAttributesCall = listAttributes;
  }

  getFloor(id) {
    if (id > 0) {
      this.floors = [];
      this.floorService
        .getFloorsListAllByAttribute(
          id,
          this.reservationPositionForm.get('idAttribute')?.value
        )
        .pipe(
          map((response) => response.filter((floor: FloorWs) => floor.enabled))
        )
        .subscribe((res) => {
          if (res.length > 0) {
            this.reservationPositionForm.get('idFloor').enable();
            this.floors = res;
            if (this.floors.length > 1) {
              this.reservationPositionForm.get('idFloor').patchValue(-1);
            } else if (this.floors.length === 1) {
              this.reservationPositionForm.get('idFloor').patchValue(res[0].id);
              this.changeFloor();
            }
          } else {
            this.floors = [];
          }
        });
    }
  }

  getArea(id) {
    if (id > 0) {
      this.areas = [];
      this.areaService
        .getAreaListAllOption(
          id,
          this.selectOptionMenu,
          this.reservationPositionForm.get('idAttribute')?.value
        )
        .pipe(
          map((response) => response.filter((area: AreaWs) => area.enabled))
        )
        .subscribe(
          (res) => {
            if (res.length > 0) {
              this.reservationPositionForm.get('idZona').enable();
              this.areas = res;
              if (this.areas.length > 1) {
                this.reservationPositionForm.get('idZona').patchValue(-1);
              } else {
                this.reservationPositionForm
                  .get('idZona')
                  .patchValue(res[0].id);
              }
            } else {
              this.areas = [];
              this.reservationPositionForm.get('idZona').disable();
            }
          },
          () => {
            this.reservationPositionForm.get('idZona').disable();
            this.areas = [];
          }
        );
    }
  }

  checkBookingPermissions() {
    if (!this.userHeadquarter) {
      return;
    }
    const hotdeskPeriod = this.userHeadquarter.hotdeskPeriod;
    const maxHotdeskPeriodDays = this.userHeadquarter.maxHotdeskPeriodDays;

    if (hotdeskPeriod && maxHotdeskPeriodDays) {
      this.spinnerService.show();

      this.boxOffice
        .getUserReservationsByPeriod(
          hotdeskPeriod,
          this.utils.applyDateMask(this.reservationDate),
          this.user.accessToken,
          this.selectOptionMenu
        )
        .pipe(
          finalize(() => {
            this.spinnerService.hide();
          })
        )
        .subscribe(
          (response: any) => {
            this.reservedPositionsByPeriod = response.count;

            this.reservedPositionsByPeriod < maxHotdeskPeriodDays
              ? (this.canBook = true)
              : (this.canBook = false);

            if (!this.canBook) {
              this.flashMessagesService.show(
                this.messagesPipe.transform('reservation_permissions'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );
            }
          },
          (error) => {
            this.flashMessagesService.show(
              this.messagesPipe.transform('reservation_recover_desk_error'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        );
    } else {
      return;
    }
  }
}
