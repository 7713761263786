import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserLdapWs } from 'src/app/core/models/user.ldap.ws';
import { environment } from '../../../../../../environments/environment';
import { SideModalService } from '../../../../../views/side-modal/side-modal.service';
import { MessagesPipe } from '../../../../pipes/messages.pipe';
import { AttendanceService } from '../../../../services/attendance.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { BaseService } from '../../../../services/base.service';
import { FlashMessagesService } from '../../../../services/flash-messages.service';

@Component({
  selector: 'app-calendar-edit-not-confirm',
  templateUrl: './calendar-edit-not-confirm.component.html',
  styleUrls: ['./calendar-edit-not-confirm.component.scss'],
})
export class CalendarEditNotConfirmComponent implements OnInit {
  assistancesList: any;
  shiftPriority: any;
  mainVehicle: any;
  showParkingSpotInfo: any;
  assistanceSelected: any;
  attendanceIntegrationParking: any;
  attendanceIntegrationDinnerDesk: any;
  userSelect: any;

  assistanceForm: FormGroup;
  assistanceFormTest: FormGroup;
  messagesPipe = new MessagesPipe();
  dinnerShift: any;
  user: UserLdapWs;

  buttonDisabledConfirm = false;
  placesAvailables: any;

  constructor(
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private authenticationService: AuthenticationService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private router: Router,
    private sideModalService: SideModalService
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      const {
        assistancesList,
        shiftPriority,
        mainVehicle,
        showParkingSpotInfo,
        assistanceSelected,
        attendanceIntegrationParking,
        attendanceIntegrationDinnerDesk,
        userSelect,
      } = this.router.getCurrentNavigation().extras.state;
      this.assistancesList = assistancesList;
      this.shiftPriority = shiftPriority;
      this.mainVehicle = mainVehicle;
      this.showParkingSpotInfo = showParkingSpotInfo;
      this.assistanceSelected = assistanceSelected;
      this.attendanceIntegrationParking = attendanceIntegrationParking;
      this.attendanceIntegrationDinnerDesk = attendanceIntegrationDinnerDesk;
      this.userSelect = userSelect;
    }
  }

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.initForm();
    this.dinnerShift = this.user.dinnerShiftFrom
      ? moment(this.user.dinnerShiftFrom).format('HH:mm')
      : '';
  }

  initForm() {
    this.assistanceForm = this.formBuilder.group({
      assistances: this.generateArrayControl(this.assistancesList),
    });
  }

  public get assistances() {
    return this.assistanceForm.get('assistances') as FormArray;
  }

  generateArrayControl(assistancesList): FormArray {
    const arrayAux = [];
    assistancesList?.forEach((assistance) => {
      arrayAux.push(
        new FormGroup({
          turn: new FormControl(
            assistance?.info?.dinnerDesk?.hasRequestDinnerDesk === true &&
            !this.checkShiftPriority(
              assistance?.info?.dinnerDesk?.availableTurns
            )
              ? this.shiftPriority
              : '0'
          ),
          placeParking: new FormControl(
            this.getMainVehicule(
              assistance?.info?.parking?.parkingSizeAttendance
            )?.enabled && assistance?.info?.parking?.hasRequestParking
              ? this.mainVehicle
              : '0'
          ),
        })
      );
    });
    return new FormArray(arrayAux);
  }

  //CALL API

  update(attendances: any) {
    this.attendanceService.update(attendances, this.userSelect).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_confirm_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_update_error')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledConfirm = false;
      }
    );
  }

  // CHECK
  confirmAssistance() {
    this.buttonDisabledConfirm = true;
    const attendancesSave = [];
    this.assistanceForm.value.assistances.forEach((valueTest, index) => {
      const valueTurn =
        valueTest.turn > 0 ? parseInt(valueTest.turn, 10) : null;
      const valuePlaceParking =
        valueTest.placeParking > 0
          ? parseInt(valueTest.placeParking, 10)
          : null;
      let availableTurn = null;
      let availablePlaceParking = null;
      if (
        valueTurn &&
        this.assistancesList[index].info.dinnerDesk &&
        this.assistancesList[index].info.dinnerDesk.availableTurns.length > 0
      ) {
        availableTurn = this.assistancesList[
          index
        ].info.dinnerDesk.availableTurns.filter((turn) => {
          return parseInt(turn.id, 10) === valueTurn;
        });
      }

      if (
        valuePlaceParking &&
        this.assistancesList[index].info.parking &&
        this.assistancesList[index].info.parking.parkingSizeAttendance.length >
          0
      ) {
        availablePlaceParking = this.assistancesList[
          index
        ].info.parking.parkingSizeAttendance.filter((place) => {
          return parseInt(place.typeId, 10) === valuePlaceParking;
        });
      }

      if (
        availableTurn &&
        this.checkDinnerDays(this.assistancesList[index].indexWeek) &&
        this.checkDinnerMonths(this.assistancesList[index].dateComplete)
      ) {
        const availableTurnsAux = {
          shiftFrom:
            availableTurn && availableTurn.length > 0
              ? availableTurn[0].shiftFrom
              : '',
          shiftTo:
            availableTurn && availableTurn.length > 0
              ? availableTurn[0].shiftTo
              : '',
        };
        this.assistancesList[index].info.dinnerDesk.availableTurns = [
          availableTurnsAux,
        ];
      } else {
        this.assistancesList[index].info.dinnerDesk = null;
      }
      if (
        (availablePlaceParking && availablePlaceParking[0].enabled) ||
        (availablePlaceParking &&
          this.assistancesList[index].info.parking.parkingWaitingListEnabled)
      ) {
        const availablePlaceParkingAux = {
          typeId: availablePlaceParking[0].typeId,
          code: availablePlaceParking[0].code,
          size: availablePlaceParking[0].size,
          enabled: availablePlaceParking[0].enabled,
        };
        this.assistancesList[index].info.parking.parkingSizeAttendance =
          availablePlaceParkingAux;
      } else {
        this.assistancesList[index].info.parking = null;
      }

      attendancesSave.push(this.assistancesList[index].info);
    });
    this.update(attendancesSave);
  }

  checkDinnerDays(indexWeek) {
    let hasDinner = false;
    const dinnerDays = environment['dinnerDays'];
    if (dinnerDays) {
      dinnerDays.forEach((element) => {
        if (element == indexWeek) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  checkDinnerMonths(day) {
    const date = moment(day, 'DD-MM-YYYY');
    const month = date.month() + 1;
    let hasDinner = false;
    const dinnerMonths = environment['dinnerMonths'];
    if (dinnerMonths) {
      dinnerMonths.forEach((element) => {
        if (element == month) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  checkShiftPriority(value: any) {
    let res = true;
    if (value) {
      value.forEach((element) => {
        if (element.id == this.shiftPriority) {
          res = false;
        }
      });
    }

    return res;
  }

  checkSelectedPlace(value: any) {
    let res = false;
    if (value.main) {
      res = true;
    }

    return res;
  }

  // MESSAGE

  getEnableSelectParking(assistance) {
    switch (true) {
      case assistance?.info?.parking == null:
        return false;
      case !assistance?.info?.parking?.hasVehicle:
        return false;

      case assistance?.info?.parking?.parkingWaitingListEnabled &&
        assistance?.info?.parking?.hasVehicle &&
        !assistance?.info?.parking?.hasParking:
        return true;

      case assistance?.info?.parking?.hasVehicle &&
        this.parkingSizeAttendanceEnable(assistance) &&
        !assistance?.info?.parking?.hasParking:
        return true;

      case assistance?.info?.parking?.parkingSizeAttendance == null ||
        assistance?.info?.parking?.parkingSizeAttendance?.length <= 0 ||
        !this.parkingSizeAttendanceEnable(assistance):
        return false;
      default:
        return false;
    }
  }

  getMessageParking(assistance) {
    switch (true) {
      case assistance?.info?.parking == null:
        return this.messagesPipe.transform(
          'assistance_text_not_space_available'
        );

      case !assistance?.info?.parking?.hasVehicle:
        return this.messagesPipe.transform(
          'assistance_text_not_vehicle_assign'
        );

      case assistance?.info?.parking?.parkingWaitingListEnabled &&
        !this.parkingSizeAttendanceEnable(assistance) &&
        assistance?.info?.parking?.hasVehicle &&
        !assistance?.info?.parking?.hasParking:
        return this.messagesPipe.transform(
          'assistance_text_parking_not_space_available_waiting'
        );

      case assistance?.info?.parking?.hasVehicle &&
        this.parkingSizeAttendanceEnable(assistance) &&
        !assistance?.info?.parking?.hasParking:
        if (assistance?.info?.parking?.hasRequestParking) {
          if (
            this.getMainVehicule(
              assistance?.info?.parking?.parkingSizeAttendance
            )?.enabled
          ) {
            return this.messagesPipe.transform(
              'assistance_text_parking_complete_space'
            );
          } else if (
            this.getMainVehicule(
              assistance?.info?.parking?.parkingSizeAttendance
            ) &&
            assistance?.info?.parking?.parkingWaitingListEnabled
          ) {
            //TODO: Nuevo Texto por definir
            return '';
          } else {
            return this.messagesPipe.transform(
              'assistance_text_parking_not_complete_space_selected'
            );
          }
        } else {
          return this.messagesPipe.transform(
            'assistance_text_parking_available'
          );
        }

      case assistance?.info?.parking?.parkingSizeAttendance == null ||
        assistance?.info?.parking?.parkingSizeAttendance?.length <= 0 ||
        !this.parkingSizeAttendanceEnable(assistance):
        return this.messagesPipe.transform(
          'assistance_text_not_request_parking'
        );
      default:
        return this.messagesPipe.transform(
          'assistance_text_not_space_available_contactSystem'
        );
    }
  }

  getMessageDinnerDesk(assistance) {
    switch (true) {
      case !this.checkDinnerDays(assistance.indexWeek) ||
        !this.checkDinnerMonths(assistance.dateComplete):
        return this.messagesPipe.transform(
          'assistance_text_not_dining_room_service'
        );

      case assistance?.info?.dinnerDesk?.availableTurns?.length <= 0:
        return this.messagesPipe.transform(
          'assistance_text_not_assign_dinner_space'
        );

      case assistance?.info?.dinnerDesk?.hasRequestDinnerDesk &&
        this.checkShiftPriority(assistance?.info?.dinnerDesk?.availableTurns) &&
        assistance?.info?.dinnerDesk?.availableTurns?.length > 0:
        return this.messagesPipe.transform(
          'assistance_text_not_assign_dinner_space_hour_select'
        );

      case !this.checkShiftPriority(
        assistance?.info?.dinnerDesk?.availableTurns
      ) && assistance?.info?.dinnerDesk?.availableTurns?.length > 0:
        return this.messagesPipe.transform(
          'assistance_text_assign_dinner_space'
        );

      case !assistance?.info?.dinnerDesk?.hasRequestDinnerDesk &&
        assistance?.info?.dinnerDesk?.availableTurns?.length > 0:
        return this.messagesPipe.transform('assistance_text_dinner_select');

      default:
        return this.messagesPipe.transform(
          'assistance_text_not_request_dinner_space'
        );
    }
  }

  getMessageAdditionalDinner(assistance) {
    switch (true) {
      case !this.checkDinnerDays(assistance.indexWeek) ||
        !this.checkDinnerMonths(assistance.dateComplete) ||
        assistance.workingHome ||
        assistance.partTime:
        return this.messagesPipe.transform(
          'assistance_text_not_dining_room_service_workingHome_partTime'
        );

      default:
        break;
    }
  }

  // UTILS

  getMainVehicule(parkingSizeAttendance) {
    if (Array.isArray(parkingSizeAttendance)) {
      return parkingSizeAttendance?.find((x) => x.main);
    } else if (parkingSizeAttendance?.main) {
      return parkingSizeAttendance;
    }
  }

  parkingSizeAttendanceEnable(assistance) {
    if (Array.isArray(assistance?.info?.parking?.parkingSizeAttendance)) {
      return assistance?.info?.parking?.parkingSizeAttendance?.find(
        (x) => x.enabled
      );
    } else if (assistance?.info?.parking?.parkingSizeAttendance?.enabled) {
      return assistance?.info?.parking?.parkingSizeAttendance;
    } else {
      return false;
    }
  }

  objectKeys(objeto: any) {
    const values = objeto ? Object.values(objeto) : '';
    return values;
  }

  closeForm() {
    this.assistancesList = [];
    this.assistanceForm.reset();
    this.buttonDisabledConfirm = false;
    this.sideModalService.closeSideModal();
  }
}
