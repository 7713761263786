import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import packageJson from '../../../../../package.json';
import { environment } from '../../../../environments/environment';
import { LOCAL_CURRENT_USER } from '../../../core/constants/const';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
  COLLAPSED_RESOLUTION,
} from '../../../core/constants/consts';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { UserService } from '../../../shared/services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NavigatorRefreshService } from '../../services/navigator.refresh.service';
import { slideButtons } from '../../utils/reservation.list.animations';
import { ThemeSwitcherService } from '../../services/theme-switcher.service';
import LoginData from '../../../core/models/interfaces/LoginData';

@Component({
  templateUrl: 'menu.component.html',
  styleUrls: ['navbar.scss'],
  animations: [slideButtons()],
})
export class MenuComponent implements OnInit {
  user: UserLdapWs;
  showLogoutButton: boolean;
  navCollapsed: boolean;
  navSticky: boolean;
  userName: string;
  linkName = '';
  permissions: MenuPermissions;
  ie: boolean;
  showBack: boolean;
  moduleParking = false;
  moduleShuttle = false;
  moduleEvent = false;
  routerLinkLopd: any;
  version: string;
  externalModules: any[];
  showCookiesDiv: boolean;
  visibleNavElements: Array<string> = [];
  showCarWashAlert = false;
  loginData: LoginData;
  logoUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private navigatorRefreshService: NavigatorRefreshService,
    private azureService: MsalService,
    private location: Location,
    private userService: UserService,
    private themeSwitcher: ThemeSwitcherService
  ) {
    this.showLogoutButton = false;
    this.ie = false;
    this.showBack = false;
  }

  ngOnInit() {
    this.version = packageJson.version;

    //saas change: ini
    // Se elimina el hash de azure y los datos relacionados
    this.removeStorageHash();

    // Se obtiene la paleta de colores al ingresar al menu
    this.themeSwitcher.getTheme();

    this.setLoginData();
    this.setLogo();

    //saas change: end

    var userAgent, ieReg;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.ie = ieReg.test(userAgent);
    this.navCollapsed = window.innerWidth <= COLLAPSED_RESOLUTION;
    this.user = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.setNavElements();
    this.user.headquarters.modules.forEach((element) => {
      if (element.code === '0019' && element.state) {
        this.moduleParking = true;
      } else if (element.code === '0020' && element.state) {
        this.moduleShuttle = true;
      }
      if (element.code == '0029') {
        this.moduleEvent = true;
      }
    });

    if (this.user == null) {
      this.router.navigate(['/login']);
    } else {
      this.setUserName();

      this.navigatorRefreshService
        .getMenuRefreshProfileEmitter()
        .subscribe((imageBase64: string) => {
          this.user = this.authenticationService.getCurrentUser();
          this.setUserName();

          if (imageBase64 && imageBase64.length > 0) {
            this.user.image = imageBase64;
          }
        });

      this.navigatorRefreshService
        .getMenuRefreshLinkEmitter()
        .subscribe((linkName: string) => {
          this.linkName = linkName;
        });
    }
    this.getLanguageDefault();
    this.getExternalModules();
    this.checkCookies();
  }

  setNavElements(): void {
    this.user.headquarters.modules.forEach((element) => {
      if(element.state) {
        this.visibleNavElements.push(element.code);
      }
    });
  }

  setUserName() {
    if (this.user.name && this.user.name.length > 0) {
      this.userName = this.user.name;
    } else {
      this.userName = this.user.username;
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event): void {
    if (event.target.innerWidth <= COLLAPSED_RESOLUTION) {
      this.navCollapsed = true;
    } else {
      this.navCollapsed = false;
    }
  }

  setNavCollapsed(): void {
    this.navCollapsed = !this.navCollapsed;
  }

  setNavSticky(): void {
    this.navSticky = !this.navSticky;
    if (this.router.url === "/menu/carWash/list") {
      // if (localStorage.getItem('AlertCarWashAccepted') === 'true') {
      //   this.showCarWashAlert = false;
      // }
      // else {
      this.showCarWashAlert = true;
      // }
    }
  }

  showLogout() {
    this.showLogoutButton = !this.showLogoutButton;
    if (this.ie) {
      const el = document.getElementById('logout');
      if (this.showLogoutButton) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    }
  }

  getSlideButtonsClass(): string {
    if (!this.ie) {
      if (this.showLogoutButton) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }

  // logout() {
  //   if (environment['use_azure']) {
  //     localStorage.removeItem(LOCAL_CURRENT_USER);
  //     this.azureService.logout();
  //   } else {
  //     this.authenticationService.logout();
  //   }
  // }

  logout() {
    if (this.loginData && this.loginData.loginType === 'msal') {
      localStorage.clear();
      this.azureService.logout();
    } else {
      this.authenticationService.logout();
      // localStorage.removeItem('AlertCarWashAccepted');
    }
  }

  // ToDo: consultar las diferentes variables de los datos extraidos del backend y guardados en localhost, en lugar del environment

  get enableLopd(): boolean {
    return environment['enableLopd'];
  }

  get disableDownload(): boolean {
    return environment['downloadAvailable'];
  }

  get hiddenParking(): boolean {
    return environment['hiddenParking'];
  }

  get hiddenDinnerDesk(): boolean {
    return environment['hiddenDinnerDesk'];
  }

  get hiddenGymResources(): boolean {
    return environment['hiddenGymResources'];
  }

  get hiddenRoomReservation(): boolean {
    return environment['hiddenRoomReservation'];
  }

  get hiddenIncidences(): boolean {
    return environment['hiddenRoomReservation'];
  }

  get hiddenHotDesktop(): boolean {
    return environment['hiddenHotDesktop'];
  }

  isModuleInWebBlackList(moduleId: string): boolean {
    if(environment['webModulesBlackList'] && environment['webModulesBlackList'].includes(moduleId)) {
      return false;
    } else {
      return true;
    }
  }

  goBack() {
    this.location.back();
  }

  showIconBack(): boolean {
    const url = this.activatedRoute.snapshot['_routerState'].url as String;
    if (url.includes('/viewMultiple-position/')) {
      this.showBack = true;
    } else {
      this.showBack = false;
    }

    return this.showBack;
  }

  getLanguageDefault() {
    this.userService
      .getLanguage(this.authenticationService.getToken())
      .subscribe((response) => {
        if (response) {
          this.routerLinkLopd = [`/lopd/${response.text}`];
        }
      });
  }
  getExternalModules() {
    const externalServiceModule = this.user.headquarters.modules.find(
      (module) => {
        return module.code === '0023' && module.state;
      }
    );
    if (externalServiceModule) {
      this.externalModules = this.user.externalModules;
      if (this.externalModules) {
        this.externalModules = this.externalModules.filter((module) => {
          return module.state;
        });
      }
    } else {
      this.externalModules = [];
    }
  }


  checkCookies() {
    if (localStorage.getItem('cookiesPolicyAccepted') === 'true') {
      this.showCookiesDiv = false;
    } else {
      setTimeout(() => {
        this.showCookiesDiv = true;
      }, 1200);
    }
  }

  acceptCookies(): void {
    this.showCookiesDiv = false;
    localStorage.setItem('cookiesPolicyAccepted', 'true');
  }

  acceptAlertCarWash(): void {
    this.showCarWashAlert = false;
    // localStorage.setItem('AlertCarWashAccepted', 'true');
  }

  //change saas: ini
  setLoginData() {
    if (localStorage.getItem('loginData')) {
      this.loginData = JSON.parse(localStorage.getItem('loginData'));
    }
  }

  setLogo() {
    if(environment['saasClient']){
      if (this.loginData.logoUrl) {
        this.logoUrl = this.loginData.logoUrl;
      } else {
        this.logoUrl = '../../assets/img/logo-menu.png';
      }
    } else {
      this.logoUrl = '../../assets/img/logo-menu.png';
    }
  }

  removeStorageHash() {
    localStorage.removeItem('hash');
    localStorage.removeItem('handleRedirectObservable');
  }
  //change saas: end
}
