export const color_palette_alten = `
--primary-color: #063c67;
--primary-color-light: #09518b;
--primary-color-lighter: #0d69b4;
--primary-color-dark: #063c67;
--primary-color-darker: #042037;
--secondary-color: #4a4d4e;
--secondary-color-light: #4a4d4e;
--background-color: #2d383b;
--navbar-background: #fff;
--navbar-color-inverse: #333;
--navbar-color-inverse-light: #707070;
--navbar-color-inverse-lighter: #8f8f8f;
--navbar-link-hover: #fff;
--navbar-link-hover-background: var(--primary-color-light);
--logo-background: var(--navbar-background);
--logo-color-inverse: var(--navbar-color-inverse);
--icon-color-parkingElectric: #fff;
--icon-color-parkingWaiting: #fff;
`;
export const color_palette_sdos = `
--primary-color: #28b0fa;
--primary-color-light: #54c2fe;
--primary-color-lighter: #7dd2ff;
--primary-color-dark: #1a92d2;
--primary-color-darker: #1474a8;
--secondary-color: #4a4d4e;
--secondary-color-light: #8f8f8f;
--background-color: #2d383b;
--navbar-background: #fff;
--navbar-color-inverse: #333;
--navbar-color-inverse-light: #707070;
--navbar-color-inverse-lighter: #8f8f8f;
--navbar-link-hover: #fff;
--navbar-link-hover-background: var(--primary-color-light);
--logo-background: var(--navbar-background);
--logo-color-inverse: var(--navbar-color-inverse);
--icon-color-parkingElectric: #fff;
--icon-color-parkingWaiting: #fff;
`;
export const test_palette = `
--primary-color: red;
--primary-color-light: pink;
--primary-color-lighter: salmon;
--primary-color-dark: #af0000;
--primary-color-darker: #860000;
--secondary-color: green;
--secondary-color-light: olive;
--background-color: pink;
--navbar-background: #fff;
--navbar-color-inverse: #333;
--navbar-color-inverse-light: #707070;
--navbar-color-inverse-lighter: #8f8f8f;
--navbar-link-hover: white;
--navbar-link-hover-background: pink;
--logo-background: #fff;
--logo-color-inverse: #333;
--icon-color-parkingElectric: #fff;
--icon-color-parkingWaiting: #fff;
`;
export const color_palette_sgs = `
--primary-color: #3C515B;
--primary-color-light: #3C515B;
--primary-color-lighter: #3C515B;
--primary-color-dark: #3C515B;
--primary-color-darker: #3C515B;
--secondary-color: #FF6600;
--secondary-color-light: #FF6600;
--background-color: #2d383b;
--navbar-background: #fff;
--navbar-color-inverse: #333;
--navbar-color-inverse-light: #707070;
--navbar-color-inverse-lighter: #8f8f8f;
--navbar-link-hover: #fff;
--navbar-link-hover-background: var(--primary-color-light);
--logo-background: var(--navbar-background);
--logo-color-inverse: var(--navbar-color-inverse);
--icon-color-parkingElectric: #fff;
--icon-color-parkingWaiting: #fff;
`;
export const color_palette_lleida = `
--primary-color: #171A19;
--primary-color-light: #171A19;
--primary-color-lighter: #171A19;
--primary-color-dark: #171A19;
--primary-color-darker: #171A19;
--secondary-color: #006338;
--secondary-color-light: #006338;
--background-color: #2d383b;
--navbar-background: #fff;
--navbar-color-inverse: #333;
--navbar-color-inverse-light: #707070;
--navbar-color-inverse-lighter: #8f8f8f;
--navbar-link-hover: #fff;
--navbar-link-hover-background: var(--primary-color-light);
--logo-background: var(--navbar-background);
--logo-color-inverse: var(--navbar-color-inverse);
--icon-color-parkingElectric: #fff;
--icon-color-parkingWaiting: #fff;
`;
