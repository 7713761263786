import {
  DEFAULT_AREA_DATA_API_PATH,
  DEFAULT_AREA_DINNER_DATA_API_PATH,
  DEFAULT_AREA_HOTDESK_DATA_API_PATH,
  DEFAULT_FLOOR_DATA_API_PATH,
  DEFAULT_HOLIDAY_DATA_API_PATH,
  DEFAULT_LANGUAGE,
  DEFAULT_PARKING_DATA_API_PATH,
} from 'src/app/core/constants/const';
import { Environment } from 'src/app/core/models/environment';

export const environment: Environment = {
  production: false,
  use_azure: false,
  clientId: null,
  downloadAvailable: true,
  donwloadUrls: {
    // androidVodafone:
    android:
      'https://s3-eu-west-1.amazonaws.com/sdos-vfplaza/vodafonesmartbuilding.apk',
    // iosVodafone:
    ios:
      'itms-services://?action=download-manifest&url=https://vodafonesmartbuilding.com/descargas/manifest.plist',
    androidTower:
      'https://vodafonesmartbuilding.com/descargas/tower/towersmartbuilding.apk',
    iosTower:
      'itms-services://?action=download-manifest&url=https://vodafonesmartbuilding.com/descargas/tower/manifest.plist',
  },
  showHeadquarterProfile: true,
  disabledProfileVehicle: true,
  notCheckAllowHeadquarter: true,
  searchInterval: true,
  hiddenIncidences: true,
  // hiddenInviteReservationRoom: true,
  disabledPersonalData: true,
  hiddenReservationOtherHeadquarters: true,
  hiddenParking: true,
  hiddenCountryAndProvince: true,
  dinnerDays: [1, 2, 3, 4],
  webModulesBlackList: ['0007'],
  usePinchZoom: true,
};
export let NAME = 'vodafone-plaza';
export const SERVICE_BASE_URL = 'https://smartbuildingpre-vodafone.sdos.es/ws';
export const COMPONENT_OAUTH_PATH = 'registerToken';
export const LOGIN_RETURN_URL = `${SERVICE_BASE_URL}/${COMPONENT_OAUTH_PATH}`;
export const CLIENT_ID = '6b6e84630bdf431d6bff613e3a5c48a9';
export const SERVICE_LOGIN_URL = `${SERVICE_BASE_URL}/web/access?return_url=${encodeURIComponent(
  LOGIN_RETURN_URL
)}&client_id=${CLIENT_ID}`;
export const PARKING_DATA_API_PATH = DEFAULT_PARKING_DATA_API_PATH;

export const AREA_DATA_API_PATH = DEFAULT_AREA_DATA_API_PATH;

export const FLOOR_DATA_API_PATH = DEFAULT_FLOOR_DATA_API_PATH;
export const AREA_DINNER_DATA_API_PATH = DEFAULT_AREA_DINNER_DATA_API_PATH;
export const AREA_HOTDESK_DATA_API_PATH = DEFAULT_AREA_HOTDESK_DATA_API_PATH;

export const HOLIDAY_DATA_API_PATH = DEFAULT_HOLIDAY_DATA_API_PATH;

export const LANGUAGE = DEFAULT_LANGUAGE;
