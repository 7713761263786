import { Component, OnInit } from '@angular/core';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';

@Component({
  selector: 'app-gym-resources',
  templateUrl: './gym-resources.component.html',
  styleUrls: ['./gym-resources.component.scss']
})
export class GymResourcesComponent implements OnInit {

  messagesPipe = new MessagesPipe();

  constructor(private navigatorRefreshService: NavigatorRefreshService) {}

  ngOnInit() {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_gym_resources')
    );
  }
}
