<div class="boxOffice">
    <div class="map">
        <div 
            *ngIf="!idList && url.includes('/selectMultiple-position/') || url.includes('/multi-BoxOffice/')"
            class="scroll" 
            id="scroll">
            <img id="mapImg" *ngIf="desks" [src]="desks?.url">
            <div [id]="id == item?.id ? id : ''"class="table" *ngFor="let item of desks?.hotdesks" (click)="multideskSelected($event,item)"
                [ngClass]="{ active : selectedDesks.includes(item?.id), disabled: item?.status == 1, free: item?.status == 2, partiallyOccupied: item?.status == 3, occupied: item?.status == 4}"
                [appAddTable]='item'>
                <span>{{item.code}}</span>
            </div>
        </div>
        <div 
            *ngIf="!idList && !url.includes('/selectMultiple-position/') && !url.includes('/multi-BoxOffice/')" 
            class="scroll" 
            id="scroll">
            <img id="mapImg" *ngIf="desks" [src]="desks?.url">
            <div [id]="id == item?.id ? id : ''"class="table" *ngFor="let item of desks?.hotdesks" (click)="openReservationDeskId(item)"
                [ngClass]="{ active : id == item?.id, disabled: item?.status == 1, free: item?.status == 2, partiallyOccupied: item?.status == 3, occupied: item?.status == 4}"
                [appAddTable]='item'>
                <span>{{item.code}}</span>
            </div>
        </div>
        <div
            *ngIf="idList?.length>0" class="scroll" id="scroll">
            <img id="mapImg" *ngIf="desks" [src]="desks?.url">
            <div [id]="[compareIdsItemForId(item) ? item?.id : '' ]" class="table" *ngFor="let item of desks?.hotdesks" (click)="openReservationDeskId(item)"
                [ngClass]="[compareIdsItem(item)]"
                [appAddTable]='item'>
                <span>{{item.code}}</span>
            </div>
        </div>
    </div>
    <div class="bottom">
        <span class="bottom__title" *ngIf="!onlyRead">{{box_title}}</span>
        <span class="bottom__title" *ngIf="onlyRead">{{box_title_legend}}</span>
        <div class="bottom__option">
            <label>{{box_free}}</label>
            <label>{{box_partially_occupied}}</label>
            <label>{{box_occupied}}</label>
            <label>{{box_disabled}}</label>
            <label *ngIf="readerDesk">{{box_yourDesk}}</label>
            <label *ngIf="viewSelect">{{box_userSelectDesk}}</label>
            <label *ngIf="viewMulti">{{box_position_available}}</label>
        </div>
        <div 
            *ngIf="!usePinchZoom"
            class="zoom">
            <button class="zoom--plus" (click)="zoomIn()">+</button>
            <button class="zoom--less" (click)="zoomOut()">-</button>
        </div>
    </div>
    <div class="placed-button" *ngIf="url.includes('/selectMultiple-position/') || url.includes('/multi-BoxOffice/')">
        <button
          type="submit"
          class="check-availability button-by"
          [disabled]="userList.length < 1 || selectedDesks.length !== userList.length"
            (click)="openConfirmationMultiDesk()"
        >
          <i class="vdp-save"></i
          >Reservar puestos
        </button>
      </div>
</div>
<app-modal-reservation (addReservedOk)=changeReserve()></app-modal-reservation>
<app-modal-reservation-confirmation></app-modal-reservation-confirmation>
<app-modal-error-mobile></app-modal-error-mobile>
<app-spinner></app-spinner>
<side-modal></side-modal>