import { Component, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { Department as Department } from 'src/app/core/models/department';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { DepartmentService } from 'src/app/shared/services/department.service';
import { environment } from '../../../environments/environment';
import {
  LOCAL_CURRENT_USER,
  STATUS_CONFLICT,
  STATUS_FORBIDDEN
} from '../../core/constants/const';
import { LOGOUT_TIMEOUT } from '../../core/constants/consts';
import { Building } from '../../core/models/building';
import { BuildingWs } from '../../core/models/building.ws';
import { CountryModel } from '../../core/models/country.ws';
import { Headquarters } from '../../core/models/headquarters';
import { HeadquartersListModel } from '../../core/models/headquarters.list.ws';
import { HeadquartersWS } from '../../core/models/headquarters.ws';
import { ProvinceModel } from '../../core/models/province.ws';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { BaseService } from '../../shared/services/base.service';
import { BuildingService } from '../../shared/services/building.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { HeadquartersService } from '../../shared/services/headquarters.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';
import { UserService } from '../../shared/services/user.service';
import { SideModalService } from '../side-modal/side-modal.service';
import { ProfileHeadquarterValidator } from './headquarters.validator';
import LoginData from '../../core/models/interfaces/LoginData';
declare var $: any;

@Component({
  templateUrl: 'profile.component.html',
  providers: [ProfileHeadquarterValidator],
  styleUrls: ['profile.scss'],
})
export class ProfileComponent implements OnInit, OnChanges {
  user: UserLdapWs;
  messagesPipe = new MessagesPipe();
  profileForm: FormGroup;
  imageUrl: string;
  typeFormOpen: string;

  loadingCountry = false;
  loadingProvince = false;
  loadingHeadquarter = false;
  loadingBuilding = false;
  loadingDepartaments = false;

  buildings: BuildingWs[];
  headquarters: HeadquartersListModel[];
  listCountry: CountryModel[];
  listProvince: ProvinceModel[];
  departments: Department[];

  headquarterValid = true;
  headquartersSelectedHtml: HeadquartersWS;
  finalHeadquarterList: HeadquartersWS[];
  userWithoutHeadquarters: boolean;

  permissions: MenuPermissions;

  changeHeadquarter = false;

  showSelectTurn: boolean;
  availableShifts: any;

  fileName: any;

  showModalLanguage = false;

  loginData: LoginData;

  constructor(
    private authenticationService: AuthenticationService,
    private buildingService: BuildingService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessagesService: FlashMessagesService,
    private baseService: BaseService,
    private headquartersService: HeadquartersService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private navigatorRefreshService: NavigatorRefreshService,
    private departmentService: DepartmentService,
    private azureService: MsalService,
    private sideModalService: SideModalService
  ) {
    this.userWithoutHeadquarters = false;
  }

  ngOnInit() {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.user = this.authenticationService.getCurrentUser();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.closeSideModal();
    this.createForm();
    if (!this.hiddenCountryAndProvince()) {
      this.getHeadquarterListCountry(1);
    }
    this.getAvailableShifts();
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_profile')
    );
    this.showSelectTurn = this.user.dinnerShiftRequest;
  }

  openModalForm(type: string) {
    this.typeFormOpen = type;
    let titleForm = '';
    if (type === 'updatePassword') {
      titleForm = this.messagesPipe.transform('profile_form_pass_mod');
    } else if (type === 'modifyVehicle') {
      titleForm = this.messagesPipe.transform('profile_form_vehicle');
    } else if (type === 'supportAssistance') {
      titleForm = this.messagesPipe.transform('profile_form_assistance_config');
    }

    if (type === 'supportAssistance') {
      const localUserCurrent = JSON.parse(
        localStorage.getItem(LOCAL_CURRENT_USER)
      );
      this.user.dinnerShiftRequest = localUserCurrent.dinnerShiftRequest;
      this.user.dinnerShiftFrom = localUserCurrent.dinnerShiftFrom;
      this.user.dinnerShiftTo = localUserCurrent.dinnerShiftTo;
      this.user.parkingRequest = localUserCurrent.parkingRequest;
      this.sideModalService.openSideModal(
        'form',
        { type: type, user: this.user },
        titleForm,
        this.route,
        true
      );
    } else {
      this.sideModalService.openSideModal(
        'form',
        { type: type },
        titleForm,
        this.route,
        true
      );
    }
  }

  openModalLanguage() {
    this.sideModalService.openSideModal(
      'language',
      { user: this.user },
      this.messagesPipe.transform('support_language_btn'),
      this.route,
      true
    );
  }

  closeSideModal() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (
        res &&
        res.close &&
        res.nameComponent == 'form' &&
        this.typeFormOpen === 'supportAssitance'
      ) {
        this.getUserEditPermission();
      }
      this.typeFormOpen = null;
    });
  }

  createForm() {
    let countryId = 0;
    let provinceId = 0;
    let headquartersId = 0;
    let buildingId = 0;
    let departmentId = 0;
    let showHotdeskReservation: boolean;
    let shiftId: string;

    if (
      this.user &&
      this.user.parents &&
      this.user.parents.find((x) => x.level == 1) &&
      this.user.parents.find((x) => x.level == 1).id &&
      !this.hiddenCountryAndProvince()
    ) {
      countryId = this.user.parents.find((x) => x.level == 1).id;
      this.getHeadquarterListProvince(countryId);
    }

    if (
      this.user &&
      this.user.parents &&
      this.user.parents.find((x) => x.level == 2) &&
      this.user.parents.find((x) => x.level == 2).id &&
      !this.hiddenCountryAndProvince()
    ) {
      provinceId = this.user.parents.find((x) => x.level == 2).id;
      this.getListHeadquarter(provinceId);
    } else if (this.hiddenCountryAndProvince()) {
      this.getFinalHeadquarterList();
    }

    if (this.user.building != null) {
      buildingId = this.user.building.id;
    }

    if (this.user.department != null) {
      departmentId = this.user.department.id;
    }

    if (this.user.headquarters != null) {
      headquartersId = this.user.headquarters.id;
    } else {
      headquartersId = 0;
      buildingId = 0;
      departmentId = 0;
      this.userWithoutHeadquarters = true;
    }

    if (this.user.dinnerShiftFrom != null && this.user.dinnerShiftTo != null) {
      shiftId =
        moment(this.user.dinnerShiftFrom).format('HHmm') +
        '' +
        moment(this.user.dinnerShiftTo).format('HHmm');
    }

    if (this.user.showHotdeskReservation != null) {
      showHotdeskReservation = this.user.showHotdeskReservation;
    } else {
      showHotdeskReservation = false;
    }

    this.imageUrl = this.user.image;
    this.profileForm = this.formBuilder.group({
      name: [this.user.name],
      email: [
        this.user.email,
        [
          Validators.pattern(
            "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*$"
          ),
        ],
      ],
      image: [''],
      building: [buildingId],
      department: [departmentId],
      country: [
        {
          value: countryId,
          disabled: this.notCheckAllowHeadquarter()
            ? false
            : !this.permissions.allowHeadquarters,
        },
      ],
      province: [
        {
          value: provinceId,
          disabled: this.notCheckAllowHeadquarter()
            ? false
            : !this.permissions.allowHeadquarters,
        },
      ],
      headquarters: [
        {
          value: headquartersId,
          disabled: this.notCheckAllowHeadquarter()
            ? false
            : !this.permissions.allowHeadquarters,
        },
        [
          Validators.required,
          Validators.min(1),
          ProfileHeadquarterValidator.validationHeadquarters,
        ],
      ],
      myReservations: [showHotdeskReservation],
      solictParking: '',
      solictDinner: '',
      availableShifts: [shiftId],
    });

    if (!this.permissions.allowEditProfile) {
      this.profileForm.controls.headquarters.disable();
      this.profileForm.controls['country'].disable();
      this.profileForm.controls['province'].disable();
      this.profileForm.controls['building'].disable();
      this.profileForm.controls['department'].disable();
      this.profileForm.controls['myReservations'].disable();
    }

    if (!this.showHeadquarter()) {
      this.profileForm.controls.headquarters.disable();
      this.profileForm.controls['country'].disable();
      this.profileForm.controls['province'].disable();
    }
  }

  ngOnChanges() {
    if (!this.permissions.allowEditProfile) {
      this.profileForm.controls.headquarters.disable();
      this.profileForm.controls['building'].disable();
      this.profileForm.controls['country'].disable();
      this.profileForm.controls['province'].disable();
    } else {
      this.profileForm.controls.headquarters.enable();
      this.profileForm.controls['building'].enable();
      this.profileForm.controls['country'].enable();
      this.profileForm.controls['province'].enable();
    }
  }

  updateProfile() {
    let building;
    let department;
    let user = new UserLdapWs();
    let mediaType = 'base64,';
    let headquartersSelectedAux;

    user.name = this.profileForm.controls['name'].value;
    user.email = this.profileForm.controls['email'].value;
    user.image = this.profileForm.controls['image'].value;
    user.showHotdeskReservation =
      this.profileForm.controls['myReservations'].value;
    building = new Building(this.profileForm.controls['building'].value, null);
    department = new Department(
      this.profileForm.controls['department'].value,
      null,
      null,
      null
    );

    if(this.hiddenCountryAndProvince()){
      headquartersSelectedAux = this.finalHeadquarterList.filter(
        (headquarter) =>
          headquarter.id == this.profileForm.controls['headquarters'].value
      );
    } else {
      headquartersSelectedAux = this.headquarters.filter(
        (headquarter) =>
          headquarter.id == this.profileForm.controls['headquarters'].value
      );
    }
    
    if (headquartersSelectedAux[0]) {
      user.headquarters = new Headquarters(
        headquartersSelectedAux[0].id,
        headquartersSelectedAux[0].name,
        headquartersSelectedAux[0].allowParking,
        headquartersSelectedAux[0].allowReservation
      );
    }

    if (user.image && user.image.length > 0) {
      user.image = user.image.substring(
        user.image.indexOf(mediaType) + mediaType.length,
        user.image.length
      );
    }

    if (
      user.headquarters &&
      user.headquarters == null &&
      user.headquarters.id <= 0
    ) {
      user.headquarters = null;
    }

    if (building != null && building.id > 0) {
      user.building = this.buildingService.findById(
        this.buildings,
        this.profileForm.controls['building'].value
      );
    } else {
      user.building = null;
    }

    if (department != null && department.id > 0 && this.departments) {
      user.department = this.departmentService.findById(
        this.departments,
        parseInt(this.profileForm.controls['department'].value, 10)
      );
    } else {
      user.department = null;
    }

    this.userService
      .updateProfile(this.authenticationService.getToken(), user)
      .subscribe(
        (response) => {
          if (response.image != null && response.image.length > 0) {
            user.image = response.image;
          } else if (this.user.image != null && this.user.image.length > 0) {
            user.image = this.user.image;
          }

          this.user.building = user.building;
          this.user.headquarters = user.headquarters;
          this.user.department = user.department;
          this.user.showHotdeskReservation = user.showHotdeskReservation;

          this.getUserEditPermission();

          this.navigatorRefreshService.emitMenuRefreshProfileEvent(
            this.profileForm.controls['image'].value
          );

          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('updateProfile_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          if (this.changeHeadquarter) {
            this.logout();
          }
        },
        (error) => {
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.updateProfile();
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.updateProfile();
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else if (error.code == STATUS_CONFLICT) {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              '',
              this.messagesPipe.transform('indicate_your_dept_desc')
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('updateProfile_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  fileChageEvent(event) {
    this.fileName = null;
    if (event && event.target && event.target.files[0]) {
      const file = event.target.files[0];
      this.fileName = file.name;
      if (file.type.includes('image/')) {
        const sizeKiloByte = file.size / 1024;
        if (sizeKiloByte < 5000) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.profileForm.get('image').patchValue(reader.result.toString());
          };
        } else {
          let fileImage = document.getElementById(
            'imageFile'
          ) as HTMLInputElement;
          fileImage.value = null;
          this.profileForm.get('image').patchValue(null);
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_error_image'),
            {
              cssClass: 'alert-danger',
              timeout: 3000,
            }
          );
        }
      } else {
        let fileImage = document.getElementById(
          'imageFile'
        ) as HTMLInputElement;
        fileImage.value = null;
        this.profileForm.get('image').patchValue(null);
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform('incidence_file_type'),
          { cssClass: 'alert-error', timeout: 3000 }
        );
      }
    } else {
      this.profileForm.get('image').patchValue(null);
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform('incidence_file_type'),
        { cssClass: 'alert-error', timeout: 3000 }
      );
    }
  }

  validationHeadquarters(control: FormControl) {
    const input = control.value;
    if (input != undefined && input != null && input > 0) {
      return null;
    } else {
      return { validationHeadquarters: true };
    }
  }

  getUserEditPermission() {
    this.userService
      .getUserPermissions(this.authenticationService.getToken())
      .subscribe(
        (response) => {
          if (response.authorizations != null) {
            let user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
            user.authorizations = response.authorizations;
            user.dinnerShiftRequest = response.dinnerShiftRequest;
            user.dinnerShiftFrom = response.dinnerShiftFrom;
            user.dinnerShiftTo = response.dinnerShiftTo;
            user.parkingRequest = response.parkingRequest;
            localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
            this.permissions =
              this.authenticationService.getPermissionsForCurrentUser();
            this.authenticationService.updateUser(response);
          }
        },
        (error) => {
          console.error();
        }
      );
  }

  // PAIS
  getHeadquarterListCountry(level: number) {
    this.loadingCountry = true;
    this.headquartersService
      .getHeadquarterListCountry(this.authenticationService.getToken(), level)
      .subscribe(
        (res) => {
          this.loadingCountry = false;
          if (res) {
            this.listCountry = res;
          } else {
            this.listCountry = [];
          }
        },
        (error) => {
          this.loadingCountry = false;
          this.listCountry = [];
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getHeadquarterListCountry(level);
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getHeadquarterListCountry(level);
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getCountry_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  changeCountry(countryId: number) {
    if (countryId) {
      this.listProvince = [];
      this.headquarters = [];
      this.buildings = [];
      this.departments = [];
      if (countryId != 0) {
        this.getHeadquarterListProvince(countryId);
      }
      this.profileForm.controls['province'].patchValue(0);
      this.profileForm.controls['headquarters'].patchValue(0);
      this.profileForm.controls['building'].patchValue(0);
      this.profileForm.controls['department'].patchValue(0);
    }
  }

  // PROVINCIAS
  getHeadquarterListProvince(parentId: number) {
    this.loadingProvince = true;
    this.headquartersService
      .getHeadquarterListProvince(
        this.authenticationService.getToken(),
        parentId
      )
      .subscribe(
        (res) => {
          this.loadingProvince = false;
          if (res) {
            this.listProvince = res;
          } else {
            this.listProvince = [];
          }
        },
        (error) => {
          this.loadingProvince = false;
          this.listProvince = [];
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getHeadquarterListProvince(parentId);
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getHeadquarterListProvince(parentId);
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getProvince_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  changeProvince(provinceId: number) {
    if (provinceId) {
      if (provinceId != 0) {
        this.getListHeadquarter(provinceId);
      }
      this.headquarters = [];
      this.buildings = [];
      this.departments = [];
      this.profileForm.controls['headquarters'].patchValue(0);
      this.profileForm.controls['building'].patchValue(0);
      this.profileForm.controls['department'].patchValue(0);
    }
  }

  //SEDES
  getFinalHeadquarterList() {
    this.loadingHeadquarter = true;
    this.headquartersService
      .getFinalHeadquarterList(this.authenticationService.getToken())
      .subscribe(
        (headquarters) => {
          this.loadingHeadquarter = false;
          this.finalHeadquarterList = headquarters;
          if (this.profileForm.controls.headquarters.value) {
            this.getBuildingListByHeadquarter(
              this.profileForm.controls.headquarters.value
            );
            this.getDepartmentListByHeadquarter(
              this.profileForm.controls.headquarters.value
            );
          }
        },
        (error) => {
          this.loadingHeadquarter = false;
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getFinalHeadquarterList();
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getFinalHeadquarterList();
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getHeadquarters_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  //SEDES by Level
  getListHeadquarter(parentId?: number) {
    this.loadingHeadquarter = true;
    this.headquartersService
      .getListHeadquarter(this.authenticationService.getToken(), parentId)
      .subscribe(
        (headquarters) => {
          this.loadingHeadquarter = false;
          this.headquarters = headquarters;
          if (this.profileForm.controls.headquarters.value) {
            this.getBuildingListByHeadquarter(
              this.profileForm.controls.headquarters.value
            );
            this.getDepartmentListByHeadquarter(
              this.profileForm.controls.headquarters.value
            );
          }
        },
        (error) => {
          this.loadingHeadquarter = false;
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getListHeadquarter(parentId);
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getListHeadquarter(parentId);
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getHeadquarters_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  headquartersChanged(headquartersId: number) {
    if (headquartersId && headquartersId > 0) {
      this.departments = [];
      this.buildings = [];
      this.getBuildingListByHeadquarter(headquartersId);
      this.getDepartmentListByHeadquarter(headquartersId);
      this.headquarterValid = true;
    } else {
      this.profileForm.controls['headquarters'].patchValue(0);
      this.buildings = [];
      this.departments = [];
      this.headquarterValid = false;
    }
  }

  //EDIFICIOS
  getBuildingListByHeadquarter(headquartersId: number) {
    this.loadingBuilding = true;
    this.buildingService
      .getBuildingListByHeadquarters(
        this.authenticationService.getToken(),
        headquartersId
      )
      .subscribe(
        (buildings) => {
          this.loadingBuilding = false;
          this.buildings = buildings;
        },
        (error) => {
          this.loadingBuilding = false;
          if (error.code == STATUS_FORBIDDEN) {
            this.authenticationService.refreshToken().subscribe(
              (response) => {
                this.getBuildingListByHeadquarter(headquartersId);
              },
              (error) => {
                this.authenticationService.validateSessionId().subscribe(
                  (response) => {
                    this.getBuildingListByHeadquarter(headquartersId);
                  },
                  (error) => {
                    this.flashMessagesService.grayOut(false);
                    this.flashMessagesService.show(
                      this.messagesPipe.transform('error_forbidden'),
                      { cssClass: 'alert-danger', timeout: 3000 }
                    );

                    setTimeout(() => {
                      this.authenticationService.logout();
                    }, LOGOUT_TIMEOUT);
                  }
                );
              }
            );
          } else {
            this.baseService.showErrorDialog(
              this.flashMessagesService,
              error,
              this.messagesPipe.transform('getBuildings_error')
            );
            this.router.navigate(['/menu/home']);
          }
        }
      );
  }

  //DEPARTAMENTO

  getDepartmentListByHeadquarter(headquartersId: number) {
    this.loadingDepartaments = true;
    this.departmentService
      .getDepartmentListByHeadquarterId(
        this.authenticationService.getToken(),
        headquartersId
      )
      .subscribe(
        (data) => {
          this.loadingDepartaments = false;
          this.departments = data;
        },
        (error) => {
          this.loadingDepartaments = false;
          this.flashMessagesService.show(
            this.messagesPipe.transform('profile_departments_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  showHeadquarter(): boolean {
    return environment['showHeadquarterProfile'];
  }

  disabledProfileVehicle(): boolean {
    return environment['disabledProfilePassileVehicle'];
  }

  disabledProfilePass(): boolean {
    return environment['disabledProfilePass'];
  }

  notCheckAllowHeadquarter(): boolean {
    return environment['notCheckAllowHeadquarter'];
  }

  disabledPersonalData(): boolean {
    return environment['disabledPersonalData'];
  }

  hiddenCountryAndProvince(): boolean {
    return environment['hiddenCountryAndProvince'];
  }
  
  hiddenProfilePicture(): boolean {
    return environment['hiddenProfilePicture'];
  }

  hiddenProfileDepartment(): boolean {
    return environment['hiddenProfileDepartment'];
  }

  hiddenProfileBuilding(): boolean {
    return environment['hiddenProfileBuilding'];
  }

  logout() {
    this.authenticationService.logout();

    if (this.loginData.loginType === 'msal') {
      this.azureService.logout();
    }
  }

  getAvailableShifts() {
    const date = moment(new Date()).format('YYYY-MM-DD');
    this.userService
      .getDinnerShifts(this.authenticationService.getToken(), date)
      .subscribe((res) => {
        if (res) {
          this.availableShifts = res;
          let turn: any;
          for (let i = 0; i < this.availableShifts.length; i++) {
            const dateFrom = moment(this.availableShifts[i].shiftFrom).format(
              'HH:mm'
            );
            const dateTo = moment(this.availableShifts[i].shiftTo).format(
              'HH:mm'
            );
            turn = {
              id:
                moment(this.availableShifts[i].shiftFrom).format('HHmm') +
                '' +
                moment(this.availableShifts[i].shiftTo).format('HHmm'),
              shiftFrom: this.availableShifts[i].shiftFrom,
              shiftTo: this.availableShifts[i].shiftTo,
              shiftFromAux: dateFrom,
              shiftToAux: dateTo,
            };
            this.availableShifts[i] = turn;
          }
        }
      });
  }
}
