import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { gymConfiguaration } from 'src/app/core/models/gym.class';
import { SERVICE_BASE_URL } from '../../../environments/vp/environment.pre';
import { OPTIONS } from '../../core/constants/const';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { resourceForHourReservationAddRequest, resourceForHourReservationCancelRequest } from 'src/app/core/models/gym.class';
import { HttpErrorResponse } from '@angular/common/http';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';


@Injectable({
  providedIn: 'root'
})
export class GymService {

  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService,
  ) { }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }
  messagesPipe = new MessagesPipe();

  gymConfiguaration() {
    return this.http.
      get(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/gymConfiguration?access_token=${this.authenticationService.getToken()}`,
        OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  gymOccupationForHour(codeResource: Number, day: any) {
    return this.http.
      get(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/occupationForHour?codeResource=${codeResource}&day=${day}&access_token=${this.authenticationService.getToken()}`,
        OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  ReservationsAdd(resourceForHourReservationAddRequest: resourceForHourReservationAddRequest) {
    return this.http.
      post(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/reservations/add?access_token=${this.authenticationService.getToken()}`,
        OPTIONS,
        { body: resourceForHourReservationAddRequest })
      .map((response: any) => {
        switch (response.status) {
          case response.status = 401:
            this.flashMessagesService.show(
              this.messagesPipe.transform('unauthorized_gym_resources'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            break;
          case response.status = 400:
            this.flashMessagesService.show(
              this.messagesPipe.transform('bad_request_gym_resources'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            break;
          case response.status = 204:
            this.flashMessagesService.show(
              this.messagesPipe.transform('reserved_gym_resources'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            break;
          case response.status = 404:
            this.flashMessagesService.show(
              this.messagesPipe.transform('not_found_gym_resources'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            break;
          case response.status = 201:
            this.flashMessagesService.show(
              this.messagesPipe.transform('not_reserved_gym_resources'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            break;
          default:
            break;
        }
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  historic(codeResource: Number) {
    return this.http.
      get(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/historic?codeResource=${codeResource}&access_token=${this.authenticationService.getToken()}`,
        OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  ReservationsMe(codeResource: Number) {
    return this.http.
      get(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/reservations/me?codeResource=${codeResource}&access_token=${this.authenticationService.getToken()}`,
        OPTIONS)
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  CancelReservation(resourceForHourReservationId: number) {
    let bodyString = {
      resourceForHourReservationId,
    };
    return this.http.
      post(
        `${SERVICE_BASE_URL}/api/resourceForHourReservation/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS,
      )
      .map((response: any) => {
        return response.json();
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}


