import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { SERVICE_BASE_URL } from '../../../environments/environment';
import { OPTIONS } from '../../core/constants/const';
import { ParkingSizeModel } from '../../core/models/parking.size';
import { ReservationCarwashWs } from '../../core/models/reservation.carwash.ws';
import { ReservationDetailCarWashWs } from '../../core/models/reservation.detail.carwash.ws';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CarWashService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getCarWashHistoric(): Observable<ReservationCarwashWs[]> {
    let bodyString = {};

    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/carwashReservation/historic?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json().content as ReservationCarwashWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getCarWashReservationMe(): Observable<ReservationCarwashWs[]> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/carwashReservation/reservations/me?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json().content as ReservationCarwashWs[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getCarWashReservation(
    date: number,
    carwashId: number,
    token?: string
  ): Observable<ReservationDetailCarWashWs> {
    let bodyString = {
      date,
      carwashId,
    };

    let tokenSend = null;
    if (token) {
      tokenSend = token;
    } else {
      tokenSend = this.authenticationService.getToken();
    }

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/carwashReservation/reservations?access_token=${tokenSend}`,
        bodyString,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as ReservationDetailCarWashWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  cancelCarwashReservation(carwashReservationId: number): Observable<any> {
    let bodyString = {
      carwashReservationId,
    };
    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/carwashReservation/cancel?access_token=${this.authenticationService.getToken()}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        let resJson = response.json();
        let reservations = resJson as any;

        return reservations;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  addCarwashReservation(
    dateFrom: number,
    dateTo: number,
    carwashSizeId: number,
    license: string,
    flota?:boolean,
    ceco?:string,
    token?: string
  ): Observable<ReservationCarwashWs> {
    let bodyString: any = {
      dateFrom,
      dateTo,
      carwashSizeId,
      license,
      ceco,
      flota
    };
    let tokenSend = null;
    if (token) {
      tokenSend = token;
    } else {
      tokenSend = this.authenticationService.getToken();
    }

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/carwashReservation/reservations/add?access_token=${tokenSend}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        return response.json() as ReservationCarwashWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  editCarwashReservation(
    dateFrom: number,
    dateTo: number,
    id: number,
    flota?:boolean,
    ceco?:string,
    token?: string
  ): Observable<ReservationCarwashWs> {
    let bodyString: any = {
      dateFrom,
      dateTo,
      flota,
      ceco
    };
    let tokenSend = null;
    if (token) {
      tokenSend = token;
    } else {
      tokenSend = this.authenticationService.getToken();
    }

    return this.http
      .put(
        `${SERVICE_BASE_URL}/api/carwashReservation/reservations/edit/${id}?access_token=${tokenSend}`,
        bodyString,
        OPTIONS
      )
      .map((response: any) => {
        return response.json() as ReservationCarwashWs;
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getCarWashParkingSizeListAll(): Observable<ParkingSizeModel[]> {
    return this.http
      .get(
        `${SERVICE_BASE_URL}/api/parkingSize/listAll?access_token=${this.authenticationService.getToken()}`,
        OPTIONS
      )
      .map((response: any) => {
        return response.json() as ParkingSizeModel[];
      })
      .catch((error) =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
