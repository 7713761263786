import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../../../environments/environment';
import {
  TYPE_CODE_VEHICLE,
  TYPE_CODE_VEHICLE_RESERVE,
} from '../../../../../core/constants/consts';
import { SideModalService } from '../../../../../views/side-modal/side-modal.service';
import { MessagesPipe } from '../../../../pipes/messages.pipe';
import { AttendanceService } from '../../../../services/attendance.service';
import { BaseService } from '../../../../services/base.service';
import { FlashMessagesService } from '../../../../services/flash-messages.service';
import { ModalAcceptDialogService } from '../../../modal-accept-dialog/modal-accept-dialog.service';
import { CalendarEditConfirmService } from './calendar-edit-confirm.service';
declare var $: any;

@Component({
  selector: 'app-calendar-edit-confirm',
  templateUrl: './calendar-edit-confirm.component.html',
  styleUrls: ['./calendar-edit-confirm.component.scss'],
})
export class CalendarEditConfirmComponent implements OnInit {
  assistanceSelected: any;
  assistanceInfo: any;
  shiftPriority: any;
  mainVehicle: any; // Tambien se puede utilizar getMainVehicule()
  typeVehicle: any;
  showParkingSpotInfo: any;
  attendanceIntegrationParking: any;
  attendanceIntegrationDinnerDesk: any;
  userSelect: any;

  messagesPipe = new MessagesPipe();
  assistanceForm: FormGroup;
  turnOK = false;

  buttonDisabledRequestParking = false;
  buttonDisabledRequestDinner = false;

  enableSelectDinner: boolean = false;
  enableButtonCancelDinner: boolean = false;

  enableSelectParking: boolean = false;
  enableModuleParking: boolean = false;
  isWaitingParking: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private flashMessagesService: FlashMessagesService,
    private attendanceService: AttendanceService,
    private baseService: BaseService,
    private router: Router,
    private calendarEditConfirmService: CalendarEditConfirmService,
    private modalAcceptDialogService: ModalAcceptDialogService,
    private sideModalService: SideModalService
  ) {
    this.calendarEditConfirmService.cancelAssistance =
      this.cancelAssistance.bind(this);
    this.calendarEditConfirmService.cancelAssistancePlace =
      this.liberateParkig.bind(this);
    this.calendarEditConfirmService.cancelAssistanceTurn =
      this.cancelTurn.bind(this);

    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      let {
        assistanceSelected,
        assistanceInfo,
        shiftPriority,
        mainVehicle,
        typeVehicle,
        showParkingSpotInfo,
        attendanceIntegrationParking,
        attendanceIntegrationDinnerDesk,
        userSelect,
      } = this.router.getCurrentNavigation().extras.state;
      this.assistanceSelected = assistanceSelected;
      this.assistanceInfo = assistanceInfo;
      this.shiftPriority = shiftPriority;
      this.mainVehicle = mainVehicle;
      this.typeVehicle = typeVehicle;
      this.showParkingSpotInfo = showParkingSpotInfo;
      this.attendanceIntegrationParking = attendanceIntegrationParking;
      this.attendanceIntegrationDinnerDesk = attendanceIntegrationDinnerDesk;
      this.userSelect = userSelect;
    }
  }

  ngOnInit() {
    this.assistanceForm = this.formBuilder.group({
      turn: [this.shiftPriority ? this.shiftPriority : '0'],
      placeParking: [
        this.getMainVehicule()?.typeId ? this.getMainVehicule()?.typeId : '0',
      ],
    });

    if (
      this.assistanceForm &&
      parseInt(this.assistanceForm.controls['turn'].value, 10) > 0
    ) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }

    this.getMessageDinner();
    this.getMessageAndSelectParking();
  }

  // CALL API

  cancelAssistance() {
    this.attendanceService
      .cancelAssistance(this.assistanceSelected.assignments.id, this.userSelect)
      .subscribe(
        (res) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_cancel_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_ko')
          );
          this.closeForm();
        }
      );
  }

  cancelTurn() {
    this.attendanceService
      .cancelDinnerDesk(this.assistanceInfo.dinner.id, this.userSelect)
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform('assistance_text_cancel_dinner_ok'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_cancel_dinner_ok')
          );
          this.closeForm();
        }
      );
  }

  solictTurn() {
    this.buttonDisabledRequestDinner = true;
    const optionSelected = this.assistanceForm.controls['turn'].value;
    const assistanceSelect = this.assistanceInfo.dinner.availableTurns.filter(
      (element) => {
        return parseInt(element.id, 10) === parseInt(optionSelected, 10);
      }
    );
    const request = {
      day: this.assistanceInfo.attendance.day,
      availableTurns: {
        shiftFrom:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftFrom
            : '',
        shiftTo:
          assistanceSelect && assistanceSelect.length > 0
            ? assistanceSelect[0].shiftTo
            : '',
      },
      userId: this.userSelect,
    };
    this.attendanceService.requestDinnerDesk(request).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_dinner_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_dinner_ko')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledRequestDinner = false;
      }
    );
  }

  liberateParkig() {
    this.attendanceService
      .liberateParking(
        this.assistanceSelected.assignments.parking.assignmentId,
        this.userSelect
      )
      .subscribe(
        (res) => {
          if (res) {
            this.flashMessagesService.show(
              this.messagesPipe.transform(
                'assistance_text_liberate_parking_ok'
              ),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.closeForm();
            this.router.navigate(['menu', 'assistance']);
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('assistance_text_liberate_parking_ko')
          );
          this.closeForm();
        }
      );
  }

  solictParking() {
    this.buttonDisabledRequestParking = true;
    const optionSelected = $(
      'select[name="placeParking"] option:selected'
    ).val();
    const solict = {
      day: moment(this.assistanceInfo.attendance.day, 'DD/MM/YYYY').format(
        'DD/MM/YYYY'
      ),
      parking: {
        assignmentId: null,
        hasParking: false,
        parkingTypeId: optionSelected
          ? parseInt(optionSelected, 10)
          : this.getMainVehicule()?.typeId,
      },
      userId: this.userSelect,
    };
    this.attendanceService.solictParking(solict).subscribe(
      (res) => {
        if (res) {
          this.flashMessagesService.show(
            this.messagesPipe.transform('assistance_text_solict_parking_ok'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
          this.closeForm();
          this.router.navigate(['menu', 'assistance']);
        }
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform('assistance_text_solict_parking_ko')
        );
        this.closeForm();
      },
      () => {
        this.buttonDisabledRequestParking = false;
      }
    );
  }

  cancelWaitingList(day: string) {
    this.attendanceService.cancelWaitingList(day, this.userSelect).subscribe(
      (res) => {
        this.flashMessagesService.show(
          this.messagesPipe.transform(
            'assistance_text_liberate_waiting_list_ok'
          ),
          { cssClass: 'alert-success', timeout: 3000 }
        );
        this.closeForm();
      },
      (error) => {
        this.baseService.showErrorDialog(
          this.flashMessagesService,
          error,
          this.messagesPipe.transform(
            'assistance_text_liberate_waiting_list_ko'
          )
        );
        this.closeForm();
      }
    );
  }

  // ACTION BUTTON

  liberateOrSolictParking(value: any) {
    if (value) {
      const assist = {
        parking: {
          hasParking: true,
        },
      };
      this.modalAcceptDialogService.openModal(assist);
    } else {
      this.solictParking();
    }
  }

  confirmCancelAsistTurn() {
    const assist = {
      dinner: {
        hasReservation: true,
      },
    };
    this.modalAcceptDialogService.openModal(assist);
  }

  confirmCancelAssistance() {
    this.modalAcceptDialogService.openModal(this.assistanceInfo);
  }

  //CHANGE AND SELECT

  changeTurn(value: any) {
    if (parseInt(value, 10) > 0) {
      this.turnOK = true;
    } else {
      this.turnOK = false;
    }
  }

  // MESSAGES AND ICON

  getMessageAndSelectParking() {
    switch (true) {
      case this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.hasParking &&
        this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.size &&
        this.typeVehicle.length > 1:
        this.enableSelectParking = false;
        this.isWaitingParking = false;
        return this.messagesPipe.transform('assistance_text_assign_parking', [
          this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.size,
        ]);
        break;

      case this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.hasParking &&
        this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.size &&
        this.typeVehicle.length == 1:
        this.enableSelectParking = false;
        this.isWaitingParking = false;
        return this.messagesPipe.transform(
          'assistance_text_assign_parking_space'
        );

      case !this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.parkingWaitingListEnabled &&
        this.assistanceInfo?.parking?.userInParkingWaitingList:
        this.enableSelectParking = false;
        this.isWaitingParking = true;
        return this.messagesPipe.transform('assistance_text_waiting_list');

      case this.parkingSizeAttendanceEnable(this.assistanceInfo) &&
        !this.assistanceInfo?.parking?.assignmentId:
        this.isWaitingParking = false;
        this.enableSelectParking = true;
        if (this.assistanceInfo?.parking?.hasRequestParking) {
          if (this.getMainVehicule()?.enabled) {
            return this.messagesPipe.transform(
              'assistance_text_not_assign_main_active'
            ); //Devuelven ""
            // "No tienes plaza de parking asignada, preferido SI esta habailitado"
          } else {
            return this.messagesPipe.transform(
              'assistance_text_not_assign_main_not_active'
            ); //Devuelven ""
            // "No tienes plaza de parking asignada, principal NO esta habailitado"
          }
        } else {
          // No tienes plaza de parking asignada
          return this.messagesPipe.transform(
            'assistance_text_not_assign_parking'
          ); //Devuelven ""
        }

      case !this.parkingSizeAttendanceEnable(this.assistanceInfo) &&
        !this.assistanceInfo?.parking?.assignmentId &&
        this.assistanceInfo?.parking?.parkingWaitingListEnabled &&
        !this.assistanceInfo?.parking?.userInParkingWaitingList:
        this.enableSelectParking = true;
        this.isWaitingParking = false;
        return this.messagesPipe.transform(
          'assistance_text_parking_not_space_available_waiting'
        );

      default:
        this.isWaitingParking = false;
        this.enableSelectParking = false;
        return this.messagesPipe.transform(
          'assistance_text_not_space_available'
        );
        break;
    }
  }

  getMessageAdditionalParking() {
    switch (true) {
      case !this.assistanceInfo?.parking?.hasVehicle:
        return this.messagesPipe.transform(
          'assistance_text_not_assign_parking_text_alert'
        );
      case !this.assistanceInfo?.parking?.parkingSizeAttendance:
        return this.messagesPipe.transform(
          'assistance_text_not_parking_space_available'
        );
      default:
        break;
    }
  }

  getMessageDinner() {
    switch (true) {
      case !this.checkDinnerDays(this.assistanceSelected?.indexWeek) ||
        !this.checkDinnerMonths(this.assistanceSelected?.dateComplete):
        this.enableSelectDinner = false;
        this.enableButtonCancelDinner = false;
        return this.messagesPipe.transform(
          'assistance_text_not_dining_room_service'
        );

      case this.assistanceInfo?.dinner?.id &&
        this.assistanceInfo?.dinner?.hasReservation:
        if (
          this.checkDinnerDays(this.assistanceSelected?.indexWeek) &&
          this.checkDinnerMonths(this.assistanceSelected?.dateComplete) &&
          !this.assistanceSelected.disabledWhitAssistance
        ) {
          this.enableButtonCancelDinner = true;
        } else {
          this.enableButtonCancelDinner = false;
        }
        this.enableSelectDinner = false;
        return this.messagesPipe.transform('assistance_text_assign_dinner');

      case this.checkDinnerDays(this.assistanceSelected?.indexWeek) &&
        this.checkDinnerMonths(this.assistanceSelected?.dateComplete) &&
        !this.assistanceInfo?.dinner?.id &&
        !this.assistanceInfo?.dinner?.hasReservation &&
        this.assistanceInfo?.dinner?.availableTurns.length > 0 &&
        !this.assistanceSelected.disabledWhitAssistance:
        this.enableSelectDinner = true;
        this.enableButtonCancelDinner = false;
        return this.messagesPipe.transform(
          'assistance_text_available_dinner_space'
        );

      default:
        this.enableSelectDinner = false;
        this.enableButtonCancelDinner = false;
        return this.messagesPipe.transform('assistance_text_not_assign_dinner');
    }
  }

  activeIconParking() {
    return this.assistanceInfo &&
      this.assistanceInfo.parking &&
      this.assistanceInfo.parking.hasParking &&
      this.assistanceInfo.parking.assignmentId
      ? ` active`
      : ``;
  }

  activeIconDinner() {
    return this.assistanceInfo &&
      this.assistanceInfo.dinner &&
      this.assistanceInfo.dinner.hasReservation &&
      this.assistanceInfo.dinner.id
      ? ` active`
      : ``;
  }

  activeButtonParking() {
    if (
      (this.assistanceInfo?.parking?.assignmentId &&
        !this.assistanceSelected.disabledWhitAssistance) ||
      (!this.assistanceInfo?.parking?.assignmentId &&
        !this.assistanceInfo?.parking?.size)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getIconVehicule() {
    let assitance = null;
    if (
      this.assistanceInfo?.parking?.userInParkingWaitingList &&
      this.assistanceInfo?.parking?.parkingWaitingListEnabled &&
      this.assistanceInfo?.parking?.parkingSizeAttendance
    ) {
      assitance = this.assistanceInfo?.parking?.parkingSizeAttendance.find(
        (x) => x?.typeCodeVehicle
      );
      if (assitance) {
        switch (assitance?.typeCodeVehicle) {
          case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
            return 'vdp-calendarParkingmotoQueue';
          case TYPE_CODE_VEHICLE_RESERVE.CombustionCar:
            return 'vdp-calendarParkingQueue';
          case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
            return 'vdp-calendarParkingQueue';

          case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
            return 'vdp-calendarParkingmotoQueue';

          default:
            return 'vdp-parking_big';
        }
      }
    } else if (
      this.assistanceInfo?.parking?.assignmentId &&
      this.assistanceInfo?.parking?.hasParking &&
      this.assistanceInfo?.parking?.hasVehicle &&
      this.assistanceInfo?.parking?.parkingSizeAttendance[0]
    ) {
      switch (
        this.assistanceInfo?.parking?.parkingSizeAttendance[0]?.typeCode
      ) {
        case TYPE_CODE_VEHICLE.Motorcycle:
          return 'vdp-parkingMotoBig';
        case TYPE_CODE_VEHICLE.ElectricCar:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE.NoElectric:
          return 'vdp-parking_big';
        default:
          return 'vdp-parking_big';
      }
    } else {
      //TODO: Poner el icono del coche Main
      return 'vdp-parking_big';
    }
  }

  // FUNCTION UTILS

  checkShiftPriority(value: any) {
    const turn = this.assistanceForm.controls['turn'].value;
    let res = true;
    value?.forEach((element) => {
      if (element.id === turn) {
        res = false;
      }
    });

    if (res) {
      this.assistanceForm.controls['turn'].setValue('0');
      this.turnOK = false;
    }

    return res;
  }

  parkingSizeAttendanceEnable(assistance) {
    if (Array.isArray(assistance?.parking?.parkingSizeAttendance)) {
      return assistance?.parking?.parkingSizeAttendance?.find((x) => x.enabled);
    } else if (assistance?.parking?.parkingSizeAttendance?.enabled) {
      return true;
    }
  }

  getMainVehicule() {
    if (Array.isArray(this.assistanceInfo?.parking?.parkingSizeAttendance)) {
      return this.assistanceInfo?.parking?.parkingSizeAttendance?.find(
        (x) => x.main
      );
    } else if (this.assistanceInfo?.parking?.parkingSizeAttendance?.main) {
      return this.assistanceInfo?.parking?.parkingSizeAttendance;
    }
  }

  closeForm() {
    this.assistanceForm.reset();
    this.buttonDisabledRequestDinner = false;
    this.buttonDisabledRequestParking = false;
    this.sideModalService.closeSideModal();
  }

  // CHECK AND DISABLED

  checkSelectedDisabled() {
    const select = $('#placeParking').val();
    return select == 0 ? true : false;
  }

  checkDinnerDays(indexWeek) {
    let hasDinner = false;
    const dinnerDays = environment['dinnerDays'];
    if (dinnerDays) {
      dinnerDays.forEach((element) => {
        if (element == indexWeek) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  checkDinnerMonths(day) {
    const date = moment(day, 'DD-MM-YYYY');
    const month = date.month() + 1;
    let hasDinner = false;
    const dinnerMonths = environment['dinnerMonths'];
    if (dinnerMonths) {
      dinnerMonths.forEach((element) => {
        if (element == month) {
          hasDinner = true;
        }
      });
    } else {
      hasDinner = true;
    }
    return hasDinner;
  }

  statusModuleParking() {
    if (this.attendanceIntegrationParking && this.assistanceInfo?.parking) {
      return (this.enableModuleParking = true);
    } else {
      this.enableModuleParking = false;
      return (this.enableModuleParking = false);
    }
  }
}
