import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { environment, SERVICE_BASE_URL } from 'src/environments/environment';

const BASE_URL = SERVICE_BASE_URL;
const GET_INFORMATION = '/api/enterprise/getInformation';
const GET_TOKEN = '/api/token';
const CLIENT = environment.saasClient;
const PSSW = environment.saasPssw;

@Injectable({
  providedIn: 'root',
})
export class LoginTypeService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getInformation(email, token): Observable<any> {
    const url = `${BASE_URL}${GET_INFORMATION}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    let bodyString = `?userEmail=${email}`;
    return this.http.get(url + bodyString, { headers: headers });
  }

  getToken(): Observable<any> {
    const url = `${BASE_URL}${GET_TOKEN}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(`${CLIENT}:${PSSW}`),
    });

    let bodyString = '?grant_type=client_credentials';
    return this.http.post(url + bodyString, '', {
      headers: headers,
      withCredentials: true,
    });
  }

  getInformationPlaceHolder(): Observable<any> {
    const url = `${BASE_URL}/api/enterprise/getInformation`;
    return of({
      id: 2,
      domain: 'alten.es',
      company_id: 2,
      company_name: 'Alten',
      color_palette: 'color_palette_alten',
      dashboard_information: {
        big_circle_logo:
          'https://smartbuildingpre-cecotec.sdos.es/ws/web/file/2',
        show_module_in_big_circle: true,
      },
      login_information: {
        type: 'msal',
        logo_url: 'https://smartbuildingpre-cecotec.sdos.es/ws/web/file/2',
        webview_login_parameters: {
          login_url: 'https://example.com/example/alten_url_login',
          logout_url: 'https://example.com/example/alten_url_logout',
          callback_url: 'https://example.com/example/alten_url_callback',
          callback_access_token_parameter_name: 'access_token_alten',
          callback_scope_parameter_name: 'scope_alten',
        },
        msal_login_parameters: {
          param_msal_1: 'value_param_1_msal',
          param_msal_2: 'value_param_2_msal',
          aud_angular: '71da7711-13da-45f9-a40b-6ff93a8df0ab',
          aud_ios: '71da7711-13da-45f9-a40b-6ff93a8df0ab',
          aud_android: '71da7711-13da-45f9-a40b-6ff93a8df0ab',
          client_id: '71da7711-13da-45f9-a40b-6ff93a8df0ab',
          tenant_id: '9bc3d1cd-55ca-4e13-b5a2-a9e9deaeba3f',
        },
      },
      headquarter_name: 'Alten Saas',
      headquarter_id: 2,
    });
  }
}
