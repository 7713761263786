import { Component, OnInit } from '@angular/core';
import { LoginTypeService } from '../../shared/services/login-type.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ThemeSwitcherService } from '../../shared/services/theme-switcher.service';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import LoginData from '../../core/models/interfaces/LoginData';

@Component({
  selector: 'app-login-switcher',
  templateUrl: './login-switcher.component.html',
  styleUrls: ['./login-switcher.component.scss'],
})
export class LoginSwitcherComponent implements OnInit {
  loginWithAzure = false;
  formLoginType: FormGroup;
  errorLogin: string;
  loginType: string;
  emailActive: boolean;
  loginData: LoginData;
  palette: string;
  preLoginNeeded: boolean;

  constructor(
    private loginTypeService: LoginTypeService,
    private formBuilder: FormBuilder,
    private themeSwitcher: ThemeSwitcherService,
    private authService: MsalService
  ) {}

  ngOnInit() {
    this.preLoginNeeded = environment['preLoginNeeded'];
    if (this.preLoginNeeded && this.preLoginNeeded === true) {
      this.themeSwitcher.setDefaultTheme();
      this.initForm();
      this.initLoginData();
      this.checkAvailabeLoginsSaas();
    } else {
      this.checkAvailabeLogins();
    }
  }

  checkAvailabeLogins() {
    if (environment['use_azure']) {
      this.loginWithAzure = true;
    }
  }

  checkAvailabeLoginsSaas() {
    if (this.loginType === 'msal') {
      this.loginWithAzure = true;
    }
  }

  initForm() {
    this.formLoginType = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  getLoginType(formEmail: any) {
    this.loginTypeService.getToken().subscribe(
      (res) => {
        console.log('status: ', res);
        console.log('token pedido...');
        this.loginTypeService
          .getInformation(formEmail.email, res.access_token)
          .subscribe(
            (res) => {
              console.log('Email ', formEmail.email, 'enviado...');
              // solo se guarda el clientId si el usuario es de tipo azure, los usuarios por password no llevan dicha propiedad
              if (res.login_information.msal_login_parameters) {
                this.loginData.clientId =
                  res.login_information.msal_login_parameters.client_id;
                this.loginData.tenantId =
                  res.login_information.msal_login_parameters.tenant_id;
              }
              this.loginData.loginType = res.login_information.type;
              this.loginData.palette = res.color_palette;
              this.loginData.logoUrl = res.login_information.logo_url;
              this.loginData.email = formEmail.email;
              // Se guardan los datos necesarios para el login en un json en el localStorage
              localStorage.setItem('loginData', JSON.stringify(this.loginData));

              this.loginType = res.login_information.type;
              this.palette = res.color_palette;

              // Se establece la paleta de colores de la aplicación
              this.themeSwitcher.getTheme();

              this.errorLogin = '';
              if (this.loginType === 'msal') {
                this.authService.instance = new PublicClientApplication({
                  auth: {
                    clientId: this.loginData.clientId,
                    authority: `https://login.microsoftonline.com/${this.loginData.tenantId}/`,
                    redirectUri: environment['redirectUrl'],
                    postLogoutRedirectUri: environment['redirectUrl'],
                  },
                });
                console.log(localStorage.getItem('loginType'));
                this.loginWithAzure = true;
              }
            },
            (error) => {
              console.log(error);
              this.errorLogin = 'Cannot access';
            }
          );
      },
      (error) => {
        console.log(error);
        this.errorLogin = 'Cannot access';
      }
    );
  }

  getLoginTypePlaceholder(formEmail: any) {
    this.loginTypeService.getInformationPlaceHolder().subscribe(
      (res) => {
        console.log('Email ', formEmail.email, 'enviado...');
        console.log(res);
        // solo se guarda el clientId si el usuario es de tipo azure, los usuarios por password no llevan dicha propiedad
        if (res.login_information.msal_login_parameters) {
          this.loginData.clientId =
            res.login_information.msal_login_parameters.client_id;
          this.loginData.tenantId =
            res.login_information.msal_login_parameters.tenant_id;
        }
        this.loginData.loginType = res.login_information.type;
        this.loginData.palette = res.color_palette;
        this.loginData.logoUrl = res.login_information.logo_url;
        this.loginData.email = formEmail.email;
        // Se guardan los datos necesarios para el login en un json en el localStorage
        localStorage.setItem('loginData', JSON.stringify(this.loginData));

        this.loginType = res.login_information.type;
        this.palette = res.color_palette;

        // Se establece la paleta de colores de la aplicación
        this.themeSwitcher.getTheme();

        this.errorLogin = '';
        if (this.loginType === 'msal') {
          this.authService.instance = new PublicClientApplication({
            auth: {
              clientId: this.loginData.clientId,
              authority: `https://login.microsoftonline.com/${this.loginData.tenantId}/`,
              redirectUri: environment['redirectUrl'],
              postLogoutRedirectUri: environment['redirectUrl'],
            },
          });
          console.log(localStorage.getItem('loginType'));
          this.loginWithAzure = true;
        }
      },
      (error) => {
        console.log(error);
        this.errorLogin = 'Cannot access';
      }
    );
  }

  setEmailActive() {
    this.emailActive = true;
  }

  removeEmailActive() {
    if (this.formLoginType.controls['email'].value.length == 0) {
      this.emailActive = false;
    }
  }

  initLoginData() {
    if (localStorage.getItem('loginType')) {
      this.loginData = JSON.parse(localStorage.getItem('loginType'));
    } else {
      this.loginData = {
        loginType: '',
        clientId: '',
        palette: '',
        logoUrl: '',
        email: '',
      };
    }
  }
}
