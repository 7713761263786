<article class="modal" id="modalResConfirm" #modalResConfirm>
  <div class="modal__confirmation">
    <section class="modal-info">
      <span class="title">{{ reserveConfirm_title }}</span>
      <span class="message">{{ reserveConfirm_text }}</span>
    </section>
    <section class="modal-footer">
      <a (click)="closeModal()">
        {{ reserveConfirm_close }}
      </a>
      <a (click)="confirmReservationMulti()" class="indicate">
        {{ reserveConfirm_confirm }}
      </a>
    </section>
  </div>
</article>
