import { environment } from 'src/environments/environment';
import LoginData from '../app/core/models/interfaces/LoginData';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
} from '@azure/msal-angular';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

// function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: environment['clientId'],
//       authority: environment['authority'],
//       redirectUri: environment['redirectUrl'],
//       postLogoutRedirectUri: environment['redirectUrl']
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage,
//       storeAuthStateInCookie: isIE,
//     },
//     system: {
//       loggerOptions: {
//         loggerCallback,
//         logLevel: LogLevel.Info,
//         piiLoggingEnabled: false,
//       },
//     },
//   });
// }

// ToDo: usar datos del tipo de login extraidos del backend

function MSALInstanceFactory(): IPublicClientApplication {
  console.log('MSAL');
  const loginData: LoginData = JSON.parse(localStorage.getItem('loginData'));
  if (loginData) {
    return new PublicClientApplication({
      auth: {
        clientId: loginData.clientId,
        authority: `https://login.microsoftonline.com/${loginData.tenantId}/`,
        redirectUri: environment['redirectUrl'],
        postLogoutRedirectUri: environment['redirectUrl'],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  } else {
    return new PublicClientApplication({
      auth: {
        clientId: environment['clientId'],
        authority: environment['authority'],
        redirectUri: environment['redirectUrl'],
        postLogoutRedirectUri: environment['redirectUrl'],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });
  }
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export {
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
  MSALGuardConfigFactory,
};
