import { Component, OnInit } from '@angular/core';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';

@Component({
  selector: 'app-car-wash',
  templateUrl: './car-wash.component.html',
  styleUrls: ['./car-wash.component.scss'],
})
export class CarWashComponent implements OnInit {
  messagesPipe = new MessagesPipe();
  id: any;
  showCarWashAlert = true;

  constructor(private navigatorRefreshService: NavigatorRefreshService) { }

  ngOnInit() {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_carWash')
    );
  }
}
