import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalReservationConfirmationService {
  constructor() {}

  openModalMultiReservation: (
    dateFrom: number,
    dateTo: number,
    desks: any[],
    users: any[],
    token: string,
    option: string,
    hourFrom: number,
    hourNumber: number
  ) => void;
  setLanguage: (language: string) => void;
  closeModal: () => void;
}
