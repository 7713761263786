import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalReservationConfirmationService } from './modal-reservation-confirmation.service';
import { MessagesPipe } from '../../pipes/messages.pipe';
import { BoxOfficeService } from '../../services/box-office.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from '../../services/flash-messages.service';
import { SpinnerService } from '../spinner/spinner.service';

@Component({
  selector: 'app-modal-reservation-confirmation',
  templateUrl: './modal-reservation-confirmation.component.html',
  styleUrls: ['./modal-reservation-confirmation.component.scss'],
})
export class ModalReservationConfirmationComponent implements OnInit {
  modalResConfirm: HTMLElement;
  messagesPipe: MessagesPipe = new MessagesPipe();
  timeOutApiAddReservation: any;

  reserveConfirm_title: string;
  reserveConfirm_text: string;
  reserveConfirm_close: string;
  reserveConfirm_confirm: string;

  //Data to make multi-reservation
  dateFrom: number;
  dateTo: number;
  desks: any[];
  users: any[];
  token: string;
  option: string;
  hourFrom: number;
  hourNumber: number;

  constructor(
    private flashMessage: FlashMessagesService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private boxOfficeService: BoxOfficeService,
    private modalReservationConfirmationService: ModalReservationConfirmationService
  ) {
    this.modalReservationConfirmationService.openModalMultiReservation =
      this.openModalMultiReservation.bind(this);
    this.modalReservationConfirmationService.closeModal =
      this.closeModal.bind(this);
    this.modalReservationConfirmationService.setLanguage =
      this.setLanguage.bind(this);
  }

  ngOnInit() {
    this.modalResConfirm = document.getElementById('modalResConfirm');
  }

  openModalMultiReservation(
    dateFrom: number,
    dateTo: number,
    desks: any[],
    users: any[],
    token: string,
    option: string,
    hourFrom: number,
    hourNumber: number
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.desks = desks;
    this.users = users;
    this.token = token;
    this.option = option;
    this.hourFrom = hourFrom;
    this.hourNumber = hourNumber;

    this.modalResConfirm.style.display = 'flex';
  }

  closeModal() {
    this.modalResConfirm.style.display = 'none';
  }

  setLanguage(language: string) {
    // Set language for mobility part
    let message = new MessagesPipe();
    if (language) {
      message.setLanguage(language);
    }

    this.reserveConfirm_title = message.transform('reserve_confirm_title');
    this.reserveConfirm_text = message.transform('reserve_confirm_text');
    this.reserveConfirm_close = message.transform('assistance_button_close');
    this.reserveConfirm_confirm = message.transform(
      'assistance_button_confirm'
    );
  }

  dipastchEventReservation(status: boolean) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
            deskReserve.statusReserve(${status});
            `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
            window.webkit.messageHandlers.statusReserve.postMessage(${status});
            `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  dipastchEventError(codeError: number) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
        deskReserve.errorReserve(${codeError});
        `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
        window.webkit.messageHandlers.errorReserve.postMessage(${codeError});
        `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  confirmReservationMulti() {
    const url = this.route.snapshot['_routerState'].url as String;
    this.spinnerService.show();
    let subMultiBoxOffice = this.boxOfficeService
      .addMultipleReservation(
        this.dateFrom,
        this.dateTo,
        this.desks,
        this.users,
        this.token,
        this.option,
        this.hourFrom,
        this.hourNumber
      )
      .subscribe((response) => {
        if (response && response.ok) {
          this.closeModal;

          this.dipastchEventReservation(true);

          if (url.includes('/selectMultiple-position/')) {
            this.flashMessage.show(
              this.messagesPipe.transform('reservation_desk_success'),
              { cssClass: 'alert-success', timeout: 3000 }
            );

            this.router.navigate([
              'menu',
              url.includes('/dinning-position/')
                ? 'dinning-position'
                : 'job-position',
            ]);
          }
        } else {
          this.dipastchEventReservation(false);

          if (url.includes('/selectMultiple-position/')) {
            this.flashMessage.show(
              this.messagesPipe.transform('reservation_desk_error'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        }
        this.spinnerService.hide();
        clearTimeout(this.timeOutApiAddReservation);
      },
      (error) => {
        if(error && error.code){
          this.dipastchEventError(error.code);

          if (url.includes('/selectMultiple-position/')) {
            this.flashMessage.show(
              this.messagesPipe.transform('reservation_desk_error'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        }
        this.spinnerService.hide();
        clearTimeout(this.timeOutApiAddReservation);
      }
      );
    this.timeOutApiAddReservation = setTimeout(() => {
      subMultiBoxOffice.unsubscribe();
      this.dipastchEventError(408);
      this.spinnerService.hide();
    }, 10000);
  }
}
