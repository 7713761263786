import { Injectable } from '@angular/core';
import LoginData from '../../core/models/interfaces/LoginData';
import {
  color_palette_alten,
  color_palette_sdos,
  test_palette,
  color_palette_sgs,
  color_palette_lleida,
} from '../../../configurations/saas/assets/colors/palettes';

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitcherService {
  constructor() {}

  palette: string;
  loginData: LoginData;

  getLoginData() {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.palette = this.loginData.palette;
  }

  getTheme() {
    if (localStorage.getItem('loginData')) {
      this.getLoginData();

      // color_palette_alten
      if (this.palette.includes('color_palette_alten')) {
        document.documentElement.style.cssText = color_palette_alten;
      }
      // color_palette_sdos
      if (this.palette.includes('color_palette_sdos')) {
        document.documentElement.style.cssText = color_palette_sdos;
      }
      // test_palette
      if (this.palette.includes('test_palette')) {
        document.documentElement.style.cssText = test_palette;
      }
      // color_palette_sgs
      if (this.palette.includes('color_palette_sgs')) {
        document.documentElement.style.cssText = color_palette_sgs;
      }
      // color_palette_lleida
      if (this.palette.includes('color_palette_lleida')) {
        document.documentElement.style.cssText = color_palette_lleida;
      }
    }
  }

  setDefaultTheme() {
    document.documentElement.style.cssText = color_palette_alten;
  }
}
