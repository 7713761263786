<div *ngIf="preLoginNeeded === true; else noPreLogin">
  <div *ngIf="!loginType" class="loginType">
    <div class="loginType-box">
      <div class="email-form">
        <img
          class="loginImg"
          src="../../assets/img/logo-login-centered.png"
          alt="Login image"
        />
        <h2 class="type-title">Welcome to Smart Building</h2>
        <form
          [formGroup]="formLoginType"
          (submit)="getLoginType(formLoginType.value)"
          action="submit"
        >
          <div class="input-field">
            <label for="email" [ngClass]="{ active: emailActive }"
              >Enter your email address *</label
            >
            <input
              [formControl]="formLoginType.controls['email']"
              id="email"
              type="email"
              name="email"
              class="form-control"
              maxlength="100"
              required
              (focus)="setEmailActive()"
              (focusout)="removeEmailActive()"
            />
            <i class="vdp-icon_usuario"></i>
          </div>
          <div class="form-actions">
            <button type="submit">Enter</button>
          </div>
          <div class="errorLogin" *ngIf="errorLogin">
            <span>{{ errorLogin }}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="loginType" class="switch">
    <!-- If the client has set the option to login with azure in its environment -->
    <app-login-azure *ngIf="loginWithAzure"></app-login-azure>
    <!-- Otherwise the app will use the default login -->
    <loginComponent *ngIf="!loginWithAzure"></loginComponent>
  </div>
</div>
<ng-template #noPreLogin>
  <!-- If the client has set the option to login with azure in its environment -->
  <app-login-azure *ngIf="loginWithAzure"></app-login-azure>
  <!-- Otherwise the app will use the default login -->
  <loginComponent *ngIf="!loginWithAzure"></loginComponent>
</ng-template>
