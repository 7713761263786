import { Component, Input, OnInit } from '@angular/core';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import {
  TYPE_CODE_VEHICLE,
  TYPE_CODE_VEHICLE_RESERVE,
} from '../../../../core/constants/consts';
import { UserLdapWs } from '../../../../core/models/user.ldap.ws';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-calendar-view-assistance',
  templateUrl: './calendar-view-assistance.component.html',
  styleUrls: ['./calendar-view-assistance.component.scss'],
})
export class CalendarViewAssistanceComponent implements OnInit {
  @Input() day: any;
  @Input() attendances: any;
  @Input() selectList: any;

  dateNew: Date;
  maxDayAssistance: number;
  permissions: MenuPermissions;
  user: UserLdapWs;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.dateNew = new Date();
    this.permissions =
      this.authenticationService.getPermissionsForCurrentUser();
    this.maxDayAssistance = this.permissions.maxDaysAssistance;
    this.user = this.authenticationService.getCurrentUser();
  }

  getClassSelected(day: any) {
    let ret = '';
    if (day.selected) {
      ret = ` selected`;
    }
    if (day.disabledWhitAssistance) {
      ret += ` dayCalendarDisabledWhitAssistance`;
    }
    if (day.assignments && (day.assignments.id || day.assignments.day)) {
      ret += ` assignment`;
    }
    return ret;
  }

  getIconVehicule(day: any) {
    if (
      day?.assignments?.parking?.userInParkingWaitingList &&
      day?.assignments?.parking?.parkingWaitingListEnabled &&
      day?.assignments?.parking?.parkingSizeAttendance?.typeCodeVehicle
    ) {
      switch (
        day?.assignments?.parking?.parkingSizeAttendance?.typeCodeVehicle
      ) {
        case TYPE_CODE_VEHICLE_RESERVE.Motorcycle:
          return 'vdp-calendarParkingmotoQueue';
        case TYPE_CODE_VEHICLE_RESERVE.CombustionCar:
          return 'vdp-calendarParkingQueue';
        case TYPE_CODE_VEHICLE_RESERVE.ElectricCar:
          return 'vdp-calendarParkingQueue';

        case TYPE_CODE_VEHICLE_RESERVE.ElectricMotorCycle:
          return 'vdp-calendarParkingmotoQueue';

        default:
          return 'vdp-parking_big';
      }
    } else if (
      day?.assignments?.parking?.assignmentId &&
      day?.assignments?.parking?.hasParking &&
      day?.assignments?.parking?.parkingSizeAttendance
    ) {
      switch (day?.assignments?.parking?.parkingSizeAttendance?.typeCode) {
        case TYPE_CODE_VEHICLE.Motorcycle:
          return 'vdp-parkingMotoBig';
        case TYPE_CODE_VEHICLE.ElectricCar:
          return 'vdp-calendarParkingCharge';
        case TYPE_CODE_VEHICLE.NoElectric:
          return 'vdp-parking_big';

        default:
          return 'vdp-parking_big';
      }
    }
  }
}
