import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_CURRENT_USER } from 'src/app/core/constants/const';
import { MyDatePicker } from 'src/app/shared/components/date_picker/datepicker.component';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Utils } from 'src/app/shared/utils/utils';
import { HOUR_MULTIPLE_RESERVATION_DINNER } from '../../../core/constants/consts';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import {
  AVAILABLE_MINUTES,
} from '../../../core/constants/consts';
import { UserService } from 'src/app/shared/services/user.service';
import { SideModalService } from '../../side-modal/side-modal.service';
import { BuildingService } from 'src/app/shared/services/building.service';
import { FloorService } from 'src/app/shared/services/floor.service';
import { AreaService } from 'src/app/shared/services/area.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { BuildingWs } from 'src/app/core/models/building.ws';
import { Floor } from 'src/app/core/models/floor';

@Component({
  selector: 'app-multiple-reservation',
  templateUrl: './multiple-reservation.component.html',
  styleUrls: ['./multiple-reservation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultipleReservationComponent implements OnInit, AfterViewInit {
  messagesPipe = new MessagesPipe();
  @ViewChild('defaultOpen') defaultTab: ElementRef;
  @ViewChild('secondTab') secondTab: ElementRef;
  multipleReservationForm: FormGroup;
  multipleBuildingSearchForm: FormGroup;
  @ViewChild('datePickerFromElement') datePickerFromElement: MyDatePicker;
  @ViewChild('datePickerToElement') datePickerToElement: MyDatePicker;
  @ViewChild('selectHourFrom') selectHourFrom: any;
  @ViewChild('selectMinuteFrom') selectMinuteFrom: any;
  @ViewChild('selectHourTo') selectHourTo: any;
  @ViewChild('selectMinuteTo') selectMinuteTo: any;
  datepickerFromOptions: any = {};
  datepickerToOptions: any = {};
  startDate: Date;
  endDate: Date;
  locale: string;
  searchType = 'byDepartment';
  selectedPeople: any[] = [];
  selectedUsersForSearch: any[] = [];
  availableHours: number[] = [];
  url: String;
  selectOptionMenu: string;
  user: UserLdapWs;
  maxDesk: number;
  isDinner = false;
  possibleHoursFrom: string[] = [];
  hoursTo: string[];
  minutesFrom = AVAILABLE_MINUTES;
  minutesTo = AVAILABLE_MINUTES;
  addLoggedUser: boolean = false;
  addLoggedUserCheck: any;
  hourFrom: Date;
  hourNumber: number;
  reservationDateFrom: string;

  token: string;
  buildings: BuildingWs[];
  floors: Floor[];
  areas: any[];

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private buildingService: BuildingService,
    private floorService: FloorService,
    private areaService: AreaService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position')
      ? 'dinner'
      : 'desks';
    this.isDinner = this.url.includes('/dinning-position') ? true : false;
    this.user = this.authenticationService.getCurrentUser();
    this.maxDesk = this.user.headquarters.maxDeskMultipleReservation;
    this.createForm();
    this.createMultiBuildingSearchForm();
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
    this.datepickerFromOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays()
    );
    
    //* Se genera el array de horas disponibles para la reserva múltiple
    let userData = JSON.parse(localStorage.getItem('currentUser'));
    let openingTime = new Date(userData.openingTime).getHours();
    let endingTime = new Date(userData.endingTime).getHours();

    for (let i = openingTime; i < endingTime; i++) {
      let number = i < 10 ? '0' + i : i.toString();
      this.possibleHoursFrom.push(number);
    }

    this.token = JSON.parse(localStorage.getItem('currentUser')).access_token
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.defaultTab.nativeElement.click();
      this.utils.setDatePickerDate(new Date(), this.datePickerFromElement);
      this.setTimeFrom();
    }, 0);
  }

  manageTabs(evt: any, tabName: string) {
    let i: number, tabcontent: any, tablinks: any;

    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' active';
  }

  setAvailableHours() {
    const user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
    const closeTime = user.endingTime ? user.endingTime : user.closingTime;
    const maxHoursPerDay = (closeTime - user.openingTime) / (3600 * 1000);

    this.availableHours = Array.from(Array(maxHoursPerDay).keys()).map(
      (x) => ++x
    );
  }

  setTimeFrom(){
    this.reservationDateFrom = this.utils.selectHoursSetPositionFrom(
      this.authenticationService,
      this.possibleHoursFrom,
      this.minutesFrom,
      this.selectHourFrom,
      this.selectMinuteFrom
    )
  }

  setAvailableHoursDinner() {
    this.availableHours = HOUR_MULTIPLE_RESERVATION_DINNER;
  }

  createForm() {
    const onlyNumbersRegex = /^\d+/;
    this.selectOptionMenu == 'dinner'
      ? this.setAvailableHoursDinner()
      : this.setAvailableHours();

    this.multipleReservationForm = this.formBuilder.group({
      numberOfpositions: [
        '',
        [Validators.required, Validators.pattern(onlyNumbersRegex)],
      ],
      numberOfHours: [
        '-1',
        [Validators.required, Validators.pattern(onlyNumbersRegex)],
      ],
    });
  }

  isFormValid(): boolean {
    this.multipleReservationForm.controls['numberOfpositions'].setValue(
      this.selectedPeople.length
    );
    return (
      this.multipleReservationForm.valid &&
      this.startDate != null &&
      this.endDate != null &&
      (this.multipleReservationForm.controls['numberOfpositions'].value > 0 || this.addLoggedUser)
    );
  }

  onStartDateChanged($event) {
    this.startDate = $event.jsdate;

    if (this.startDate) {
      if (this.startDate > this.endDate) {
        this.datePickerToElement.clearDate();
        this.datepickerToOptions = this.utils.getDatepickerOptions(
          this.authenticationService.getMaxHotDeskAdvanceDays()
        );
      }
      this.setRestrictionsToEndDate();
    } else {
      this.datePickerToElement.clearDate();
      this.datepickerToOptions = this.utils.getDatepickerOptions(
        this.authenticationService.getMaxHotDeskAdvanceDays()
      );
    }
    
  }

  onEndDateChanged($event) {
    this.endDate = $event.jsdate;
  }

  private setRestrictionsToEndDate(): void {
    const allowStartDate = new Date(this.startDate);
    allowStartDate.setDate(allowStartDate.getDate() - 1);

    this.datepickerToOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxHotDeskAdvanceDays(),
      allowStartDate
    );
  }

  getSelectedPeople($event): void {
    this.selectedPeople = $event;
  }

  checkMyself(): void {
    this.addLoggedUserCheck = document.getElementById('checkMyself');
    if(this.addLoggedUserCheck.checked){
      this.addLoggedUser = true;
    } else {
      this.addLoggedUser = false;
    }
  }

  // Check date and time is not lower than today
  // Chechk date and time is not higher than the limit of the headquarters opening time
  checkDayAvailability(): boolean {
    let today = new Date();
    let openingTime = new Date(JSON.parse(localStorage.getItem('currentUser')).openingTime).getHours();
    let endingTime = new Date(JSON.parse(localStorage.getItem('currentUser')).endingTime).getHours();
    let limitsToday = {
      openingTime: new Date(this.hourFrom.getTime()).setHours(openingTime,0,0,0),
      endingTime: new Date(this.hourFrom.getTime()).setHours(endingTime,0,0,0),
    }
    let hourTo = new Date(this.hourFrom.getTime());
    hourTo.setHours(hourTo.getHours() + this.hourNumber);
    
    if(this.hourFrom.getTime() < today.getTime()){
      this.flashMessagesService.show(
        this.messagesPipe.transform('reserve_limit_today_lower'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
      return false;
    } else if(hourTo.getTime() < limitsToday.openingTime || hourTo.getTime() > limitsToday.endingTime){
      this.flashMessagesService.show(
        this.messagesPipe.transform('reserve_limit_opening_headquarters'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
      return false;
    } else {
      return true;
    }
  }

  // ***********************************
  // multi-reservation area search logic
  searchByPosition(): void {
    this.hourNumber = parseInt(
      this.multipleReservationForm.controls['numberOfHours'].value,
      10
    );
    this.hourFrom = new Date(this.startDate.getTime());
    this.hourFrom.setHours(this.selectHourFrom.nativeElement.value,this.selectMinuteFrom.nativeElement.value);

    if(!this.checkDayAvailability()){
      return;
    }

    this.buildings = [];
    this.floors = [];
    this.areas = [];
    this.selectedUsersForSearch = [...this.selectedPeople]
    if(this.addLoggedUser){
      let user = this.authenticationService.getCurrentUser();
      this.userService.searchIdFromUser(
        this.token,
        user.username
      ).subscribe(
        (response: any) => {
          let loggedUserAlreadySelected = this.selectedUsersForSearch.findIndex((person) =>  JSON.stringify(person) == JSON.stringify(response[0]));
          if(loggedUserAlreadySelected === -1){
            this.selectedUsersForSearch.push(response[0]);
          }
          this.secondTab.nativeElement.click();
          this.getBuilding();
        }
      );
    } else {
      this.secondTab.nativeElement.click();
      this.getBuilding();
    }
  }

  createMultiBuildingSearchForm():void{
    this.multipleBuildingSearchForm = new FormGroup({
      idBuilding: new FormControl(-1, Validators.required),
      idFloor: new FormControl(-1, Validators.required),
      idArea: new FormControl(-1, Validators.required),
    });
    
    this.multipleBuildingSearchForm.get('idBuilding').disable();
    this.multipleBuildingSearchForm.get('idFloor').disable();
    this.multipleBuildingSearchForm.get('idArea').disable();
  }

  getBuilding(){
    this.buildings = [];
    this.buildingService.getBuildingForMultiReservation(
      this.startDate.getTime(),
      this.endDate.getTime(),
      this.selectedUsersForSearch.length,
      this.hourFrom.getTime(),
      this.hourNumber,
      this.selectedUsersForSearch.map((person) => {
        return person.id;
      }),
      this.token,
      this.selectOptionMenu
    ).subscribe((response) => {
      if(response.length > 0){
        this.multipleBuildingSearchForm.get('idBuilding').enable();
        this.buildings = response;
        if(this.buildings.length > 1){
          this.multipleBuildingSearchForm.get('idBuilding').patchValue(-1);
        } else if (this.buildings.length == 1){
          this.multipleBuildingSearchForm.get('idBuilding').patchValue(this.buildings[0].id);
        }
        this.changeBuilding();
      } else {
        this.buildings = [];
        this.multipleBuildingSearchForm.get('idBuilding').disable();
        this.floors = [];
        this.multipleBuildingSearchForm.get('idFloor').disable();
        this.areas = [];
        this.multipleBuildingSearchForm.get('idArea').disable();
        this.flashMessagesService.show(
        this.messagesPipe.transform('reserve_not_available'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
      }
    })
  }

  changeBuilding(){
    if(this.multipleBuildingSearchForm.get('idBuilding').value > 0){
      this.multipleBuildingSearchForm.patchValue({
        idFloor: -1,
        idArea: -1
      });
      this.multipleBuildingSearchForm.get('idArea').disable();
      this.getFloors(this.multipleBuildingSearchForm.get('idBuilding').value);
    } else {
      this.floors = [];
      this.multipleBuildingSearchForm.get('idFloor').disable();
      this.areas = [];
      this.multipleBuildingSearchForm.get('idArea').disable();
    }
  }

  getFloors(buildingId: number){
    this.floors = [];
    this.floorService.getFloorsForMultiReservation(
      buildingId,
      this.startDate.getTime(),
      this.endDate.getTime(),
      this.selectedUsersForSearch.length,
      this.hourFrom.getTime(),
      this.hourNumber,
      this.selectedUsersForSearch.map((person) => {
        return person.id;
      }),
      this.token,
      this.selectOptionMenu
    ).subscribe((response) => {
      if(response.length > 0){
        this.multipleBuildingSearchForm.get('idFloor').enable();
        this.floors = response;
        if(this.floors.length > 1){
          this.multipleBuildingSearchForm.get('idFloor').patchValue(-1);
        } else if (this.floors.length == 1){
          this.multipleBuildingSearchForm.get('idFloor').patchValue(this.floors[0].id);
        }
        this.changeFloor();
      } else {
        this.floors = [];
        this.multipleBuildingSearchForm.get('idFloor').disable();
        this.areas = [];
        this.multipleBuildingSearchForm.get('idArea').disable();
      }
    })
  }

  changeFloor(){
    if(this.multipleBuildingSearchForm.get('idFloor').value > 0){
      this.multipleBuildingSearchForm.patchValue({
        idArea: -1
      });
      this.multipleBuildingSearchForm.get('idArea').disable();
      this.getAreas(this.multipleBuildingSearchForm.get('idFloor').value);
    } else {
      this.areas = [];
      this.multipleBuildingSearchForm.get('idArea').disable();
    }
  }    
    
  getAreas(floorId: number){
    this.areas = [];
    this.areaService.getAreasForMultiReservation(
      floorId,
      this.startDate.getTime(),
      this.endDate.getTime(),
      this.selectedUsersForSearch.length,
      this.hourFrom.getTime(),
      this.hourNumber,
      this.selectedUsersForSearch.map((person) => {
        return person.id;
      }),
      this.token,
      this.selectOptionMenu
    ).subscribe(
      (response) => {
      if(response.length > 0){
        this.multipleBuildingSearchForm.get('idArea').enable();
        this.areas = response;
        if(this.areas.length > 1){
          this.multipleBuildingSearchForm.get('idArea').patchValue(-1);
        } else if (this.areas.length == 1){
          this.multipleBuildingSearchForm.get('idArea').patchValue(this.areas[0].id);
        }
      } else {
        this.areas = [];
        this.multipleBuildingSearchForm.get('idArea').disable();
      }
      () => {
        this.multipleBuildingSearchForm.get('idArea').disable();
        this.areas = [];
      }
    })
  }

  isSearchBuildingValid(){
    if(
      this.multipleBuildingSearchForm.valid &&
      this.multipleBuildingSearchForm.get('idBuilding').value > 0 &&
      this.multipleBuildingSearchForm.get('idFloor').value > 0 &&
      this.multipleBuildingSearchForm.get('idArea').value > 0
    ){
      return false;
    } else {
      return true;
    }
  }

  seeAvailablePositions2(): void {
    let selectedUsers = 
      this.selectedUsersForSearch.map((person) => {
        return person.id;
      })
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'selectMultiple-position',
      this.multipleBuildingSearchForm.get('idArea').value,
      this.startDate.getTime(),
      this.endDate.getTime(),
      this.hourNumber,
      this.hourFrom.getTime(),
      this.token
    ],
    {
      queryParams: {
        user: selectedUsers
      }
    }
    );
  }

  // En caso de querer volver a la anterior versión de reserva múltiple
  /* seeAvailablePositions(): void {
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'available-positions',
      dateFrom.getTime(),
      dateTo.getTime(),
      hotdeskNumber,
      hourNumber,
      this.selectedPeople
        .map((person) => {
          return person.id;
        })
        .join(','),
    ]);
  } */
}
