import { RouterModule, Routes } from '@angular/router';
import { COMPONENT_OAUTH_PATH } from '../environments/environment';
import { MenuGuard } from './core/guards/menu.guard';
import { CalendarEditConfirmComponent } from './shared/components/calendar/calendar-edit/calendar-edit-confirm/calendar-edit-confirm.component';
import { CalendarEditListUsersComponent } from './shared/components/calendar/calendar-edit/calendar-edit-list-users/calendar-edit-list-users.component';
import { CalendarEditNotConfirmComponent } from './shared/components/calendar/calendar-edit/calendar-edit-not-confirm/calendar-edit-not-confirm.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { OauthComponent } from './shared/components/oauth.component';
import { AppVersionComponent } from './views/app-version/app-version.component';
import { AssistanceIberdrolaUserComponent } from './views/assistance-iberdrola-user/assistance-iberdrola-user.component';
import { AssistanceIberdrolaComponent } from './views/assistance-iberdrola/assistance-iberdrola.component';
import { AssistanceUserComponent } from './views/assistance-user/assistance-user.component';
import { AssistanceComponent } from './views/assistance/assistance.component';
import { BoxOfficeComponent } from './views/box-office/box-office.component';
import { ReservationDeskComponent } from './views/box-office/reservation-desk/reservation-desk.component';
import { CantineComponent } from './views/cantine/cantine.component';
import { CarWashListComponent } from './views/car-wash/car-wash-list/car-wash-list.component';
import { CarWashReserveMobileComponent } from './views/car-wash/car-wash-reserve-mobile/car-wash-reserve-mobile.component';
import { CarWashReserveComponent } from './views/car-wash/car-wash-reserve/car-wash-reserve.component';
import { CarWashComponent } from './views/car-wash/car-wash.component';
import { CustomExternalModuleComponent } from './views/custom-external-module/custom-external-module.component';
import { DescargasComponent } from './views/descargas/descargas.component';
import { DinningOccupationComponent } from './views/dinning-occupation/dinning-occupation.component';
import { DinningPositionComponent } from './views/dinning-position/dinning-position.component';
import { DorletComponent } from './views/dorlet/dorlet.component';
import { ExternalModuleComponent } from './views/external-module/external-module.component';
import { GymResorucesListComponent } from './views/gym-resources/gym-resoruces-list/gym-resoruces-list.component';
import { GymResorucesReserveComponent } from './views/gym-resources/gym-resoruces-reserve/gym-resoruces-reserve.component';
import { GymResourcesComponent } from './views/gym-resources/gym-resources.component';
import { HomeComponent } from './views/home/home.component';
import { IncidenceNewFormComponent } from './views/incidence/incidence-new-form/incidence-new-form.component';
import { IncidenceComponent } from './views/incidence/incidence.component';
import { AvailablePositionsComponent } from './views/job-position/available-positions/available-positions.component';
import { CheckInWithCodeComponent } from './views/job-position/check-in-with-code/check-in-with-code.component';
import { JobPositionComponent } from './views/job-position/job-position.component';
import { ListOfPositionsComponent } from './views/job-position/list-of-positions/list-of-positions.component';
import { MultipleReservationComponent } from './views/job-position/multiple-reservation/multiple-reservation.component';
import { ReservationByPositionComponent } from './views/job-position/reservation-by-position/reservation-by-position.component';
import { SearchUserPositionComponent } from './views/job-position/search-user-position/search-user-position.component';
import { UserPositionsComponent } from './views/job-position/user-positions/user-positions.component';
import { LoginSwitcherComponent } from './views/login-switcher/login-switcher.component';
import { LopdComponent } from './views/lopd/lopd.component';
import { ParkingOccupationMenuComponent } from './views/parking-occupation-menu/parking-occupation-menu.component';
import { ParkingOccupationComponent } from './views/parking-occupation/parking-occupation.component';
import { ParkingOneComponent } from './views/parking-one/parking-one.component';
import { ParkingComponent } from './views/parking/parking.component';
import { ModalLanguageComponent } from './views/profile/modal-language/modal-language.component';
import { ProfileFormComponent } from './views/profile/profile-form/profile-form.component';
import { ProfileComponent } from './views/profile/profile.component';
import { QuestionAndAnswersListComponent } from './views/questions-and-answers/question-and-answers-list/question-and-answers-list.component';
import { QuestionAndAnswersNewComponent } from './views/questions-and-answers/question-and-answers-new/question-and-answers-new.component';
import { QuestionAndAnswersResultComponent } from './views/questions-and-answers/question-and-answers-result/question-and-answers-result.component';
import { QuestionAndAnswersTypeComponent } from './views/questions-and-answers/question-and-answers-type/question-and-answers-type.component';
import { QuestionsAndAnswersComponent } from './views/questions-and-answers/questions-and-answers.component';
import { ReservationListComponent } from './views/reservation/reservation.list.component';
import { ReservationNewDetailByHQ } from './views/reservation/reservation_new_by_headquarters/reservation.new.detail.by.hq';
import { ReservationNewDetailByRoom } from './views/reservation/reservation_new_by_room/reservation.new.detail.room';
import { ReservationNewFormComponent } from './views/reservation/reservation_new_form/reservation.new.form';
import { ReservationSearchByRoomAndPeriodComponent } from './views/reservation/reservation_search_by_room_and_period/reservation.search.by.room.period.component';
import { ReservationSpeedNewDetail } from './views/reservation/reservation_speed/reservation.speed.new.detail';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ListComponent } from './views/resource/list/list.component';
import { ReserveMobileComponent } from './views/resource/reserve-mobile/reserve-mobile.component';
import { ReserveComponent } from './views/resource/reserve/reserve.component';
import { ResourceComponent } from './views/resource/resource.component';
import { RoomAvailableComponent } from './views/room/room.available.component';
import { RoomSpeedAvailableComponent } from './views/room/room_speed/room.speed.available.component';
import { RoomWithoutTime } from './views/room/room_without_time/room.without.time';
import { RoomWithoutTimeMosaic } from './views/room/room_without_time/room.without.time.mosaic';
import { AssignPlaceComponent } from './views/security-search/assign-place/assign-place.component';
import { SecuritySearchComponent } from './views/security-search/security-search.component';
import { ShuttleComponent } from './views/shuttle/shuttle.component';
import { StewardComponent } from './views/steward/steward.component';
import { ValidateTokenComponent } from './views/validate-token/validate-token.component';

const appRoutes: Routes = [
  { path: 'id_token', redirectTo: '', pathMatch: 'full' },
  { path: 'access_token', redirectTo: '', pathMatch: 'full' },
  { path: 'error', redirectTo: '', pathMatch: 'full' },
  { path: 'code', component: LoginSwitcherComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'validate-token', component: ValidateTokenComponent },
  { path: 'login', component: LoginSwitcherComponent },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'app-version', component: AppVersionComponent },
  {
    path: 'menu',
    component: MenuComponent,
    children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'reservations',
        component: ReservationListComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'reservationForm',
            component: ReservationNewFormComponent,
            outlet: 'modal',
          },
          {
            path: 'reservationNewDetailByRoom',
            component: ReservationNewDetailByRoom,
            outlet: 'modal',
          },
          {
            path: 'reservationSearchByRoomAndPeriod',
            component: ReservationSearchByRoomAndPeriodComponent,
            outlet: 'modal',
          },
          {
            path: 'reservationNewFormByHQ',
            component: ReservationNewDetailByHQ,
            outlet: 'modal',
          },
          {
            path: 'reservationNewSpeed',
            component: ReservationSpeedNewDetail,
            outlet: 'modal',
          },
        ],
      },
      { path: 'availableRooms', component: RoomAvailableComponent },
      {
        path: 'parking',
        component: ParkingComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'parkingOne',
        component: ParkingOneComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'incidence',
        component: IncidenceComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'incidenceForm',
            component: IncidenceNewFormComponent,
            outlet: 'modal',
          },
        ],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          {
            path: 'form',
            component: ProfileFormComponent,
            outlet: 'modal',
          },
          {
            path: 'language',
            component: ModalLanguageComponent,
            outlet: 'modal',
          },
        ],
      },
      {
        path: 'security',
        component: SecuritySearchComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'assignPlace',
            component: AssignPlaceComponent,
            outlet: 'modal',
          },
        ],
      },
      { path: 'descargas', component: DescargasComponent },
      {
        path: 'gym_resources',
        component: GymResourcesComponent,
        children: [
          {
            path: 'listGym',
            component: GymResorucesListComponent,
            children: [
              {
                path: 'reserve',
                component: GymResorucesReserveComponent,
                outlet: 'modal',
              },
            ],
          },
          { path: '**', pathMatch: 'full', redirectTo: 'listGym' },
        ],
      },
      { path: 'room/detail', component: RoomWithoutTime },
      { path: 'room/mosaic', component: RoomWithoutTimeMosaic },
      { path: 'availableSpeedRooms', component: RoomSpeedAvailableComponent },
      {
        path: 'assistance',
        component: AssistanceComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'calendarEditConfirm',
            component: CalendarEditConfirmComponent,
            outlet: 'modal',
          },
          {
            path: 'calendarEditNotConfirm',
            component: CalendarEditNotConfirmComponent,
            outlet: 'modal',
          },
          {
            path: 'calendarEditListUser',
            component: CalendarEditListUsersComponent,
            outlet: 'modal',
          },
        ],
      },
      {
        path: 'assistance-user',
        component: AssistanceUserComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'calendarEditConfirm',
            component: CalendarEditConfirmComponent,
            outlet: 'modal',
          },
          {
            path: 'calendarEditNotConfirm',
            component: CalendarEditNotConfirmComponent,
            outlet: 'modal',
          },
          {
            path: 'calendarEditListUser',
            component: CalendarEditListUsersComponent,
            outlet: 'modal',
          },
        ],
      },
      {
        path: 'assistance-iberdrola',
        component: AssistanceIberdrolaComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'assistance-iberdrola-user',
        component: AssistanceIberdrolaUserComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'cantine',
        component: CantineComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'steward',
        component: StewardComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'recursos',
        component: ResourceComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'list',
            component: ListComponent,
            children: [
              {
                path: 'reserve',
                component: ReserveComponent,
                outlet: 'modal',
              },
            ],
          },
          { path: '**', pathMatch: 'full', redirectTo: 'list' },
        ],
      },
      {
        path: 'carWash',
        component: CarWashComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'list',
            component: CarWashListComponent,
            children: [
              {
                path: 'reserve',
                component: CarWashReserveComponent,
                outlet: 'modal',
              },
            ],
          },
          { path: '**', pathMatch: 'full', redirectTo: 'list' },
        ],
      },
      {
        path: 'questionsAndAnswers',
        component: QuestionsAndAnswersComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'list',
            component: QuestionAndAnswersListComponent,
          },
          {
            path: 'new/:id',
            component: QuestionAndAnswersNewComponent,
          },
          {
            path: 'type',
            component: QuestionAndAnswersTypeComponent,
          },
          {
            path: 'result/:id',
            component: QuestionAndAnswersResultComponent,
          },
          { path: '**', pathMatch: 'full', redirectTo: 'list' },
        ],
      },
      { path: 'dorlet', component: DorletComponent, canActivate: [MenuGuard] },
      {
        path: 'occupationParking',
        component: ParkingOccupationMenuComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'shuttle',
        component: ShuttleComponent,
        canActivate: [MenuGuard],
      },
      {
        path: 'job-position',
        component: JobPositionComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'panel',
            component: ListOfPositionsComponent,
            children: [
              {
                path: 'searchUserPosition',
                component: SearchUserPositionComponent,
                outlet: 'modal',
              },
              {
                path: 'multipleReservation',
                component: MultipleReservationComponent,
                outlet: 'modal',
              },
              {
                path: 'reservationByPosition',
                component: ReservationByPositionComponent,
                outlet: 'modal',
              },
              {
                path: 'checkInWithCode',
                component: CheckInWithCodeComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'select-position/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'view-position/:id/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'viewMultiple-position/:idList/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'selectView-position/:id/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'edit-position/:idReservation/:idDesk/:date/:areaId/:token',
            component: ReservationDeskComponent,
          },
          {
            path: 'available-positions/:dateFrom/:dateTo/:hotdeskNumber/:hourNumber/:usersList',
            component: AvailablePositionsComponent,
          },
          {
            path: 'selectMultiple-position/:areaId/:dateFrom/:dateTo/:hourNumber/:hourFrom/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'user-positions/:date/:userId',
            component: UserPositionsComponent,
          },
          { path: '**', pathMatch: 'full', redirectTo: 'panel' },
        ],
      },
      {
        path: 'dinning-position',
        component: DinningPositionComponent,
        canActivate: [MenuGuard],
        children: [
          {
            path: 'panel',
            component: ListOfPositionsComponent,
            children: [
              {
                path: 'searchUserPosition',
                component: SearchUserPositionComponent,
                outlet: 'modal',
              },
              {
                path: 'multipleReservation',
                component: MultipleReservationComponent,
                outlet: 'modal',
              },
              {
                path: 'reservationByPosition',
                component: ReservationByPositionComponent,
                outlet: 'modal',
              },
            ],
          },
          {
            path: 'select-position/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'view-position/:id/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'viewMultiple-position/:idList/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'selectView-position/:id/:areaId/:date/:token',
            component: BoxOfficeComponent,
          },
          {
            path: 'edit-position/:idReservation/:idDesk/:date/:areaId/:token',
            component: ReservationDeskComponent,
          },
          {
            path: 'user-positions/:date/:userId',
            component: UserPositionsComponent,
          },
          {
            path: 'available-positions/:dateFrom/:dateTo/:hotdeskNumber/:hourNumber/:usersList',
            component: AvailablePositionsComponent,
          },
          { path: '**', pathMatch: 'full', redirectTo: 'panel' },
        ],
      },
      {
        path: 'external-module',
        component: ExternalModuleComponent,
        // canActivate: [MenuGuard], TODO: Mirar esta guarda
        children: [
          { path: '', pathMatch: 'full', redirectTo: '' },
          {
            path: 'customExternalModule/:moduleId',
            component: CustomExternalModuleComponent,
          },
          { path: '**', pathMatch: 'full', redirectTo: '' },
        ],
      },
    ],
  },
  {
    path: 'resource-reserve/:idResourceType/:date/:openingTime/:endingTime/:timeInterval/:language/:token',
    component: ReserveMobileComponent,
  },
  {
    path: 'resource-edit-reserve/:id/:idResourceForHour/:idResourceType/:date/:openingTime/:endingTime/:language/:token',
    component: ReserveMobileComponent,
  },
  {
    path: 'carwash-reserve/:carwashSizeId/:license/:date/:openingTime/:endingTime/:timeInterval/:language/:token',
    component: CarWashReserveMobileComponent,
  },
  {
    path: 'carwash-edit-reserve/:carwashId/:carwashSizeId/:license/:date/:openingTime/:endingTime/:language/:token',
    component: CarWashReserveMobileComponent,
  },
  {
    path: 'multi-BoxOffice/:areaId/:dateFrom/:dateTo/:hourNumber/:hourFrom/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'boxOffice/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'boxOffice-dinner/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'readerBoxOffice/:id/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'readerBoxOffice-dinner/:id/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'viewMultipleBoxOffice/:idList/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'viewMultipleBoxOffice-dinner/:idList/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'viewSelectBoxOffice/:id/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'viewSelectBoxOffice-dinner/:id/:areaId/:date/:language/:token',
    component: BoxOfficeComponent,
  },
  {
    path: 'reservationDesk/:id/:date/:areaId/:language/:token',
    component: ReservationDeskComponent,
  },
  {
    path: 'reservationDesk-dinner/:id/:date/:areaId/:language/:token',
    component: ReservationDeskComponent,
  },
  {
    path: 'reservationDeskEdit/:idReservation/:idDesk/:date/:areaId/:language/:token',
    component: ReservationDeskComponent,
  },
  {
    path: 'reservationDeskEdit-dinner/:idReservation/:idDesk/:date/:areaId/:language/:token',
    component: ReservationDeskComponent,
  },
  { path: 'dinning-occupation', component: DinningOccupationComponent },
  {
    path: 'dinning-occupation/:token/:language',
    component: DinningOccupationComponent,
  },
  {
    path: 'dinning-occupation-headquarter/:headquarterId',
    component: DinningOccupationComponent,
  },
  {
    path: 'dinning-occupation-headquarter/:headquarterId/:language',
    component: DinningOccupationComponent,
  },
  { path: 'parking-occupation/:token', component: ParkingOccupationComponent },
  { path: 'parking-occupation', component: ParkingOccupationComponent },
  { path: 'lopd/:language', component: LopdComponent },
  {
    path: `${COMPONENT_OAUTH_PATH}/:token/:expire_in/:refresh`,
    component: OauthComponent,
  },
  {
    path: 'customExternalModule/:moduleId',
    component: CustomExternalModuleComponent,
  },
];

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'corrected' });
