import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DeskAttribute } from '../../core/models/deskAttribute';
import { Desks } from './../../core/models/desks';
import { ModalReservationService } from '../../shared/components/modal-reservation/modal-reservation.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { BoxOfficeService } from '../../shared/services/box-office.service';
import * as panzoom from 'panzoom';
import * as Hammer from 'hammerjs';
import { environment } from '../../../environments/environment';
import { ModalReservationConfirmationService } from '../../shared/components/modal-reservation-confirmation/modal-reservation-confirmation.service';

@Component({
  selector: 'app-box-office',
  templateUrl: './box-office.component.html',
  styleUrls: ['./box-office.component.scss'],
})
export class BoxOfficeComponent implements OnInit {
  desks: Desks;
  token: string;
  areaId: number;
  listAttributes: DeskAttribute[];
  id: number = null;
  idList: Array<number> = null;
  date: number;
  readerDesk: boolean = false;
  viewSelect = false;
  viewMulti = false;
  timeOutApi: any;
  zoomScale = 0.3;
  minScale: number;
  onlyRead = false;
  language: string;

  // Attributes passed by messagesPipe
  box_title_legend: string;
  box_title: string;
  box_free: string;
  box_partially_occupied: string;
  box_occupied: string;
  box_disabled: string;
  box_yourDesk: string;
  box_userSelectDesk: string;
  box_position_available: string;

  selectOptionMenu: string;
  url: String;

  selectedDesks: any[] = [];
  userList: any[] = [];
  dateFrom: number;
  dateTo: number;
  hourNumber: number;
  hourFrom: number;

  constructor(
    private modalReservationService: ModalReservationService,
    private modalReservationConfirmationService: ModalReservationConfirmationService,
    private boxOfficeService: BoxOfficeService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    document.head.innerHTML =
      document.head.innerHTML +
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">';
    this.url = this.route.snapshot['_routerState'].url as String;
    if (
      this.url.includes('/dinning-position') ||
      this.url.includes('-dinner')
    ) {
      this.selectOptionMenu = 'dinner';
    } else {
      this.selectOptionMenu = 'desks';
    }
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    this.route.params.forEach((params: Params) => {
      const url = this.route.snapshot['_routerState'].url as String;

      // Language modification for the mobility part
      let message = new MessagesPipe();
      this.language = params['language'];
      if (this.language) {
        message.setLanguage(this.language);
      }
      this.box_title_legend = message.transform('box_title_legend');
      this.box_title = message.transform('box_title');
      this.box_free = message.transform('box_free');
      this.box_partially_occupied = message.transform('box_partially_occupied');
      this.box_occupied = message.transform('box_occupied');
      this.box_disabled = message.transform('box_disabled');
      this.box_yourDesk = message.transform('box_yourDesk');
      this.box_userSelectDesk = message.transform('box_userSelectDesk');
      this.box_position_available = message.transform('box_position_available');

      if (
        url.includes('/readerBoxOffice/') ||
        url.includes('/view-position/') ||
        url.includes('/readerBoxOffice-dinner/')
      ) {
        this.readerDesk = true;
        if (
          params['id'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.id = params['id'];
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
          this.onlyRead = true;
        }
        this.centerScrollDesk();
      } else if (
        url.includes('/selectView-position/') ||
        url.includes('/viewSelectBoxOffice/') ||
        url.includes('/viewSelectBoxOffice-dinner/')
      ) {
        this.viewSelect = true;
        if (
          params['id'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.id = params['id'];
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
        }
      } else if (
        url.includes('/viewMultiple-position/') ||
        url.includes('/viewMultipleBoxOffice/') ||
        url.includes('/viewMultipleBoxOffice-dinner/')
      ) {
        this.viewMulti = true;
        if (
          params['idList'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.idList = params['idList'].split(',');
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
          this.onlyRead = true;
        }
      } else if(
          url.includes('/selectMultiple-position/') ||
          url.includes('/multi-BoxOffice/')
        ){
        this.areaId = params['areaId'];
        this.dateFrom = params['dateFrom'];
        this.dateTo = params['dateTo'];
        this.hourNumber = params['hourNumber'];
        this.hourFrom = params['hourFrom'];
        this.token = params['token'];
      }else {
        this.readerDesk = false;
        if (
          !params['id'] &&
          params['areaId'] &&
          params['date'] &&
          params['token']
        ) {
          this.areaId = params['areaId'];
          this.date = params['date'];
          this.token = params['token'];
          this.id = null;
        }
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params?.atr) {
        if(typeof params.atr === 'string'){
          let atr = JSON.parse(params.atr);
          this.listAttributes = [...atr];
        } else {
          this.listAttributes = [...params.atr];
        }
      }
      if(params?.user){
        if(typeof params.user === 'string'){
          this.userList = [params.user]
        } else {
          this.userList = [...params.user];
        }
      }
    });

    if(this.url.includes('/selectMultiple-position/') ||
      this.url.includes('/multi-BoxOffice/')){
      this.getSearchMultiDesk();
    } else {
      this.getSearchHostDesck(
        this.areaId,
        this.date,
        this.token,
        this.listAttributes
      );
    }
  }

  getSearchHostDesck(
    areaId: number,
    date: number,
    token: string,
    listAttributes?: DeskAttribute[]
  ) {
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    let subsBoxOffice = this.boxOfficeService
      .searchHostDesck(
        areaId,
        date,
        token,
        this.selectOptionMenu,
        listAttributes
      )
      .subscribe(
        (res: Desks) => {
          if (res) {
            this.desks = res;
            this.spinnerService.hide();
            clearTimeout(this.timeOutApi);
            setTimeout(() => {
              this.centerScrollDesk();
              this.zoomInit();
            }, 0);
          }
        },
        (error) => {
          if (error && error.code) {
            this.dipastchEventError(error.code);
            clearTimeout(this.timeOutApi);
          }
        }
      );
    this.timeOutApi = setTimeout(() => {
      subsBoxOffice.unsubscribe();
      this.dipastchEventError(408);
    }, 10000);
  }

  getSearchMultiDesk(){
    setTimeout(() => {
      this.spinnerService.show();
    }, 0);
    let subsMultiSearchBoxOffice = this.boxOfficeService.getSearchMultiDesk(
      this.areaId,
      this.dateFrom,
      this.dateTo,
      this.userList.length,
      this.hourNumber,
      this.hourFrom,
      this.userList,
      this.token,
      this.selectOptionMenu
    ).subscribe(
      (response: any) => {
        this.desks = response;
        this.spinnerService.hide();
        clearTimeout(this.timeOutApi);
        setTimeout(() => {
          this.centerScrollDesk();
          this.zoomInit();
        }, 0);
      },
      (error) => {
        if (error && error.code) {
          this.dipastchEventError(error.code);
          clearTimeout(this.timeOutApi);
        }
      }
    );
    this.timeOutApi = setTimeout(() => {
      subsMultiSearchBoxOffice.unsubscribe();
      this.dipastchEventError(408);
    }, 10000);
  }

  multideskSelected(event: any, item: any){
    if(item.status === 4){
      return;
    }
    if(this.selectedDesks.includes(item.id)){
      this.selectedDesks = this.selectedDesks.filter(x => x !== item.id);
    } else {
      if(this.selectedDesks.length < this.userList.length){
        this.selectedDesks.push(item.id);
      }
    }
  }

  openReservationDeskId(item) {
    const url = this.route.snapshot['_routerState'].url as String;
    if (
      item &&
      item.id &&
      item.status &&
      item.status != 1 &&
      !this.id &&
      !url.includes('/viewMultiple-position/') &&
      !url.includes('/viewMultipleBoxOffice/') &&
      !url.includes('/viewMultipleBoxOffice-dinner/')
    ) {
      this.modalReservationService.openModal(
        item.id,
        this.date,
        this.areaId,
        this.token
      );
      this.modalReservationService.setLanguage(this.language);
    }
    if (
      item &&
      item.id &&
      item.status &&
      item.status != 1 &&
      (url.includes('/selectView-position/') ||
        url.includes('/viewSelectBoxOffice/') ||
        url.includes('/viewSelectBoxOffice-dinner/'))
    ) {
      this.modalReservationService.openModal(
        item.id,
        this.date,
        this.areaId,
        this.token
      );
      this.modalReservationService.setLanguage(this.language);
    }
  }

  centerScrollDesk() {
    if (this.desks && this.desks.hotdesks && this.readerDesk) {
      this.desks.hotdesks.forEach((x) => {
        if (x.id == this.id) {
          setTimeout(() => {
            document.getElementById('map').scrollTop =
              document.getElementById(x.id.toString()).offsetTop *
                this.zoomScale -
              (screen.height / 2 + 40) * this.zoomScale;
            document.getElementById('map').scrollLeft =
              document.getElementById(x.id.toString()).offsetLeft *
                this.zoomScale -
              (screen.width / 2 + 40) * this.zoomScale;
          }, 300);
        }
      });
    }
  }

  dipastchEventError(codeError: number) {
    var ua = navigator.userAgent;
    var checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/),
    };
    if (checker.android) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      deskReserve.errorReserve(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    } else if (checker.iphone) {
      var x = document.createElement('script');
      var t = document.createTextNode(`
      window.webkit.messageHandlers.errorReserve.postMessage(${codeError});
      `);
      x.appendChild(t);
      document.body.appendChild(x);
    }
  }

  waitForMapLoad(){
    return new Promise<void>(resolve => {
      const checkMapImgLoad = () => {
        let imgElement = document.getElementById('mapImg');
        
        if(imgElement && imgElement.offsetHeight > 0){
          resolve();
        } else {
          setTimeout(checkMapImgLoad, 100);
        }
      }
      checkMapImgLoad();
    })
  }

  zoomInit() {
    this.waitForMapLoad().then(() => {
      if (document.getElementById('scroll')) {
        let elementScroll = document.getElementById('scroll');
        let containerHeight = elementScroll.parentElement.offsetHeight;
        let imageHeight = elementScroll.offsetHeight;
        this.zoomScale = containerHeight / imageHeight;
        this.minScale = this.zoomScale;
        elementScroll.style.transform = `scale(${this.zoomScale})`;
        if(this.usePinchZoom){
          this.controlPanZoom();
          elementScroll.parentElement.style.overflow = 'hidden';
        }
      }
    });
  }

  zoomIn() {
    if (this.zoomScale <= this.minScale + 1) {
      this.zoomScale = this.zoomScale + 0.05;
      let mapElement = document.getElementById(
        'scroll'
      );

      if(mapElement.style.transform.includes('translate')){
        let mapElementTransform = mapElement.style.transform.replace(/scale\((.*?)\)/g, '');
        mapElement.style.transform = `scale(${this.zoomScale})` + mapElementTransform;
      } else {
        mapElement.style.transform = `scale(${this.zoomScale})`;
      }
    }
  }

  zoomOut() {
    if (this.zoomScale > this.minScale) {
      this.zoomScale = this.zoomScale - 0.05;
      let mapElement = document.getElementById(
        'scroll'
      );

      if(mapElement.style.transform.includes('translate')){
        let mapElementTransform = mapElement.style.transform.replace(/scale\((.*?)\)/g, '');
        mapElement.style.transform = `scale(${this.zoomScale})` + mapElementTransform;
      } else {
        mapElement.style.transform = `scale(${this.zoomScale})`;
      }
    }
  }

  changeReserve() {
    this.getSearchHostDesck(this.areaId, this.date, this.token);
  }

  compareIdsItem(item: any): string {
    let ret = '';
    let found = false;
    this.idList.forEach((element) => {
      if (!found) {
        if (element === item.id.toString()) {
          ret = `{ active }`;
          found = true;
        } else if (item.status === 1) {
          ret = `{ disabled }`;
        } else if (item.status === 2) {
          ret = `{ free }`;
        } else if (item.status === 3) {
          ret = `{ partiallyOccupied }`;
        } else if (item.status === 4) {
          ret = `{ occupied }`;
        }
      }
    });
    return ret;
  }

  compareIdsItemForId(item: any): boolean {
    let ret = false;
    let found = false;
    this.idList.forEach((element) => {
      if (!found) {
        if (element === item.id.toString()) {
          ret = true;
          found = true;
        }
      }
    });
    return ret;
  }

  // Function that controls the pan and zoom of the map
  controlPanZoom(){
    // Config of panzoom 
    const instance = panzoom.default(document.getElementById('scroll'), {
      minZoom: this.minScale,
      maxZoom: this.minScale + 1,
      smoothScroll: false,
    });
    // First Zoom to the minScale
    instance.zoomAbs(0, 0, this.minScale);

    // Only execute this when the user is on a touch device
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      // Detect the tap event on the elements of the map
      const hammer = new Hammer(document.getElementById('scroll'));
      hammer.on('tap', (e) => {
        if(
          e.target.tagName === 'SPAN'
        ) {
          let parentElement = e.target.parentElement;
          if(
            parentElement.tagName === 'DIV' &&
            parentElement.classList.contains('table')
          ) {
            e.target.click();
          }
        } else if (
          e.target.tagName === 'DIV' && 
          e.target.classList.contains('table')
        ) {
          e.target.click();
        }
      });
    }
  }

  get usePinchZoom(): boolean {
    return environment['usePinchZoom'];
  }

  openConfirmationMultiDesk(){
    const url = this.route.snapshot['_routerState'].url as String;
    if(url.includes('/selectMultiple-position/') ||
      url.includes('/multi-BoxOffice/')){
      this.modalReservationConfirmationService.openModalMultiReservation(
        this.dateFrom,
        this.dateTo,
        this.selectedDesks,
        this.userList,
        this.token,
        this.selectOptionMenu,
        this.hourFrom,
        this.hourNumber
      );
      this.modalReservationConfirmationService.setLanguage(this.language);
    }
  }
}
