<div class="new-reserve">
    <!-- Spinner -->
    <app-spinner></app-spinner>

    <form class="row mui-form" [formGroup]="reservationGymForm">
        <!-- DatePicker -->
        <div class="form-group select-group col-sm-12 mui-textfield">
            <label for="fecha y hora">{{ 'gym_resources_reserve_date' | messagesPipe }}</label>
            <datepicker class="custom-datepicker" #datePickerElement id="fecha" [options]="datepickerOptions"
                [locale]="locale" (dateChanged)="onDateChanged($event)"></datepicker>
        </div>
        <!-- <div class="d-flex flex-row mt-4 mb-4"> -->
        <div class="form-group select-group col-sm-12 mt-5 mb-5">
            <div style="max-height:1em !important;" class="mui-select">
                <label for="hourFrom">{{'gym_resources_reserve_date_hour' | messagesPipe}}</label>
                <select formControlName="dateFrom" class="form-control" id="hourFrom" name="hourFrom"
                    (change)="onChangeDateFrom($event)">
                    <option [value]="-1">
                        {{ 'select_hours' | messagesPipe }}
                      </option>
                    <option *ngFor="let hourFrom of DateFrom">
                        {{hourFrom}}
                    </option>
                </select>
            </div>
        </div>
        <!-- <div class="form-group select-group col-sm-12">
                <div class="mui-select">
                    <label for="hourTo">{{'gym_resources_reserve_date_hour_end' | messagesPipe}}</label>
                    <select formControlName="dateTo" class="form-control" id="hourTo" name="hourTo"
                        (change)="onChangeDateTo($event)">
                        <option *ngFor="let hourTo of DateTo">
                            {{hourTo}}
                        </option>
                    </select>

                </div>
            </div> -->
        <!-- </div> -->

        <div class="last-textfield form-group col-sm-12 mui-textfield">
            <label>{{
                'search_users_add' | messagesPipe
                }}</label>
            <div class="w-75" style="margin-top: 10px;">
                <ng-autocomplete #usersAutocompleteComponent [data]="users" [searchKeyword]="usersKeyword"
                    (selected)="onUserSelected($event)" (inputChanged)="onSearchChanged($event)"
                    [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [debounceTime]="600"
                    [isLoading]="isLoadingUsers" [minQueryLength]="2"
                    placeholder="{{'search_members_gym' | messagesPipe }}">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                    <a href="javascript:void(0)" class="invitation-users-item">
                        <span class="invitation-users-info">
                            <span [innerHTML]="item.userName"></span>
                            <span class="invitation-users-email" *ngIf="item.email != null && item.email.length > 0">
                                ({{ item.email }})</span>
                        </span>
                    </a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                    <div>{{ 'invitation_user_not_found' | messagesPipe }}</div>
                </ng-template>
            </div>
        </div>
    </form>
</div>
<div class="mt-5">
    <div class="">
        <p-chart type="horizontalBar" height="300" [data]="basicData" [options]="horizontalOptions"></p-chart>
    </div>
</div>
<div class="mt-2 mb-5">
    <button (click)="ReservationsAdd()" type="submit" class="check-availability button-by" [disabled]="isFormValid()">
        {{ 'reservation_companions_button' | messagesPipe }}
    </button>
</div>