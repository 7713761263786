<div class="new-reserve">
  <!-- Spinner -->
  <app-spinner></app-spinner>

  <form class="row mui-form" [formGroup]="reservationCarWashForm"
    (ngSubmit)="dataEdit ? checkHourReserveEdit() : checkHourReserve()" novalidate>
    <div class="select-by form-group select-group col-sm-12">
      <div class="mui-select">
        <label for="selectParkingSize">{{
          'carWash_reserve_type' | messagesPipe | uppercase
          }}</label>
        <select #selectParkingSize id="selectParkingSize" name="selectParkingSize" class="form-control"
          formControlName="carwashSizeId">
          <option [value]="0" selected>
            {{ 'carWash_reserve_type_select' | messagesPipe }}
          </option>
          <option *ngFor="let parkingSize of listCarWashParkingSizeAll" [value]="parkingSize.id">
            {{ parkingSize.size }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group col-sm-12 mui-textfield mb-4">
      <div class="form-check">
        <input (change)="onChangeCheckBox()" [checked]="flota" [disabled]="editCeco" class="form-check-input"
          type="checkbox" value="" id="flexBox">
        <label class="form-check-label mt-1" for="flexCheckDefault" for="license">{{
          'carWash_reserve_flota' | messagesPipe | uppercase
          }}</label>
      </div>
    </div>
    <form action="" [formGroup]="formCeco">
      <div *ngIf="ceco" class="mb-3">
        <div class="form-group col-sm-12 mui-textfield">
          <label for="license">{{
            'carWash_reserve_ceco' | messagesPipe | uppercase
            }}</label>
          <input type="text" class="form-control" formControlName="ceco" placeholder="{{
              'carWash_reserve_ceco_introduction' | messagesPipe
            }}" />
          <span class="required" [hidden]="formCeco.controls.ceco.valid">{{ 'error_ceco_required' |
            messagesPipe }}</span>
        </div>
      </div>
    </form>

    <div class="form-group col-sm-12 mui-textfield">
      <label for="license">{{
        'carWash_reserve_license' | messagesPipe | uppercase
        }}</label>
      <input type="text" placeholder="{{
          'profile_vehicleEdit_license_placeholder' | messagesPipe
        }}" class="form-control" formControlName="license" />
      <span class="required" [hidden]="reservationCarWashForm.controls.license.valid">{{ 'error_license_required' |
        messagesPipe }}</span>
    </div>
    <!-- DatePicker -->
    <div class="form-group select-group col-sm-12 mui-textfield">
      <label for="fecha">{{ 'carWash_reserve_date' | messagesPipe }}</label>
      <datepicker class="custom-datepicker" #datePickerElement id="fecha" [options]="datepickerOptions"
        [locale]="locale" (dateChanged)="onDateChanged($event)"></datepicker>
    </div>

    <div class="placed-button">
      <button type="submit" class="check-availability button-by"
        [disabled]="reservationCarWashForm.invalid ||formCeco.invalid">
        {{
        dataEdit
        ? ('carWash_reserve_edit_submit' | messagesPipe)
        : ('carWash_reserve_submit' | messagesPipe)
        }}
      </button>
    </div>
  </form>
</div>
<app-modal-reservation-generic (submitReserve)="addCarwashReservation($event)"
  (submitEdit)="editCarwashReservation($event)"></app-modal-reservation-generic>
<app-modal-error-mobile></app-modal-error-mobile>