import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { Subscription } from 'rxjs';
import { STATUS_FORBIDDEN } from 'src/app/core/constants/const';
import { LOGOUT_TIMEOUT } from 'src/app/core/constants/consts';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-search-people',
  templateUrl: './search-people.component.html',
  styleUrls: ['./search-people.component.scss'],
})
export class SearchPeopleComponent implements OnInit {
  messagesPipe = new MessagesPipe();
  @ViewChild('usersAutocompleteComponent')
  usersAutocompleteComponent: AutocompleteComponent;
  currentInvitationsRequestSubscription: Subscription;
  usersKeyword = 'name';
  users: any[] = [];
  selectedUsers: any[] = [];
  selectedUsersId: any[] = [];
  isLoadingUsers = false;
  // Parameter to disable the component
  @Input() disabled?: boolean;
  // This parameter can contain two values 'basic' or 'advanced', it is used to configure what type of filtering we apply
  @Input() serviceType: string;
  @Output() onSelectedUsersChange = new EventEmitter<any[]>();
  @Input() translationKey?: string;
  @Input() placeHolderkey?: string;
  @Input() departmentId?: number;
  @Input() departmentDesc?: string;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit() {
    this.reset();
  }

  onUserSelected(user: any): void {
    if (this.currentInvitationsRequestSubscription) {
      this.currentInvitationsRequestSubscription.unsubscribe();
    }

    this.isLoadingUsers = false;
    this.selectedUsers = this.addUserToSelectedUsers(user);

    this.usersAutocompleteComponent.clear();

    this.clearAndChangeAutoComplete(this.departmentId, this.departmentDesc);
  }

  onSearchChanged(
    searchText: string,
    departmentId?: number,
    departmentDesc?: string
  ): void {
    this.departmentId = departmentId ? departmentId : this.departmentId;
    this.departmentDesc = departmentDesc ? departmentDesc : this.departmentDesc;
    let usersList = [];
    this.users = [];
    this.setSelectedUsersId();
    if (this.serviceType == 'basic') {
      this.isLoadingUsers = true;
      this.currentInvitationsRequestSubscription = this.userService
        .listUsersLdapByFilter(
          this.authenticationService.getToken(),
          searchText
        )
        .subscribe(
          (response) => {
            if (response) {
              this.users = response.filter((user) => {
                return user.email && user.email.length > 0;
              });
            }
          },
          (error) => {
            this.handleNetworkError(
              error,
              'invitations_users_search_error',
              () => {
                this.onSearchChanged(searchText);
              }
            );
          },
          () => {
            this.isLoadingUsers = false;
          }
        );
    } else if (this.serviceType == 'byDepartment') {
      this.isLoadingUsers = true;

      usersList = this.addDept(searchText);

      this.currentInvitationsRequestSubscription = this.userService
        .searchUserByDepartment(
          this.authenticationService.getToken(),
          searchText,
          this.departmentId ? this.departmentId : null
        )
        .subscribe(
          (response) => {
            if (response) {
              response.forEach((user) => {
                if (user) {
                  usersList.push(user);
                }
              });

              // Remove the selected users
              if (this.selectedUsersId && this.selectedUsersId.length > 0) {
                for (let i = 0; i < usersList.length; i++) {
                  if (this.selectedUsersId.includes(usersList[i].id)) {
                    usersList.splice(i, 1);
                    i--;
                  }
                }
              }
              
              this.users = usersList;
            }
          },
          (error) => {
            this.handleNetworkError(
              error,
              'invitations_users_search_error',
              () => {
                this.onSearchChanged(searchText);
              }
            );
          },
          () => {
            this.isLoadingUsers = false;
          }
        );
    } else {
      this.usersKeyword = 'texto';
      this.isLoadingUsers = true;
      this.currentInvitationsRequestSubscription = this.userService
        .searchByUserAndDepartment(
          this.authenticationService.getToken(),
          searchText
        )
        .subscribe(
          (response) => {
            if (response) {
              response.map((obj) => {
                obj.email = obj.texto;
                obj.userName = new Date().getTime().toString();
                obj.name =
                  obj.tipo == 1
                    ? this.messagesPipe.transform('user')
                    : this.messagesPipe.transform('profile_department');
              });

              this.users = response.filter((user) => {
                return user.texto && user.texto.length > 0;
              });
            }
          },
          (error) => {
            this.handleNetworkError(
              error,
              'invitations_users_department_search_error',
              () => {
                this.onSearchChanged(searchText);
              }
            );
          },
          () => {
            this.isLoadingUsers = false;
          }
        );
    }
  }

  reset(): void {
    this.users = [];
    this.selectedUsers = [];
    this.isLoadingUsers = false;
  }

  removeUser(user: any): void {
    this.selectedUsers = this.selectedUsers.filter((u) => {
      return u.userName.toLowerCase() !== user.userName.toLowerCase();
    });
    this.onSelectedUsersChange.emit(this.selectedUsers);
    this.clearAndChangeAutoComplete(this.departmentId, this.departmentDesc);
  }

  get getSelectedUsers(): any[] {
    return this.selectedUsers;
  }

  private addUserToSelectedUsers(user: any): any[] {
    const filteredUsers = this.selectedUsers.filter((u) => {
      return u.userName.toLowerCase() !== user.userName.toLowerCase();
    });

    // Get the department users
    if (user.id < 0) {
      this.setSelectedUsersId();
      let onlyDept = false;
      this.currentInvitationsRequestSubscription = this.userService
        .searchUserByDepartment(
          this.authenticationService.getToken(),
          '',
          user.userName
        )
        .subscribe(
          (response) => {
            if (response) {
              response.forEach((user) => {
                if (response) {
                  // Filter selected users
                  if (filteredUsers && filteredUsers.length > 0 && !onlyDept) {
                    if (
                      this.selectedUsersId &&
                      this.selectedUsersId.length > 0
                    ) {
                      if (!this.selectedUsersId.includes(user.id)) {
                        filteredUsers.push(user);
                      }
                    }
                  } else {
                    filteredUsers.push(user);
                    onlyDept = true;
                  }
                }
              });
              this.clearAndChangeAutoComplete(
                this.departmentId,
                this.departmentDesc
              );
            }
          },
          () => {
            this.isLoadingUsers = false;
          }
        );
    } else {
      filteredUsers.push(user);
    }

    this.onSelectedUsersChange.emit(filteredUsers);

    return filteredUsers;
  }

  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            () => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }

  clearAndChangeAutoComplete(dept?: number, descDept?: string) {
    this.departmentId = dept;
    this.departmentDesc = descDept;
    if (this.serviceType == 'byDepartment') {
      if (this.departmentId) {
        this.users = this.addDept('');
      } else {
        this.users = [];
      }
    } else {
      this.usersAutocompleteComponent.clear();
      this.usersAutocompleteComponent.close();
      this.users = [];
    }
  }

  setSelectedUsersId() {
    this.selectedUsersId = [];
    this.selectedUsers.forEach((element) => {
      this.selectedUsersId.push(element.id);
    });
  }

  addDept(searchText: string) {
    const usersList = []
    const userObj = {
      email: '',
      id: null,
      name: '',
      texto: '',
      tipo: null,
      userName: '',
    };
    if (this.departmentId) {
      // Create object to show department
      userObj.email =
        this.messagesPipe.transform('all_user_dept') + this.departmentDesc;
      userObj.id = -1;
      userObj.name = searchText;
      userObj.texto = '';
      userObj.tipo = -1;
      userObj.userName = this.departmentId.toString();
      usersList.push(userObj);
    }

    return usersList;
  }

  // Necessary for the correct functioning of angular-ng-autocomplete
  // Check which users from the list received from backend match in name, 
  // email or username with what the user is looking for.
  customFilter(users: any[], query:string){
    // Replace accents
    query = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let filteredUsers = [];

    users.forEach((user) => {
      if(user.email && user.email.toLowerCase().includes(query.toLowerCase()) || 
        user.userName && user.userName.toLowerCase().includes(query.toLowerCase()) || 
        user.name && user.name.toLowerCase().includes(query.toLowerCase())){
        filteredUsers.push(user);
      }
    });
    return filteredUsers;
  }
}
