import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AVAILABLE_HOURS, AVAILABLE_MINUTES, LOGOUT_TIMEOUT } from 'src/app/core/constants/consts';
import { ParkingSizeModel } from '../../../core/models/parking.size';
import { ReservationCarwashWs } from '../../../core/models/reservation.carwash.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { ModalReservationGenericService } from '../../../shared/components/modal-reservation-generic/modal-reservation-generic.service';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { CarWashService } from '../../../shared/services/car-wash.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { Utils } from '../../../shared/utils/utils';
import { SideModalService } from '../../side-modal/side-modal.service';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { Subscription } from 'rxjs';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { STATUS_FORBIDDEN } from 'src/app/core/constants/const';
import { UserService } from 'src/app/shared/services/user.service';
import { GymService } from 'src/app/shared/services/gym.service';
import { gymConfiguaration, OcupationForHour, resourceForHourReservationAddRequest, resourceForHourReservationCancelRequest } from 'src/app/core/models/gym.class';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-gym-resoruces-reserve',
  templateUrl: './gym-resoruces-reserve.component.html',
  styleUrls: ['./gym-resoruces-reserve.component.scss']
})
export class GymResorucesReserveComponent implements OnInit {
  
  @ViewChild('datePickerElement') datePickerElement: MyDatePicker;
  @ViewChild('selectHourFrom') selectHourFrom: any;
  @ViewChild('selectMinuteFrom') selectMinuteFrom: any;
  @ViewChild('selectHourTo') selectHourTo: any;
  @ViewChild('selectMinuteTo') selectMinuteTo: any;

  dataEdit: ReservationCarwashWs;

  listCarWashParkingSizeAll: ParkingSizeModel[];
  reservationGymForm: FormGroup;
  datepickerOptions: any = {};
  locale: string;
  messagesPipe = new MessagesPipe();
  data: any;
  reservationDateFrom: string;
  reservationDateTo: string;
  DateFrom: Array<string>;
  saveDateFrom: string;
  validationDatePicker: boolean = true;
  usersKeyword = 'userNameEmail';
  users: any[] = [];
  currentInvitationsRequestSubscription: Subscription;
  selectedUsers: any[] = [];
  isLoadingUsers = false;
  userNotRegistered = '';
  invalidEmail = true;

  usersAutocompleteComponent: AutocompleteComponent;

  resourceForHourReservationAddRequests: resourceForHourReservationAddRequest = {
    dateFrom: new Date(),
    dateTo: new Date(),
    license: '',
    resourceTypeForHourId: 0,
    users: []
  };




  constructor(
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private userService: UserService,
    private gymService: GymService

  ) {
  }

  basicData: any;
  horizontalOptions: any;

  gymConfiguaration: gymConfiguaration;

  ngOnInit(): void {
    this.getGymConfiguaration();
    this.reset();
    this.createForm();
    this.horizontalOptions = {
      // plugins: {
      //   datalabels: {
      //     align: 'end',
      //     anchor: 'end',
      //     borderRadius: 4,
      //     backgroundColor: 'teal',
      //     color: 'white',
      //     font: {
      //       weight: 'bold'
      //     }
      //   }
      // },

      // title: {
      //   display: true,
      //   text: 'ocupación actual del gimnasio para la fecha: ',
      //   fontSize: 12,
      //   fontColor: 'black',
      // },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        labels: {
          fontColor: 'black'
        }
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: 'black',
            stepSize: 1,
            beginAtZero: true,
            max: null
          },
          gridLines: {
            color: '#ebedef'
          }
        }],
        yAxes: [{
          ticks: {
            fontColor: 'black',
            stepSize: 1,
            beginAtZero: true,
          },
          gridLines: {
            color: '#ebedef'
          }
        }]
      }
    };
    this.datepickerOptions = this.utils.getDatepickerOptionsOnlyFrom();
    this.locale = localStorage.getItem('language_app')
      ? localStorage.getItem('language_app')
      : navigator.language;
  }

  getGymConfiguaration() {
    this.gymService.gymConfiguaration().
      subscribe(
        {
          next: res => {
            this.gymConfiguaration = res;
            let newFormatDate = this.datePickerElement.selectionDayTxt.split("-");
            let reversedFormatDate = newFormatDate.reverse();
            let FormatDate = reversedFormatDate.join('-');
            this.resourceForHourReservationAddRequests.resourceTypeForHourId = this.gymConfiguaration.resourceTypeForHourId;

            this.gymService.gymOccupationForHour(this.gymConfiguaration.resourceTypeForHourCode,
              FormatDate)
              .subscribe(
                {
                  next: res => {
                    let OcupationForHour: OcupationForHour;
                    this.DateFrom = res.labels
                    OcupationForHour = res;
                    this.basicData = {
                      labels: OcupationForHour.labels,
                      datasets: [
                        {
                          label: 'Ocupación actual del gimnasio para la fecha: ' +
                            this.datePickerElement.selectionDayTxt,
                          backgroundColor: 'red',
                          data: OcupationForHour.values,
                        },
                      ]
                    };
                    this.horizontalOptions.scales.xAxes[0].ticks.max = this.gymConfiguaration.resourceNumber;
                  },
                  error: err => console.log(err)
                }
              )
          },
          error: err => console.log(err)
        }
      )
  }

  ReservationsAdd() {
    let newFormatDate = this.datePickerElement.selectionDayTxt.split("-");
    let reversedFormatDate = newFormatDate.reverse();
    let FormatDate = reversedFormatDate.join('-');
    let Datefrom = + new Date(`${FormatDate} ${this.saveDateFrom}`);

    let Sumatorio = parseInt("01:00")
    let parseDateFrom = parseInt(this.saveDateFrom);
    let sumDateTo = parseDateFrom + Sumatorio;
    let parseDateTo = sumDateTo.toString();

    if (parseDateTo.length === 1) {
      parseDateTo = (`0${parseDateTo}:00`);
    }
    else {
      parseDateTo = (`${parseDateTo}:00`);
    }

    let DateTo = + new Date(`${FormatDate} ${parseDateTo}`);

    this.resourceForHourReservationAddRequests.dateFrom = Datefrom;
    this.resourceForHourReservationAddRequests.dateTo = DateTo;

    this.gymService.ReservationsAdd(this.resourceForHourReservationAddRequests).subscribe({
      next: res => {
        this.getGymConfiguaration();
      },
      error: err => this.flashMessagesService.show(
        this.messagesPipe.transform('error_reservation_gym_resources'),
        { cssClass: 'alert-danger', timeout: 3000 }
      )
    })

  }

  onUserSelected(user: any): void {
    if (this.currentInvitationsRequestSubscription) {
      this.currentInvitationsRequestSubscription.unsubscribe();
    }

    this.isLoadingUsers = false;
    this.selectedUsers = this.addUserToSelectedUsers(user);

    if(this.usersAutocompleteComponent) {
      this.usersAutocompleteComponent.clear();
    }
    // this.usersAutocompleteComponent.close();
  }

  onSearchChanged(searchText: string): void {
    this.isLoadingUsers = true;
    this.currentInvitationsRequestSubscription = this.userService
      .listUsersLdapByFilter(this.authenticationService.getToken(), searchText)
      .subscribe(
        (response) => {
          if (response) {
            this.users = response.filter((user) => {
              return user.email && user.email.length > 0;
            });
            this.users.forEach(item => item.userNameEmail = item.userName + " " + item.email);
          } else {
            this.users = [];
          }
        },
        (error) => {
          this.handleNetworkError(
            error,
            'invitations_users_search_error',
            () => {
              this.onSearchChanged(searchText);
            }
          );
        },
        () => {
          this.isLoadingUsers = false;
        }
      );
  }

  onChangeDateFrom($event) {
    this.saveDateFrom = $event.target.value;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.dataEdit) {
        this.utils.setDatePickerDate(new Date(), this.datePickerElement);
      }
      if (this.dataEdit) {
        this.utils.setDatePickerDate(
          new Date(this.dataEdit.dateFrom),
          this.datePickerElement
        );
      }
    }, 0);
  }

  isFormValid(): boolean {
    if (this.saveDateFrom === '-1' ||this.reservationGymForm.invalid || this.validationDatePicker) {
      return true;
    }
    return false;
  }

  createForm() {
    this.reservationGymForm = new FormGroup({
      dateFrom: new FormControl(null, [Validators.required]),
      // dateTo: new FormControl(null, [Validators.required]),
      // date: new FormControl(null, [Validators.required]),
      // : new FormControl(null, [Validators.required]),
    });
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.getGymConfiguaration();
      this.validationDatePicker = false;
    }
  }

  private reset(): void {
    this.users = [];
    this.selectedUsers = [];
    this.isLoadingUsers = false;
  }

  private addUserToSelectedUsers(user: any): any[] {
    const filteredUsers = this.selectedUsers.filter((u) => {
      return u.userName.toLowerCase() !== user.userName.toLowerCase();
    });

    filteredUsers.push(user);

    return filteredUsers;
  }


  private handleNetworkError(
    error: any,
    errorMessage: string,
    callback: Function
  ): void {
    if (error.code === STATUS_FORBIDDEN) {
      this.authenticationService.refreshToken().subscribe(
        (response) => {
          callback();
        },
        () => {
          this.authenticationService.validateSessionId().subscribe(
            (response) => {
              callback();
            },
            () => {
              this.flashMessagesService.grayOut(false);
              this.flashMessagesService.show(
                this.messagesPipe.transform('error_forbidden'),
                { cssClass: 'alert-danger', timeout: 3000 }
              );

              setTimeout(() => {
                this.authenticationService.logout();
              }, LOGOUT_TIMEOUT);
            }
          );
        }
      );
    } else {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        this.messagesPipe.transform(errorMessage),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    }
  }
}

