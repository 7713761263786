import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, take } from 'rxjs/operators';
import {
  ANIMATE_CLASS_ACTIVE,
  ANIMATE_CLASS_INACTIVE,
} from 'src/app/core/constants/consts';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BoxOfficeService } from 'src/app/shared/services/box-office.service';
import { ConfirmService } from 'src/app/shared/services/confirm.service';
import { FlashMessagesService } from 'src/app/shared/services/flash-messages.service';
import { slideButtons } from 'src/app/shared/utils/reservation.list.animations';
import { environment } from 'src/environments/environment';
import { SideModalService } from '../../side-modal/side-modal.service';
import { ListOfPositionsRoutes } from './list-of-positions.routes';

@Component({
  selector: 'app-list-of-positions',
  templateUrl: './list-of-positions.component.html',
  styleUrls: ['./list-of-positions.component.scss'],
  animations: [slideButtons()],
})
export class ListOfPositionsComponent implements OnInit, AfterViewInit {
  reservationList: any[] = [];
  reservationHistoryList: any[] = [];
  showSearchUserPositionForm = false;
  showMultipleReservationForm = false;
  showReservationByPositionForm = false;
  messagesPipe = new MessagesPipe();
  token = this.authenticationService.getToken();
  permissions: MenuPermissions =
    this.authenticationService.getPermissionsForCurrentUser();
  isFirst = false;
  isLast = false;
  numberOfElements: number;
  size: number;
  totalElements: number;
  page: number;
  isShareModalVisible: boolean;
  myReservation = {};
  showOptionsButtons: boolean;
  ie: boolean;
  url: String;
  selectOptionMenu: string;
  isDinner: boolean;
  showOverlay: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private boxOfficeService: BoxOfficeService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService,
    private router: Router,
    private confirmService: ConfirmService,
    private route: ActivatedRoute,
    private sideModalService: SideModalService
  ) {
    this.isShareModalVisible = false;
    this.showOptionsButtons = false;
    this.showOverlay = false;
    this.ie = false;
  }

  ngOnInit() {
    let userAgent: string, ieReg: RegExp;
    userAgent = window.navigator.userAgent;
    ieReg = /msie|Trident.*rv[ :]*11\./gi;
    this.ie = ieReg.test(userAgent);
    this.url = this.route.snapshot['_routerState'].url as String;
    this.selectOptionMenu = this.url.includes('/dinning-position')
      ? 'dinner'
      : 'desks';
    this.isDinner = this.url.includes('/dinning-position') ? true : false;
    this.closeSideModal();
  }

  ngAfterViewInit() {
    this.getReservationList();
    this.getHistory();
  }

  openSearchUserPositionForm() {
    this.sideModalService.openSideModal(
      ListOfPositionsRoutes.searchUserPosition.routerName,
      {},
      this.messagesPipe.transform(
        ListOfPositionsRoutes.searchUserPosition.title
      ),
      this.route
    );
  }

  openMultipleReservationForm() {
    this.sideModalService.openSideModal(
      ListOfPositionsRoutes.multipleReservation.routerName,
      {},
      this.messagesPipe.transform(
        ListOfPositionsRoutes.multipleReservation.title
      ),
      this.route
    );
  }

  openReservationByPositionForm() {
    this.sideModalService.openSideModal(
      ListOfPositionsRoutes.reservationByPosition.routerName,
      {},
      this.messagesPipe.transform(
        ListOfPositionsRoutes.reservationByPosition.title
      ),
      this.route
    );
  }

  closeSideModal() {
    this.sideModalService.subjectSideModal(this.route).subscribe((res) => {
      if (res.close && res.checkinWithNfc) {
        // Estamos cerrando desde la modal de check in con nfc
        this.showOverlay = false;
        this.getReservationList();
      } else if (res.close) {
        this.showOptionsButtons = false;
        this.showOverlay = false;
      }
    });
  }

  getReservationList(): void {
    this.spinnerService.show();

    this.boxOfficeService
      .getUserReservations(this.token, this.selectOptionMenu)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.reservationList = response.content;
            this.reservationList.forEach((element) => {
              element.minuteFrom =
                element.minuteFrom.toString().length === 2
                  ? element.minuteFrom
                  : '0' + element.minuteFrom;
            });
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_recover_desk_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  viewMyPosition(id: number, areaId: number, date: number) {
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'view-position',
      id,
      areaId,
      date,
      this.token,
    ]);
  }

  cancelPosition(locatorCode: string, id: string) {
    this.spinnerService.show();

    this.boxOfficeService
      .cancelHotDeskReservation(locatorCode, this.token, this.selectOptionMenu)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.reservationList = this.reservationList.filter(
            (obj) => obj.id !== id
          );

          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_cancel_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_cancel_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  confirmCancelation(event: any, locatorCode: string, id: string) {
    event.stopPropagation();

    this.confirmService
      .activate(
        this.messagesPipe.transform('reservation_delete_msg'),
        this.messagesPipe.transform('reservation_delete_msg_title')
      )
      .then((result) => {
        if (result) {
          this.cancelPosition(locatorCode, id);
        }
      })
      .catch((error) => {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          this.messagesPipe.transform('confirm_dialog_err'),
          { cssClass: 'alert-danger', timeout: 3000 }
        );
      });
  }

  editPosition(
    idReservation: number,
    idDesk: number,
    date: number,
    areaId: number
  ) {
    this.router.navigate([
      'menu',
      this.url.includes('/dinning-position')
        ? 'dinning-position'
        : 'job-position',
      'edit-position',
      idReservation,
      idDesk,
      date,
      areaId,
      this.token,
    ]);
  }

  canCheckIn(event: any, reservationId: number) {
    event.stopPropagation();
    const allowWebChekInWithCode = environment['allowWebChekInWithCode'];

    if (!allowWebChekInWithCode) {
      this.spinnerService.show();
      this.boxOfficeService
        .doCheckinWithoutNfc(reservationId, this.token, this.selectOptionMenu)
        .pipe(
          finalize(() => {
            this.spinnerService.hide();
          })
        )
        .subscribe(
          (response: any) => {
            this.flashMessagesService.show(
              this.messagesPipe.transform('reservation_checkin_success'),
              { cssClass: 'alert-success', timeout: 3000 }
            );
            this.getReservationList();
          },
          (error) => {
            this.flashMessagesService.show(
              this.messagesPipe.transform('reservation_checkin_error'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
          }
        );
    } else {
      this.showOverlay = true;
      this.sideModalService.openSideModal(
        ListOfPositionsRoutes.checkInWithCode.routerName,
        {
          reservationId: reservationId,
          option: this.selectOptionMenu,
        },
        this.messagesPipe.transform(
          ListOfPositionsRoutes.checkInWithCode.title
        ),
        this.route
      );
    }
  }

  doCheckOut(event: any, locatorCode: string, id: string) {
    event.stopPropagation();

    this.spinnerService.show();

    this.boxOfficeService
      .doCheckout(locatorCode, this.token, this.selectOptionMenu)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (response: any) => {
          this.reservationList = this.reservationList.filter(
            (obj) => obj.id !== id
          );

          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_checkout_success'),
            { cssClass: 'alert-success', timeout: 3000 }
          );
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('rreservation_checkout_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );

  }

  showShareModal(event: any, reservation: any): void {
    event.stopPropagation();
    this.isShareModalVisible = true;
    this.myReservation = reservation;
  }

  hideShareModal(): void {
    this.isShareModalVisible = false;
  }

  getHistory(pageNumber = 0, pageSize = 10): void {
    this.spinnerService.show();

    this.boxOfficeService
      .getUserDeskHistory(
        this.token,
        pageNumber,
        pageSize,
        this.selectOptionMenu
      )
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.reservationHistoryList = this.reservationHistoryList.concat(
              response.content
            );
            this.isFirst = response.first;
            this.isLast = response.last;
            this.numberOfElements = response.numberOfElements;
            this.size = response.size;
            this.totalElements = response.totalElements;
            this.page = response.number;
          }
        },
        (error) => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('reservation_recover_desk_error'),
            { cssClass: 'alert-danger', timeout: 3000 }
          );
        }
      );
  }

  getMoreElementsFromHistory() {
    const nextPage = 1;

    if (
      this.reservationHistoryList.length < this.totalElements &&
      !this.isLast
    ) {
      this.getHistory(this.page + nextPage, this.numberOfElements);
    }
  }

  showButtons(): void {
    this.showOptionsButtons = !this.showOptionsButtons;
  }

  getSlideButtonsClass(): string {
    if (this.ie) {
      const el = document.getElementById('list-of-positions');
      if (this.showOptionsButtons) {
        el.style.display = 'block';
        el.style.opacity = '1';
      } else {
        el.style.display = 'none';
        el.style.opacity = '0';
      }
    } else {
      if (this.showOptionsButtons) {
        return ANIMATE_CLASS_ACTIVE;
      } else {
        return ANIMATE_CLASS_INACTIVE;
      }
    }
  }
}
