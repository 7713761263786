import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MessagesPipe } from 'src/app/shared/pipes/messages.pipe';
import { BuildingWs } from '../../../core/models/building.ws';
import { Floor } from '../../../core/models/floor';
import { Headquarters } from '../../../core/models/headquarters';
import { UserLdapWs } from '../../../core/models/user.ldap.ws';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { AreaService } from '../../../shared/services/area.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { BuildingService } from '../../../shared/services/building.service';
import { CategoriesService } from '../../../shared/services/categories.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { FloorService } from '../../../shared/services/floor.service';
import { IncidenceService } from '../../../shared/services/incidence.service';
import { ResourcesService } from '../../../shared/services/resources.service';
import { SideModalService } from '../../side-modal/side-modal.service';

@Component({
  selector: 'app-incidence-new-form',
  templateUrl: './incidence-new-form.component.html',
  styleUrls: ['./incidence-new-form.component.scss'],
})
export class IncidenceNewFormComponent implements OnInit {
  categoryParam: any;

  @ViewChild('imageFile') fileUploader: ElementRef;

  buildings: BuildingWs[];
  floors: Floor[];
  areas: any;
  resources: any;
  categories: any;
  user: UserLdapWs;
  userHeadquarter: Headquarters;
  incidenceForm: FormGroup;
  loading: boolean;
  messagesPipe = new MessagesPipe();

  constructor(
    private authenticationService: AuthenticationService,
    private buildingService: BuildingService,
    private floorService: FloorService,
    private areaService: AreaService,
    private resourcesService: ResourcesService,
    private categoriesService: CategoriesService,
    private incidenceService: IncidenceService,
    private flashMessagesService: FlashMessagesService,
    private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private sideModalService: SideModalService,
    private router: Router
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.categoryParam
    ) {
      this.categoryParam =
        this.router.getCurrentNavigation().extras.state.categoryParam;
    }
  }

  ngOnInit() {
    this.user = this.authenticationService.getCurrentUser();
    this.userHeadquarter = this.user.headquarters;
    this.createForm();
    this.getBuilding();
  }

  createForm() {
    this.incidenceForm = new FormGroup({
      idBuilding: new FormControl(-1, Validators.required),
      idFloor: new FormControl(-1, Validators.required),
      idZona: new FormControl(-1, Validators.required),
      idResource: new FormControl(-1, Validators.required),
      idCategory: new FormControl(-1, Validators.required),
      description: new FormControl('', Validators.required),
      name: new FormControl(''),
      image: new FormControl(null),
    });
    this.incidenceForm.get('idFloor').disable();
    this.incidenceForm.get('idZona').disable();
    this.incidenceForm.get('idResource').disable();
    this.incidenceForm.get('idCategory').disable();
  }

  validForm() {
    if (
      this.incidenceForm.valid &&
      this.incidenceForm.get('idBuilding').value > 0 &&
      this.incidenceForm.get('idFloor').value > 0 &&
      this.incidenceForm.get('idZona').value > 0 &&
      this.incidenceForm.get('idResource').value > 0 &&
      this.incidenceForm.get('idCategory').value > 0 &&
      this.incidenceForm.get('description')
    ) {
      return false;
    } else {
      return true;
    }
  }

  changeBuilding() {
    if (this.incidenceForm.get('idBuilding').value > 0) {
      this.incidenceForm.patchValue({
        idFloor: -1,
        idZona: -1,
        idResource: -1,
        idCategory: -1,
      });
      this.incidenceForm.get('idZona').disable();
      this.incidenceForm.get('idResource').disable();
      this.incidenceForm.get('idCategory').disable();
      this.getFloor(this.incidenceForm.get('idBuilding').value);
    } else {
      this.floors = [];
      this.areas = [];
      this.resources = [];
      this.categories = [];
      this.incidenceForm.get('idFloor').disable();
      this.incidenceForm.get('idZona').disable();
      this.incidenceForm.get('idResource').disable();
      this.incidenceForm.get('idCategory').disable();
    }
  }

  changeFloor() {
    if (this.incidenceForm.get('idFloor').value > 0) {
      this.incidenceForm.patchValue({
        idZona: -1,
        idResource: -1,
        idCategory: -1,
      });
      this.incidenceForm.get('idResource').disable();
      this.incidenceForm.get('idCategory').disable();
      this.getArea(this.incidenceForm.get('idFloor').value);
    } else {
      this.areas = [];
      this.resources = [];
      this.categories = [];
      this.incidenceForm.get('idZona').disable();
      this.incidenceForm.get('idResource').disable();
      this.incidenceForm.get('idCategory').disable();
    }
  }

  changeArea() {
    if (this.incidenceForm.get('idZona').value > 0) {
      this.incidenceForm.patchValue({
        idResource: -1,
        idCategory: -1,
      });
      this.incidenceForm.get('idCategory').disable();
      this.getResources(this.incidenceForm.get('idZona').value);
    } else {
      this.resources = [];
      this.categories = [];
      this.incidenceForm.get('idResource').disable();
      this.incidenceForm.get('idCategory').disable();
    }
  }

  changeResource() {
    if (this.incidenceForm.get('idResource').value > 0) {
      this.incidenceForm.patchValue({
        idCategory: -1,
      });
      this.incidenceForm.get('idCategory').enable();
      this.getCategories(this.incidenceForm.get('idResource').value);
    } else {
      this.categories = [];
      this.incidenceForm.get('idCategory').disable();
    }
  }

  changeImagen(event) {
    if (event && event.target && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type.includes('image/')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.incidenceForm.get('image').patchValue(reader.result.toString());
        };
      } else {
        let fileImage = document.getElementById(
          'imageFile'
        ) as HTMLInputElement;
        fileImage.value = null;
        this.incidenceForm.get('image').patchValue(null);
        this.flashMessagesService.grayOut(false);

        this.flashMessagesService.show(
          this.messagesPipe.transform('incidence_file_type'),
          { cssClass: 'alert-error', timeout: 3000 }
        );
      }
    } else {
      this.incidenceForm.get('image').patchValue(null);
      this.flashMessagesService.grayOut(false);

      this.flashMessagesService.show(
        this.messagesPipe.transform('incidence_file_type'),
        { cssClass: 'alert-error', timeout: 3000 }
      );
    }
  }

  checkIncidence() {
    this.spinnerService.show();
    this.incidenceForm
      .get('name')
      .patchValue(this.incidenceForm.get('description').value);
    this.incidenceService.altaIncidence(this.incidenceForm.value).subscribe(
      (res) => {
        if (res && res.status == 200) {
          this.sideModalService.closeSideModal();
          this.flashMessagesService.grayOut(false);
          this.flashMessagesService.show('Incidencia añadida correctamente', {
            cssClass: 'alert-success',
            timeout: 3000,
          });
        } else {
          this.flashMessagesService.grayOut(false);
          this.sideModalService.closeSideModal();
          this.flashMessagesService.show('Error al añadir la incidencia', {
            cssClass: 'alert-danger',
            timeout: 3000,
          });
        }
        this.spinnerService.hide();
      },
      (error) => {
        this.spinnerService.hide();
        this.flashMessagesService.grayOut(false);
        this.sideModalService.closeSideModal();
        this.flashMessagesService.show('Error al añadir la incidencia', {
          cssClass: 'alert-danger',
          timeout: 3000,
        });
      }
    );
  }

  getBuilding() {
    this.buildingService
      .getBuildingList(this.user.accessToken)
      .pipe(
        map((response) =>
          response.filter((building: BuildingWs) => building.enabled)
        )
      )
      .subscribe((res) => {
        if (res) {
          this.buildings = res;
          if (this.buildings.length > 1) {
            this.incidenceForm.get('idBuilding').patchValue(-1);
          } else if (this.buildings.length == 1) {
            this.incidenceForm.get('idBuilding').patchValue(res[0].id);
            this.changeBuilding();
          }
        } else {
          this.buildings = [];
        }
      });
  }

  getFloor(id) {
    if (id > 0) {
      this.floorService.getFloorsListAll(id).subscribe((res) => {
        if (res) {
          this.incidenceForm.get('idFloor').enable();
          this.floors = res;
          if (this.floors.length > 1) {
            this.incidenceForm.get('idFloor').patchValue(-1);
          } else if (this.floors.length == 1) {
            this.incidenceForm.get('idFloor').patchValue(res[0].id);
            this.getArea(res[0].id);
            this.changeFloor();
          }
        } else {
          this.floors = [];
        }
      });
    }
  }

  getArea(id) {
    if (id > 0) {
      this.areaService.getAreaListAll(id).subscribe((res) => {
        if (res) {
          this.incidenceForm.get('idZona').enable();
          this.areas = res;
          if (this.areas.length > 1) {
            this.incidenceForm.get('idZona').patchValue(-1);
          } else {
            this.incidenceForm.get('idZona').patchValue(res[0].id);
            this.getResources(res[0].id);
            this.changeArea();
          }
        } else {
          this.areas = [];
        }
      });
    }
  }

  getResources(id) {
    if (id > 0) {
      this.resourcesService.getResourcesListAllById(id).subscribe((res) => {
        if (res) {
          this.resources = res;
          this.incidenceForm.get('idResource').enable();
          if (this.resources.length > 1) {
            this.incidenceForm.get('idResource').patchValue(-1);
          } else {
            this.incidenceForm.get('idResource').patchValue(res[0].id);
            this.getCategories(res[0].id);
            this.changeResource();
          }
        } else {
          this.resources = [];
        }
      });
    }
  }

  getCategories(id) {
    if (id > 0) {
      this.categoriesService.getCategoriesListAll(id).subscribe((res) => {
        if (res) {
          this.categories = res;
          this.incidenceForm.get('idCategory').enable();
          if (this.categories.length > 1) {
            if (this.categories.find((x) => x.id == this.categoryParam)) {
              this.incidenceForm
                .get('idCategory')
                .patchValue(this.categoryParam);
            } else {
              this.incidenceForm.get('idCategory').patchValue(-1);
            }
          } else {
            this.incidenceForm.get('idCategory').patchValue(res[0].id);
          }
        } else {
          this.categories = [];
        }
      });
    }
  }
}
