import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { ConfigData } from 'src/app/core/models/config.data';
import { MenuPermissions } from 'src/app/core/models/menu.permissions';
import { CLIENT_ID, SERVICE_BASE_URL } from '../../../environments/environment';
import {
  ALLOWHQ,
  LOCAL_CURRENT_USER,
  OPTIONS,
} from '../../core/constants/const';
import { BuildingWs } from '../../core/models/building.ws';
import { HeadquartersWS } from '../../core/models/headquarters.ws';
import { Oauth } from '../../core/models/oauth';
import { OauthResponseWS } from '../../core/models/oauth.response.ws';
import { UserLdapWs } from '../../core/models/user.ldap.ws';
import { Utils } from '../utils/utils';
import { ThemeSwitcherService } from './theme-switcher.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: Http,
    private router: Router,
    private utils: Utils,
    private themeSwitcher: ThemeSwitcherService
  ) {}

  login(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {

    // Se carga el tema por defecto de la aplicación al hacer logout
    this.themeSwitcher.setDefaultTheme();

    localStorage.clear();
    this.login();
  }

  saveToken(user: UserLdapWs) {
    let expireDate = this.utils.getExpireDate(user.expireIn);
    user.expireIn = expireDate.getTime();
    localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
  }

  updateToken(token: string, expireIn: number, refresh: string) {
    let date = new Date();
    expireIn = expireIn - 5;
    date.setSeconds(date.getSeconds() + expireIn);

    let user = JSON.parse(
      localStorage.getItem(LOCAL_CURRENT_USER)
    ) as UserLdapWs;

    user.accessToken = token;
    user.expireIn = date.getTime();
    user.refreshToken = refresh;

    localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
  }

  updateBuilding(building: BuildingWs) {
    let user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
    user.building = building;
    localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
  }

  updateHeadquarters(headquarters: HeadquartersWS) {
    let user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
    user.headquarters = headquarters;
    localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(user));
  }

  updateUser(user: UserLdapWs) {
    let userSession = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
    userSession.name = user.name;
    userSession.email = user.email;
    userSession.building = user.building;
    userSession.headquarters = user.headquarters;
    userSession.parents = user.parents;
    userSession.image = user.image;
    userSession.department = user.department;
    userSession.showHotdeskReservation = user.showHotdeskReservation;
    localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(userSession));
  }

  updateOpeningAndMaxTime(data: ConfigData) {
    let userSession = JSON.parse(
      localStorage.getItem(LOCAL_CURRENT_USER)
    ) as UserLdapWs;
    userSession.maxDays = data.reservationMaxAllowedDays;
    userSession.openingTime = data.openingTime;
    localStorage.setItem(LOCAL_CURRENT_USER, JSON.stringify(userSession));
  }

  existLogedUser(): boolean {
    if (localStorage.getItem(LOCAL_CURRENT_USER)) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser(): UserLdapWs {
    if (this.existLogedUser()) {
      let user = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
      return user;
    } else {
      return null;
    }
  }

  getPermissionsForCurrentUser(): MenuPermissions {
    return new MenuPermissions(this.getCurrentUser());
  }

  getToken(): string {
    if (this.existLogedUser()) {
      let user = JSON.parse(
        localStorage.getItem(LOCAL_CURRENT_USER)
      ) as UserLdapWs;
      return user.accessToken;
    } else {
      return null;
    }
  }

  isTokenExpire(): boolean {
    let actualDate;
    let currentUser;

    if (this.existLogedUser()) {
      actualDate = new Date();
      currentUser = JSON.parse(localStorage.getItem(LOCAL_CURRENT_USER));
      let expireDate = new Date(currentUser.expireIn);

      if (this.utils.compareDatesSecondsInclude(actualDate, expireDate) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  refreshToken(): Observable<string> {
    let oauth = new Oauth();
    let currentUser = JSON.parse(
      localStorage.getItem(LOCAL_CURRENT_USER)
    ) as UserLdapWs;

    oauth.clientId = CLIENT_ID;
    oauth.refreshToken = currentUser.refreshToken;

    return this.http
      .post(
        `${SERVICE_BASE_URL}/web/access/refreshToken`,
        JSON.stringify(oauth),
        OPTIONS
      )
      .map((response: Response) => {
        let oauthResponse = response.json() as OauthResponseWS;
        this.updateToken(
          oauthResponse.oauth.access_token,
          oauthResponse.oauth.expires_in,
          oauthResponse.oauth.refresh_token
        );
        return oauthResponse.oauth.access_token;
      });
  }

  validateSessionId(): Observable<string> {
    let currentUser = JSON.parse(
      localStorage.getItem(LOCAL_CURRENT_USER)
    ) as UserLdapWs;
    let bodyString = JSON.stringify({
      clientId: CLIENT_ID,
      sessionId: currentUser.sessionId,
    });

    return this.http
      .post(`${SERVICE_BASE_URL}/web/soap/authenticate`, bodyString, OPTIONS)
      .map((response: Response) => {
        let user = response.json() as UserLdapWs;
        this.updateToken(user.accessToken, user.expireIn, user.refreshToken);
        return user.refreshToken;
      });
  }

  getMaxReservationDate(): Date {
    let dateMax;
    let currentUser = this.getCurrentUser();
    let actualDate = new Date();
    if (currentUser != null) {
      dateMax = new Date(currentUser.maxDays);
      return dateMax;
    } else {
      return actualDate;
    }
  }

  getMaxHotDeskAdvanceDays(): Date {
    const currentUser = this.getCurrentUser();
    const currentDate = new Date();

    if (currentUser && currentUser.headquarters.maxHotdeskAdvanceDays) {
      return new Date(
        currentDate.setDate(
          currentDate.getDate() + currentUser.headquarters.maxHotdeskAdvanceDays
        )
      );
    } else {
      const availabilityByDefault = 120;
      return new Date(
        currentDate.setDate(currentDate.getDate() + availabilityByDefault)
      );
    }
  }

  getMinReservationTime(): Date {
    let timeMin;
    let currentUser = this.getCurrentUser();

    if (currentUser != null) {
      timeMin = new Date(currentUser.openingTime);
      return timeMin;
    } else {
      return null;
    }
  }

  hqButtonShow(user: UserLdapWs): boolean {
    if (user.authorizations) {
      for (let authorizarion of user.authorizations) {
        if (authorizarion && authorizarion.tag == ALLOWHQ) {
          return true;
        }
      }
    }
    return false;
  }
}
