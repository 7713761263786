import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AVAILABLE_HOURS,
  AVAILABLE_MINUTES_SPEED,
  RESERVATION_TYPE_NONE,
} from '../../../core/constants/consts';
import { ReservationSpeedLimitsResponseWs } from '../../../core/models/reservation.speed.ws';
import { ReservationTypesWS } from '../../../core/models/reservation.types.ws';
import { RoomListWs } from '../../../core/models/room.list.ws';
import { MyDatePicker } from '../../../shared/components/date_picker/datepicker.component';
import { CustomTimePipe } from '../../../shared/pipes/custom.time.pipe';
import { DateStringPipe } from '../../../shared/pipes/date.string.pipe';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { ReservationService } from '../../../shared/services/reservation.service';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'reservationSpeedNewDetail',
  templateUrl: 'reservation.speed.new.detail.html',
  styleUrls: ['new-reservation.scss'],
})
export class ReservationSpeedNewDetail implements OnInit, AfterViewInit {
  reservationTypes: ReservationTypesWS[];
  @ViewChild('datePickerElement') datePickerElement: MyDatePicker;
  @ViewChild('datePickerMaxDate') datePickerMaxDate: MyDatePicker;
  @ViewChild('selectHourFrom') selectHourFrom: any;
  @ViewChild('selectMinuteFrom') selectMinuteFrom: any;
  @ViewChild('selectDuration') selectDuration: any;
  @ViewChild('selectReservationTypes') selectReservationTypes: any;
  reservationTypeSelected: ReservationTypesWS;
  defaultReservationTypeSelected = RESERVATION_TYPE_NONE;
  reservationForm: FormGroup;
  datepickerOptions: any;
  reservationDate: Date;
  reservationMaxDate: Date;
  hoursFrom: string[];
  reservationDateFrom: string;
  reservationDuration: number;
  minutesFrom = AVAILABLE_MINUTES_SPEED;
  duration: ReservationSpeedLimitsResponseWs[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private utils: Utils,
    private authenticationService: AuthenticationService,
    private flashMessagesService: FlashMessagesService,
    private router: Router,
    private reservationService: ReservationService
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.reservationTypes =
        this.router.getCurrentNavigation().extras.state.reservationTypes;
    }

    let minDate;
    let minHourIndex;
    this.datepickerOptions = {};
    this.reservationTypeSelected = new ReservationTypesWS();
    let dateStringPipe = new DateStringPipe();

    //Remove the hours from selects
    minDate = this.authenticationService.getMinReservationTime();

    if (minDate != null) {
      minHourIndex = AVAILABLE_HOURS.indexOf(
        dateStringPipe.transform(minDate, 'HH')
      );
      this.hoursFrom = AVAILABLE_HOURS.slice(
        minHourIndex,
        AVAILABLE_HOURS.length
      );
    } else {
      this.hoursFrom = AVAILABLE_HOURS;
    }
  }

  ngOnInit() {
    this.reservationForm = this.formBuilder.group({
      reason: ['', Validators.required],
    });

    this.datepickerOptions = this.utils.getDatepickerOptions(
      this.authenticationService.getMaxReservationDate()
    );
    let actualDate = new Date();
    this.reservationDate = actualDate;
    this.getDuration();

    let maxPeriodicityDate = this.authenticationService.getMaxReservationDate();
    this.reservationMaxDate = maxPeriodicityDate;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let actualDate = new Date();
      let maxPeriodicityDate =
        this.authenticationService.getMaxReservationDate();

      this.utils.setDatePickerDate(actualDate, this.datePickerElement);
      this.utils.setDatePickerDate(maxPeriodicityDate, this.datePickerMaxDate);
      this.selectDuration.nativeElement.selectedIndex = 0;
      this.setPositionFrom();

      if (this.reservationTypes && this.reservationTypes.length > 0) {
        this.reservationTypeSelected = this.reservationTypes.filter((type) => {
          return this.defaultReservationTypeSelected == type.id;
        })[0];
        this.selectReservationTypes.nativeElement.selectedIndex =
          this.reservationTypes.indexOf(this.reservationTypeSelected);
      }
    }, 200);
  }

  getDuration() {
    this.reservationService
      .getSpeedLimits(this.authenticationService.getToken())
      .subscribe({
        next: (response) => {
          if (response) {
            this.duration = response;
            if (response && response[0]) {
              this.reservationDuration = parseInt(response[0].time);
            }
          }
        },
      });
  }

  checkReservations() {
    let periodicityCorrect;
    let comparePeriodicityDates;
    let dateTo;
    let roomList;
    let timePipe = new CustomTimePipe();
    let messagesPipe = new MessagesPipe();
    let dateFrom = timePipe.transform(
      this.reservationDate,
      this.reservationDateFrom
    );
    let actualDate = new Date();

    if (this.utils.compareDates(dateFrom, actualDate) < 0) {
      this.flashMessagesService.grayOut(false);
      this.flashMessagesService.show(
        messagesPipe.transform('date_from_greater'),
        { cssClass: 'alert-danger', timeout: 3000 }
      );
    } else {
      dateTo = new Date(dateFrom.getTime());
      dateTo.setMinutes(dateTo.getMinutes() + this.reservationDuration);

      if (dateTo.getDay() > dateFrom.getDay()) {
        this.flashMessagesService.grayOut(false);
        this.flashMessagesService.show(
          messagesPipe.transform('duraton_greater'),
          { cssClass: 'alert-danger', timeout: 3000 }
        );
      } else {
        if (
          this.reservationTypeSelected.id != this.defaultReservationTypeSelected
        ) {
          comparePeriodicityDates = this.utils.compareDatesWithoutTime(
            this.reservationDate,
            this.reservationMaxDate
          );

          if (comparePeriodicityDates >= 0) {
            this.flashMessagesService.grayOut(false);
            this.flashMessagesService.show(
              messagesPipe.transform('date_max_periodicity_greater'),
              { cssClass: 'alert-danger', timeout: 3000 }
            );
            periodicityCorrect = false;
          } else {
            periodicityCorrect = true;
          }
        } else {
          periodicityCorrect = true;
          this.reservationTypeSelected = null;
          this.reservationMaxDate = null;
        }

        if (periodicityCorrect) {
          roomList = new RoomListWs(
            dateFrom,
            new Date(),
            this.reservationForm.controls['reason'].value,
            null,
            null,
            this.reservationTypeSelected,
            this.reservationMaxDate
          );
          roomList.dateTo = null;
          roomList.dateToInMillis = null;
          roomList.duration = this.reservationDuration;

          localStorage.setItem('roomSpeedFilter', JSON.stringify(roomList));

          this.router.navigate(['/menu/availableSpeedRooms']);
        }
      }
    }
  }

  setPositionFrom(): void {
    this.reservationDateFrom = this.utils.selectHoursSetPositionFrom(
      this.authenticationService,
      this.hoursFrom,
      this.minutesFrom,
      this.selectHourFrom,
      this.selectMinuteFrom
    );
  }

  onDateChanged($event) {
    if ($event.formatted.length > 0) {
      this.reservationDate = new Date();
      this.reservationDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationDate = null;
    }
  }

  onDateMaxPeriodicity($event) {
    if ($event.formatted.length > 0) {
      this.reservationMaxDate = new Date();
      this.reservationMaxDate.setFullYear(
        $event.date.year,
        $event.date.month - 1,
        $event.date.day
      );
    } else {
      this.reservationMaxDate = null;
    }
  }

  onChangeHourFrom($event) {
    let minute = this.reservationDateFrom.split(':')[1];

    this.reservationDateFrom = `${$event.target.value}:${minute}`;
  }

  onChangeMinuteFrom($event) {
    let hour = this.reservationDateFrom.split(':')[0];

    this.reservationDateFrom = `${hour}:${$event.target.value}`;
  }

  onChangeDuration($event) {
    let duration = $event.target.value;
    this.reservationDuration = parseInt(duration);
  }

  onChangeReservationType($event) {
    let reservationId = parseInt($event.target.value);
    this.reservationTypeSelected = this.reservationTypes.filter((type) => {
      return type.id == reservationId;
    })[0];
  }
}
