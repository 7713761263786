<div
  [ngClass]="[
    getClassSelected(day),
    day.festive
      ? 'dayCalendarFestive'
      : day.disabled
      ? 'dayCalendarDisabled'
      : 'dayCalendar'
  ]"
  [style.background-color]="day.color"
  [style.border-width]="day.currentDay ? 'thick' : ''"
  [style.border-style]="day.currentDay ? 'solid' : ''"
  [style.border-color]="day.currentDay ? day.borderColor : ''"
>
  <div class="contentIcon">
    <span [style.color]="day.textColor">{{ day?.value }}</span>
    <div class="iconsStyle" *ngIf="day.assignments?.id">
      <div class="row">
        <div class="col columStyle">
          <i *ngIf="day.isParking" [ngClass]="getIconVehicule(day)"></i>
        </div>
      </div>
    </div>
  </div>
</div>
