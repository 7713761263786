import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { RoomWs } from '../../core/models/room.ws';
import { DetailWs } from '../../core/models/detail.ws';
import { EquipementWs } from '../../core/models/equipement.ws';
import { OPTIONS } from '../../core/constants/const';
import { SERVICE_BASE_URL } from '../../../environments/environment';

@Injectable()
export class EquipementService {
  constructor(
    private http: Http,
    private authenticationService: AuthenticationService,
    private baseService: BaseService
  ) {}

  getRoomEquipement(room: RoomWs, token: string): Observable<EquipementWs[]> {
    let detailWs = new DetailWs(room.id);
    let json = JSON.stringify(detailWs);

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/equipment/roomEquipment?access_token=${token}`,
        json,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as EquipementWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }

  getAllEquipement(token: string): Observable<EquipementWs[]> {
    let json = JSON.stringify({});

    return this.http
      .post(
        `${SERVICE_BASE_URL}/api/equipment/list?access_token=${token}`,
        json,
        OPTIONS
      )
      .map((response: Response) => {
        return response.json() as EquipementWs[];
      })
      .catch(error =>
        this.baseService.handleError(this.authenticationService, error)
      );
  }
}
