<nav [ngClass]="{ collapsed: navCollapsed, sticky: navSticky }">
  <div class="logo">
    <a [routerLink]="['/menu/home']">
      <img [src]=logoUrl alt="" />
    </a>
  </div>
  <ul>
    <li (click)="setNavSticky()">
      <a [routerLink]="['/menu/home']" [routerLinkActive]="['active']"><i class="vdp-navbar-salas"></i><span>{{
          'menu_init' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0002') && isModuleInWebBlackList('0002') && !hiddenRoomReservation" (click)="setNavSticky()">
      <a [routerLink]="['/menu/reservations']" [routerLinkActive]="['active']"><i
        class="vdp-navbar-salas-activo"></i><span>{{ 'menu_room' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0001') && isModuleInWebBlackList('0001') && user.headquarters.parkingType === 2 && !hiddenParking" (click)="setNavSticky()">
      <a [routerLink]="['/menu/parking']" [routerLinkActive]="['active']"><i
          class="vdp-navbar-parking-activo"></i><span>{{ 'menu_parking' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0001') && isModuleInWebBlackList('0001') && user.headquarters.parkingType === 1 && !hiddenParking" (click)="setNavSticky()">
      <a [routerLink]="['/menu/parkingOne']" [routerLinkActive]="['active']"><i
        class="vdp-navbar-parking-activo"></i><span>{{ 'menu_parking' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0003') && isModuleInWebBlackList('0003') && !hiddenIncidences"(click)="setNavSticky()">
      <a [routerLink]="['/menu/incidence']" [routerLinkActive]="['active']"><i class="vdp-warning"></i><span>{{
        'menu_incidence' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="permissions.allowSecurity" (click)="setNavSticky()">
      <a [routerLink]="['/menu/security']" [routerLinkActive]="['active']"><i class="vdp-icon_password"></i><span>{{
          'menu_security' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0012') && isModuleInWebBlackList('0012') && !hiddenHotDesktop" (click)="setNavSticky()">
      <a [routerLink]="['/menu/job-position']" [routerLinkActive]="['active']"><i
          class="vdp-puesto_trabajo"></i><span>{{ 'menu_job_position' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0017') && isModuleInWebBlackList('0017') && !hiddenDinnerDesk" (click)="setNavSticky()">
      <a [routerLink]="['/menu/dinning-position']" [routerLinkActive]="['active']"><i
        class="vdp-puestos-restaurante"></i><span>{{ 'menu_dinning_position' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0018') && isModuleInWebBlackList('0018')" (click)="setNavSticky()">
      <a [routerLink]="['/menu/assistance']" [routerLinkActive]="['active']"><i
        class="vdp-icon-reserva-sala"></i><span>{{ 'menu_assistance' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0004') && isModuleInWebBlackList('0004')" (click)="setNavSticky()">
      <a [routerLink]="['/menu/cantine']" [routerLinkActive]="['active']"><i class="vdp-config_restaurant"></i><span>{{
        'menu_cantine' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="user.headquarters.allowResourceForHour" (click)="setNavSticky()">
      <a [routerLink]="['/menu/recursos']" [routerLinkActive]="['active']"><i
        class="vdp-equipation-television"></i><span>{{ 'menu_resource' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0028') && isModuleInWebBlackList('0028')" (click)="setNavSticky()">
      <a [routerLink]="['/menu/carWash']" [routerLinkActive]="['active']"><i class="vdp-waves"></i><span>{{
        'menu_carWash' | messagesPipe }}</span></a>
    </li>
    <!-- INFO: DORLET Deshabilitado (permiso: dorletParking) -->
    <li *ngIf="false" (click)="setNavSticky()">
      <a [routerLink]="['/menu/dorlet']" [routerLinkActive]="['active']"><i class="vdp-waves"></i><span>{{ 'menu_dorlet'
          | messagesPipe }}</span></a>
    </li>
    <!-- INFO: MAYORDOMO Deshabilitado (permiso: '') -->
    <li *ngIf="false" (click)="setNavSticky()">
      <a [routerLink]="['/menu/steward']" [routerLinkActive]="['active']"><i class="vdp-waves"></i><span>{{
          'menu_steward' | messagesPipe }}</span></a>
    </li>
    <li *ngIf="visibleNavElements.includes('0029') && isModuleInWebBlackList('0029')" (click)="setNavSticky()">
      <a [routerLink]="['/menu/questionsAndAnswers']" [routerLinkActive]="['active']"><i
          class="vdp-supportIcon"></i><span>Soporte</span></a>
    </li>

    <li (click)="setNavSticky()">
      <a [routerLink]="['/menu/profile']" [routerLinkActive]="['active']"><i
          class="vdp-navbar-perfil-activo"></i><span>{{ 'menu_profile' | messagesPipe }}</span></a>
    </li>

    <li (click)="setNavSticky()">
      <a *ngIf="visibleNavElements.includes('0019') && isModuleInWebBlackList('0019')" [routerLink]="['/menu/occupationParking']"
        [routerLinkActive]="['active']"><i class="vdp-navbar-parking-activo"></i><span>{{ 'menu_parking' | messagesPipe
          }}</span></a>
    </li>

    <li (click)="setNavSticky()">
      <a *ngIf="visibleNavElements.includes('0007') && isModuleInWebBlackList('0007')" [routerLink]="['/menu/shuttle']" [routerLinkActive]="['active']"><i
          class="vdp-config_lanzaderas"></i><span> {{ 'menu_shuttle' | messagesPipe }}</span></a>
    </li>

    <li *ngIf="visibleNavElements.includes('0031') && isModuleInWebBlackList('0031')" (click)="setNavSticky()">
      <a [routerLink]="['/menu/gym_resources']" [routerLinkActive]="['active']"><i
          class="vdp-gym-resources"></i><span>{{ 'menu_gym_resources' | messagesPipe }}</span></a>
    </li>

    <li (click)="setNavSticky()" *ngIf="disableDownload">
      <a [routerLink]="['/menu/descargas']" [routerLinkActive]="['active']"><i
          class="vdp-navbar-descargar-activo"></i><span>{{ 'menu_descargas' | messagesPipe }}</span></a>
    </li>

    <ng-container *ngIf="visibleNavElements.includes('0023') && isModuleInWebBlackList('0023')">
      <li *ngFor="let module of externalModules">
        <app-menu-external (setNavSticky)="setNavSticky()" [module]="module"></app-menu-external>
      </li>
    </ng-container>

    <li class="logout" (click)="logout()">
      <a href="#"><i class="vdp-logout"></i><span>{{ 'menu_logout' | messagesPipe }}</span></a>
    </li>
  </ul>

  <div class="foot" *ngIf="enableLopd">
    <a [routerLink]="routerLinkLopd" [routerLinkActive]="['active']">
      <span>{{ 'lopd_text_title' | messagesPipe }}</span></a>
  </div>

  <div id="nav-icon3" (click)="setNavSticky()" [ngClass]="{ sticky: navSticky }">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</nav>
<header [ngClass]="{ collapsed: navCollapsed }">
  <button [ngClass]="{ collapsed: navCollapsed }" (click)="setNavCollapsed()" type="button" name="button"
    class="vdp-plegar-menu"></button>
  <div class="pull-left">
    <div class="pull-left-with-icon" (click)="goBack()" *ngIf="showIconBack()">
      <i class="vdp-go-back"></i>
    </div>
    <span>{{ linkName }}</span>
  </div>
  <div class="pull-right">
    <div class="user" (click)="showLogout()">
      <span>{{ userName }}</span>
      <div class="image-cropper" *ngIf="user.image != null && user.image.length > 0">
        <img [src]="user.image" />
      </div>
      <div [@slideButtons]="getSlideButtonsClass()" (click)="logout()" id="logout" class="logout-message">
        <i class="vdp-logout"></i>
        <span>{{ 'menu_logout' | messagesPipe }}</span>
      </div>
    </div>
  </div>
</header>
<div *ngIf="showCookiesDiv" class="cookies-container">
  <div class="cookies-text">
    <h1>{{ 'cookies_title' | messagesPipe }}</h1>
    <p>
      {{ 'cookies_text_1' | messagesPipe }}
    </p>
    <p>
      {{ 'cookies_text_2' | messagesPipe }}
    </p>
    <p>
      {{ 'cookies_text_3' | messagesPipe }}
    </p>
    <button class="btn waves-effect waves-light" (click)="acceptCookies()">
      Aceptar
    </button>
  </div>
</div>
<div *ngIf="showCarWashAlert" class="carWashAlert-container">
  <div class="carWashAlert-text">
    <h1>{{ 'alertCarWash_title' | messagesPipe }}</h1>

    <li>{{ 'alertCarWash_text_1' | messagesPipe }}</li>

    <li>{{ 'alertCarWash_text_2' | messagesPipe }}</li>

    <li>{{ 'alertCarWash_text_3' | messagesPipe }}</li>

    <h1>{{ 'alertCarWash_title_1' | messagesPipe }}</h1>

    <li>{{ 'alertCarWash_text_4' | messagesPipe }}</li>

    <li>{{ 'alertCarWash_text_5' | messagesPipe }}</li>

    <h1>{{ 'alertCarWash_title_2' | messagesPipe }}</h1>

    <li>{{ 'alertCarWash_text_6' | messagesPipe }}</li>

    <button class="btn waves-effect waves-light" (click)="acceptAlertCarWash()">
      {{ 'indicate_your_dept_bt' | messagesPipe }}
    </button>
  </div>
</div>
<div [ngClass]="{ collapsed: navCollapsed }">
  <router-outlet></router-outlet>
</div>